import { 
  AppstoreOutlined, 
  MailOutlined, 
  SettingOutlined, 
  DashboardOutlined,
  UserOutlined,
  RobotOutlined,
  ApartmentOutlined,
  DatabaseOutlined,
  CalendarOutlined,
  FileSearchOutlined,
  RocketOutlined,
  DollarOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

export const SideMenu = ({ currentStep, activePage }) => {
    const { basicInfo } = useSelector(state => state.app);
    console.log(basicInfo,"basicInfo")
    const menuItems = [
      {
        key: 'sub1',
        label: 'Dashboard',
        icon: <DashboardOutlined />,
        path: '/',
        disabled: false
      },
      {
        key: 'sub2',
        label: 'Onboarding',
        icon: <UserOutlined />,
        path: '/onboarding',
        disabled: basicInfo?.company_name ? false : true
      },
      {
        key: 'sub3',
        label: 'Customize AI Personality',
        icon: <RobotOutlined />,
        path: '/customize-ai-personality',
        disabled: basicInfo?.assistant_name ? false : true
      },
      {
        key: 'sub4',
        label: 'Workflow & Role',
        icon: <ApartmentOutlined />,
        path: '/workflow-and-role-setup',
        disabled: basicInfo?.department ? false : true
      },
      {
        key: 'sub5',
        label: 'Data Integration & Training',
        icon: <DatabaseOutlined />,
        path: '/audience-filters',
        disabled: basicInfo?.audience_filters ? false : true
      },
      {
        key: 'sub6',
        label: 'Connect Calendly',
        icon: <CalendarOutlined />,
        path: '/connect-calendly',
        disabled: basicInfo?.calendly_access_token ? false : true
      },
      {
        key: 'sub7',
        label: 'Review Samples',
        icon: <FileSearchOutlined />,
        path: '/review-samples',
        disabled: basicInfo?.review_samples ? false : true
      },

      {
        key: 'sub9',
        label: 'Deploy Agents',
        icon: <RocketOutlined />,
        path: '/confirm-deploy-agents',
        disabled: basicInfo?.deploy_agents ? false : true
      }
    ];
  
    const navigate = useNavigate();
  
    const onClick = (e) => {
      const selectedItem = menuItems.find(item => item.key === e.key);
      if (selectedItem && selectedItem.path) {
        navigate(selectedItem.path);
      }
    };

    const activeKey = menuItems.find(item => item.path === activePage)?.key || 'sub1';
  
    return (
      <Menu
        onClick={onClick}
        style={{
          width: 256,
        }}
        selectedKeys={[activeKey]}
        mode="inline"
        items={menuItems.map(item => ({
          ...item,
          disabled: item.disabled
        }))}
      />
    );
};