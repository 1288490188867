import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, List, Typography, Space, Row, Col, Modal, Form, Checkbox, message, Popconfirm, Table, Spin } from 'antd';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import "./KnowledgeBaseTab.css";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { render } from '@testing-library/react';
import { Tooltip } from 'antd';
import { Spinner } from "react-bootstrap";

const { Search } = Input;
const { Title, Text } = Typography;


const KnowledgeBaseTab = ({document_id}) => {
  const { userId } = useSelector(state => state.app);
  const [searchTerm, setSearchTerm] = useState('');
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [connectedKnowledgeBases, setConnectedKnowledgeBases] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([]);
  const [modalSearchTerm, setModalSearchTerm] = useState('');
  const [isConnecting, setisConnecting] = useState(false)
  const [filteredKnowledgeBases, setFilteredKnowledgeBases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);

  useEffect(() => {
    fetchKnowledgeBases();
    fetchConnectedKnowledgeBases();
  }, []);

  const fetchKnowledgeBases = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/aichat/list_knowledge_bases_to_connect`, { params: { "user_id": userId,"aichat_id":document_id} });
      setKnowledgeBases(response.data);

    } catch (error) {
      console.error('Error fetching knowledge bases:', error);
      message.error('Failed to fetch knowledge bases');
    } finally {
      setLoading(false);
    }
  };

  const fetchConnectedKnowledgeBases = async () => {
    setLoadingList(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/aichat/get_connected_knowledge_base`, { params: { parent_id: document_id } });
      setConnectedKnowledgeBases(response.data);
      console.log("connected Knowledgebases",response.data);

    } catch (error) {
      console.error('Error fetching connected knowledge bases:', error);
      message.error('Failed to fetch connected knowledge bases');
    } finally {
      setLoadingList(false);
    }
  };

  const handleSearch = (value) => {
    console.log("value--->",value);
    
    setSearchTerm(value?.trim());
    // Implement search functionality here if needed
  };
  const FilteredconnectedKnowledgeBases = connectedKnowledgeBases.filter(kb =>
        kb.name.toLowerCase().includes(searchTerm.toLowerCase())
       
    );

   const FilteredKnowledgeBases=knowledgeBases.filter(kb => 
      kb.name.toLowerCase().includes(modalSearchTerm?.trim().toLowerCase())
    )


  const handleAddKnowledgeBase = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedKnowledgeBases([]);
    setModalSearchTerm('');
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Checkbox
          checked={selectedKnowledgeBases.includes(record._id)}
          onChange={() => handleSelectKnowledgeBase(record._id)}
        >
          {text}
        </Checkbox>
      ),
    },
    {
      title: 'Last updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.lastUpdated || '1 hour ago'}
        </div>
      )
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.owner || 'Sanvi'}
        </div>
      ),
    },
  ];  

  const handleModalOk = async () => {
    try {
      setisConnecting(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/connect_knowledge_bases/`, {
        aichat_id: document_id,
        knowledge_base_ids: selectedKnowledgeBases
      });

      if (response.status === 200) {
        message.success('Knowledge bases connected successfully');
        fetchKnowledgeBases()
        fetchConnectedKnowledgeBases(); // Refresh the list of connected knowledge bases
      } else {
        message.error('Failed to connect knowledge bases');
      }
    } catch (error) {
      console.error('Error connecting knowledge bases:', error);
      message.error('Failed to connect knowledge bases');
    }
    finally{
      setisConnecting(false)
    }

    setIsModalVisible(false);
    setSelectedKnowledgeBases([]);
    setModalSearchTerm('');
  };

  const handleDeleteKnowledgeBase = async (id) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/delete_connected_knowledge_base/`,
        {
          aichat_id: document_id,
          knowledge_base_id: id
      });

      if (response.status === 200) {
        message.success('Knowledge base disconnected successfully');
        fetchKnowledgeBases()
        fetchConnectedKnowledgeBases(); // Refresh the list of connected knowledge bases
      } else {
        message.error('Failed to disconnect knowledge base');
      }
    } catch (error) {
      console.error('Error disconnecting knowledge base:', error);
      message.error('Failed to disconnect knowledge base');
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedKnowledgeBases(FilteredKnowledgeBases.map(kb => kb._id));
    } else {
      setSelectedKnowledgeBases([]);
    }
  };

  const handleSelectKnowledgeBase = (id) => {
    setSelectedKnowledgeBases(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(item => item !== id)
        : [...prevSelected, id]
    );
  };

  const columnsConnected = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span>
          <Link to={`/knowledge-base/${record.id}`} state={record}>{text}</Link>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to disconnect this knowledge base?"
          onConfirm={() => handleDeleteKnowledgeBase(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Disconnect">
            <Button icon={<DeleteOutlined />} danger></Button>
          </Tooltip>
        </Popconfirm>
      ),
    },
  ];


  return (
    <div style={{ width: '100%' }}>
      <Row gutter={16} style={{ marginBottom: '16px',justifyContent:'space-between' }}>
        <Col span={12}>
          <Input
            placeholder="Search"
            size="large"
            onChange={(e)=>{handleSearch(e.target.value)}}
            className='search-input-knowledge-base'
            // style={{
            //   width: 250,
            // }}
          />
        </Col>
        <Col  style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            size="medium"
            onClick={handleAddKnowledgeBase}
            style={{ backgroundColor: '#1677ff',display: 'inlineBlock' }}
          >
            Add knowledge base
          </Button>
        </Col>
      </Row>

      <Typography.Title level={5} style={{ marginBottom: '8px' }}>
        Connected Knowledge Bases
      </Typography.Title>

      <div>
      <Table
        columns={columnsConnected}
        dataSource={FilteredconnectedKnowledgeBases}
        rowKey="id"
        loading={loadingList}
        pagination={false}
      />
    </div>

      <Modal
        title="Connect knowledge bases to AI chat"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={900}
        className='knowledge-base-modal'
        okText={
          isConnecting ? (
            <span>
              Connecting <Spin size="small" />
            </span>
          ) : (
            'Connect'
          )
        }
        okButtonProps={{ disabled: isConnecting }}
      >
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          <Col>
            <Checkbox
              onChange={handleSelectAll}
              checked={FilteredKnowledgeBases.length>0 && selectedKnowledgeBases.length === FilteredKnowledgeBases.length}
              disabled={FilteredKnowledgeBases.length===0}
            >
              Select All
            </Checkbox>
          </Col>
          <Col>
            <Input
              placeholder="Search"  
              value={modalSearchTerm}
              onChange={(e) => setModalSearchTerm(e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
        </Row>
        <Table
          dataSource={FilteredKnowledgeBases}
          columns={columns}
          pagination={false}
          scroll={{ y: 300 }}
          rowKey="_id"
        />
      </Modal>
    </div>
  );
};

export default KnowledgeBaseTab;
