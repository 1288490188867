import { SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { FaLinkedin, FaLinkedinIn } from 'react-icons/fa6'
import { IoIosCall } from 'react-icons/io'
import { MdOutlineEmail } from 'react-icons/md'

const ConcersationCard = () => {
  return (
    <div>
        <div class="notification">
            {/* <div class="status-dot new"></div> */}
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTggauC9GzZ20cDt94Nzm2-z-qvmi9Jrh1JK-6n1HG-derBaewvGMJTsHMcw8ndDX7Fjqc&usqp=CAU" alt="Profile Picture"/>
            <div class="notification-content">
                <div>
                    <p><strong>Jamie Schwartz</strong> <span className='notification-highlight'>visited</span> your Gomeet page</p>
                    <p className='notification-designation'>Working as CEO for DBI360</p>
                    <div class="notification-action">
                        {/* <Button type="link" icon={<IoIosCall />}  /> */}
                        <Button icon={<MdOutlineEmail />}>
                            Follow Up
                        </Button>
                        <Button type='primary'  icon={<FaLinkedin  />}>
                            LinkedIn
                        </Button>
                        {/* <Button type="link" icon={<FaLinkedinIn />} /> */}
                    </div>
                </div>
                <div class="notification-time">
                    3h ago &mdash; <span>New York</span>
                </div>
            </div>
        </div>

        <div class="notification">
            {/* <div class="status-dot inactive"></div> */}
            <img src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1724716800&semt=ais_hybrid" alt="Profile Picture"/>
            <div class="notification-content">
                <div>
                    <p><a href="#">Fahmida Juarez</a> assigned you to <a href="#">Improve cards readability</a></p>
                    <p>Working as Developer for DBI360</p>
                    <div class="notification-action">
                    <Button icon={<MdOutlineEmail />}>
                            Follow Up
                        </Button>
                        <Button  type='primary' icon={<FaLinkedin  />}>
                            LinkedIn
                        </Button>
                    </div>
                </div>
                <div class="notification-time">
                    Yesterday &mdash; <span>USA</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ConcersationCard