import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Breadcrumb, Spin, Menu, Steps, Drawer } from 'antd';
import styled from "styled-components";
import {
    ApartmentOutlined,
    AppstoreOutlined,
    CalendarOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    DollarOutlined,
    FileSearchOutlined,
    MailOutlined,
    RobotOutlined,
    SettingOutlined,
    SmileOutlined,
    SolutionOutlined,
    UserOutlined
} from "@ant-design/icons";
import Lottie from 'react-lottie';
import animationData from '../assets/deployAgent.json';
import thankyoujson from '../assets/thankyou.json';
import { SideMenu } from '../components/Common/SideMenu';
import { TailSpin } from 'react-loader-spinner';



// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // margin-bottom: 30px;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }
`;

function ConfirmDeployAgents() {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [agentsDeployed, setAgentsDeployed] = useState(false);
    const navigate = useNavigate();
    const { userId, basicInfo } = useSelector(state => state.app);
    const [subscriptionExpired, setSubscriptionExpired] = useState(false);
    const [open, setOpen] = useState(false);
    const [upgradeReason, setUpgradeReason] = useState('');

    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };
    
    const checkDeploymentStatus = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/agent_setup/check_deployment_status/${userId}`
            );
            
            if (!response.data.deploy_agents) {
                // setError('Subscription expired');
                console.log("Something caused this to print.")
                setSubscriptionExpired(true);
                setUpgradeReason(response.data.message);
                return false;
            }
            
            return true;
            
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to check deployment status');
            return false;
        }
    };

   

    const thankyouLottie = {
        loop: true,
        autoplay: true,
        animationData: thankyoujson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const handleConfirm = async () => {
        setIsLoading(true);
        setError(null);
        try {

            const canDeploy = await checkDeploymentStatus();
            
            if (!canDeploy) {
                setIsLoading(false);
                return;
            }

            const response = await axios.post(process.env.REACT_APP_MULTIAGENT_API_URL + '/api/run-system/', {
                document_id: basicInfo._id,
                task_name: "Sales_Engagement_Automation"
            });
            
            if (response.status === 200 || response.status === 202) {
                setAgentsDeployed(true);
                setShowModal(true);
            } else {
                setError('Failed to deploy agents. Please try again.');
            }
        } catch (error) {
            console.error('Error deploying agents:', error);
            setError('An error occurred while deploying agents. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/');
    };

    const handleUpgrade = () => {
        navigate('/pricing');
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    if (isLoading) {
        return (
            <Container>
                <div className="text-center mt-5">
                <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                </div>
            </Container>
        );
    }
    const menuItems = [
        {
            key: 'sub1',
            label: 'Dashboard',
            icon: <MailOutlined />,
            path: '/',
        },
        {
            key: 'sub2',
            label: 'Onboarding',
            icon: <AppstoreOutlined />,
            path: '/onboarding',
        },
        {
            key: 'sub3',
            label: 'Customize AI Personality',
            icon: <SettingOutlined />,
            path: '/customize-ai-personality',
        },
        {
            key: 'sub4',
            label: 'Workflow & Role',
            icon: <SettingOutlined />,
            path: '/workflow-and-role-setup',
            // disabled: true
        },
        {
            key: 'sub5',
            label: 'Data Integration & Training',
            icon: <SettingOutlined />,
            path: '/audience-filters',
            // disabled: true
        },
        {
            key: 'sub6',
            label: 'Connect Calendly',
            icon: <SettingOutlined />,
            path: '/connect-calendly',
            // disabled: true
        },
        {
            key: 'sub7',
            label: 'Review Samples',
            icon: <SettingOutlined />,
            path: '/review-samples',
            // disabled: true
        },
        {
            key: 'sub8',
            label: 'Confirm and Deploy Agents',
            icon: <SettingOutlined />,
            path: '/confirm-deploy-agents',
            // disabled: true

        }
    ];

    // const onClick = (e) => {
    //     const selectedItem = menuItems.find(item => item.key === e.key);
    //     if (selectedItem && selectedItem.path) {
    //         navigate(selectedItem.path);
    //     }
    // };

    return (
        <PageBackground>
            <Container fluid >
                <Card  className='d-none d-lg-block'>
                    <Steps
                        items={[
                            {
                                title: 'Dashboard',
                                status: 'finish',
                                icon: <DashboardOutlined />,
                            },
                            {
                                title: 'Onboarding',
                                status: 'finish',
                                icon: <UserOutlined />,
                            },
                            {
                                title: 'Customize AI Personality',
                                status: 'finish',
                                icon: <RobotOutlined />,
                            },
                            {
                                title: 'Workflow & Role',
                                status: 'finish',
                                icon: <ApartmentOutlined />,
                            },
                            {
                                title: 'Data Integration & Training',
                                status: 'finish',
                                icon: <DatabaseOutlined />,
                            },
                            {
                                title: 'Connect Calendly',
                                status: 'finish',
                                icon: <CalendarOutlined />,
                            },
                            {
                                title: 'Review Samples',
                                status: 'finish',
                                icon: <FileSearchOutlined />,
                            },
                            {
                                title: 'Confirm and Deploy Agents',
                                status: 'process',
                                icon: <DollarOutlined />,

                            }
                        ]}
                    />
                </Card>
                <Card  className='p-3 d-sm-block d-none d-lg-none'>
                    <Steps
                        items={[
                            {
                                status: 'finish',
                                icon: <DashboardOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <UserOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <RobotOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <ApartmentOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <DatabaseOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <CalendarOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <FileSearchOutlined />,
                            },
                            {
                                status: 'process',
                                icon: <DollarOutlined />,

                            }
                        ]}
                    />
                </Card>

                <div className="d-flex  flex-column flex-md-row mt-4">
                    <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
                        <SideMenu currentStep={9} activePage="/confirm-deploy-agents" />
                    </Card>
                    <div className='mb-3'>
                        <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
                            Navigation Menu
                        </Button>
                    </div>
                    <Drawer title="Navigation Menu" onClose={onClose} open={open}>
                        <SideMenu currentStep={9} activePage="/confirm-deploy-agents" />
                    </Drawer>
                    <div className="w-100">
                        <Card className="p-2">
                            <Row className="my-4 pb-5 ">
                                <Col className="pb-5 text-center">
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    {agentsDeployed ? (
                                        <div>
                                            <Lottie options={thankyouLottie}
                                                height={250}
                                                width={250}
                                        />
                                            <h2>Thank you for deploying your AI agents!</h2>
                                            <p>Your AI agents are now active and ready to work for you.</p>
                                            <Button onClick={() => navigate('/')}>Go to Dashboard</Button>
                                        </div>
                                    ): (

                                        <div>
                                        {subscriptionExpired && (
                                            <>
                                            <div className="alert alert-danger">
                                                  
                                                  {upgradeReason === '1' ? (
                                                    <p className='m-0'>
                                                      Your subscription has been expired. Please{' '}
                                                      <strong>
                                                        <a
                                                          href="#"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleUpgrade();
                                                          }}
                                                          className="text-blue-600 hover:text-blue-800 cursor-pointer"
                                                        >
                                                          renew
                                                        </a>
                                                      </strong>{' '}your subscription.</p>
                                                  ) : (
                                                    <p className='m-0'>
                                                      Your limit of 1000 contacts has been exceeded this month. Please{' '}
                                                      <strong>
                                                        <a
                                                          href="#"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            handleUpgrade();
                                                          }}
                                                          className="text-blue-600 hover:text-blue-800 cursor-pointer"
                                                        >
                                                          upgrade
                                                        </a>
                                                      </strong>{' '}
                                                      your plan to deploy more agents.</p>
                                                  )}
                                                </div>
 
                                            </>
                                        )}
                                        <div>
                                            <Lottie options={defaultOptions}
                                                height={280}
                                                width={280}
                                        />
                                            <h2>Confirm and Deploy Agents</h2>
                                            <p>Are you ready to deploy your AI agents?</p>
                                            <Button onClick={handleConfirm} disabled={isLoading}>
                                                {/* {isLoading ?<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Yes, Deploy Agents'} */}
                                                {isLoading ? (
                                                    <TailSpin height="20" width="20" color="white" />
                                                ) : (
                                                    'Yes, Deploy Agents'
                                                )}
                                            </Button>
                                            <Button variant="secondary" className="ms-2" onClick={() => navigate(-1)}>No, Go Back</Button>
                                        </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>





                {/* <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Agents Deployed Successfully</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Your AI agents have been successfully deployed and are now ready to work.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseModal}>
                            Go to Home
                        </Button>
                    </Modal.Footer>
                </Modal> */}
            </Container>
        </PageBackground>
    );
}

export default ConfirmDeployAgents;
