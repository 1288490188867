import React from 'react'
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Flex } from 'antd';

const Register = () => {
    const onFinish = (values) => {
      };
  return (
    <div className='container-fluid'>
        <div class="row justify-content-center align-items-center authentication authentication-basic ">
            <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                <div class="my-4 d-flex justify-content-center">
                <a href="index.html" className='authlogo'>
                    Gomeet AI
                </a>
                </div>
                <div class="card custom-card">
                <div class="card-body p-5 login-auth-gomeet">
                    <h4 className='text-center fw-700'>Register</h4>
                    <p className='text-center'>Welcome & Join us by creating a free account !</p>
                     <Form
                     size='large'
                    name="register"
                    layout="vertical" 
                    initialValues={{
                        remember: true,
                        
                    }}
                    onFinish={onFinish}
                    >
                    <Form.Item
                        name="firstname"
                        label="First Name"
                        className='mb-2'    
                        rules={[
                        {
                            required: true,
                            message: 'Please input your First Name !',
                        },
                        
                        ]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                        className="mb-2"
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            autoComplete="off"
                            placeholder="Password"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}  // Optional: Custom icons
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Confirm Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                        className="mb-2"
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            autoComplete="off"
                            placeholder="Confirm Password"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}  // Optional: Custom icons
                        />
                    </Form.Item>
                    <Form.Item>
                        <Flex justify="space-between" align="center">
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox> By creating a account you agree to our </Checkbox>
                        </Form.Item>
                        {/* <a href="">Forgot password</a> */}
                        </Flex>
                    </Form.Item>

                    <Form.Item>
                        <Button block type="primary" htmlType="submit" style={{backgroundColor:"#3a5892"}}>
                            Create Account
                        </Button>
                       
                    </Form.Item>
                    <p>Already have an account?   <a href="">Sign In</a></p>
                    </Form>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register