import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Slider, Tooltip, Breadcrumb, Modal, Menu, Steps, Drawer } from 'antd';
import { FiInfo } from "react-icons/fi";
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Pricing.css';
import animationData from '../assets/pricing.json';
import payment from '../assets/payment.json';

import styled from "styled-components";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FileSearchOutlined,
  MailOutlined,
  RobotOutlined,
  RocketOutlined,
  SettingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined
} from "@ant-design/icons";
import {Button, Container} from "react-bootstrap";
import Lottie from 'react-lottie';
import { SideMenu } from '../components/Common/SideMenu';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { FaExclamationCircle } from 'react-icons/fa';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // margin-bottom: 30px;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }
`;

const Pricing = () => {

  const pricingTiers = [
    { contacts: 1000, price: 299, priceId: 'price_1QRTUkJbSDxwoE5Wp2ydqHjS' },
    { contacts: 5000, price: 499, priceId: 'price_1QRTUkJbSDxwoE5WIgH8UYcC' },
    { contacts: 10000 , price: 769, priceId: 'price_1QRTUkJbSDxwoE5WKe3Ofdr5' },
    { contacts: 25000, price: 1299, priceId: 'price_1QRTUkJbSDxwoE5W1FbDurMU' },
    { contacts: 50000, price: 2499, priceId: 'price_1QRTUkJbSDxwoE5WA3ve974E' },
    { contacts: 100000, price: 4499, priceId: 'price_1QRTW7JbSDxwoE5WGEEtRyA8' },
    { contacts: 200000, price: 7999, priceId: 'price_1QRTWnJbSDxwoE5WJbBUjOBL' },
    { contacts: 300000, price: 11999, priceId: 'price_1QRTX5JbSDxwoE5WQx8VI24l' },
    { contacts: 500000, price: null, priceId: null },
  ];


  const [activeContacts, setActiveContacts] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '' });
  const { userId, basicInfo } = useSelector(state => state.app);
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const getInitialContacts = (basicInfo) => {
    if (basicInfo?.current_plan && checkSubscriptionValidity(basicInfo.subscription_start_date)) {
      const currentPlanTier = pricingTiers.find(tier => tier.priceId === basicInfo.current_plan);
      return currentPlanTier ? currentPlanTier.contacts : 1000;
    }
    return 1000;
  };

  const checkSubscriptionValidity = (subscriptionStartDate) => {
    if (!subscriptionStartDate) return false;
    const startDate = new Date(subscriptionStartDate);
    const endDate = new Date(startDate);
    endDate.setFullYear(endDate.getFullYear() + 1);
    const currentDate = new Date();
    return endDate > currentDate;
  };


  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const completedPricingOptions = {
    // loop: false,
    // autoplay: false,
    animationData: payment,
    // rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice'
    // }
}

  useEffect(() => {
    // Update activeContacts whenever basicInfo changes
    const initialContacts = getInitialContacts(basicInfo);
    setActiveContacts(initialContacts);
  }, [basicInfo]);


  useEffect(() => {
    // Check for session_id in URL parameters
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    if (sessionId) {
      verifyPayment(sessionId);
    }
  }, [location]);

  const verifyPayment = async (sessionId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/verify-payment`, { sessionId });
      if (response.data.status === 'success') {
        setModalContent({
          title: 'Payment Successful',
          message: `Thank you for your purchase. Your account has been upgraded. Amount: $${(response.data.amount_total / 100).toFixed(2)} ${response.data.currency.toUpperCase()}`
        });
        setShowModal(true);
      } else {
        setError('There was an error processing your payment. Please contact support.');
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      setError('There was an error verifying your payment. Please contact support.');
    }
  };

  

  const getCurrentTier = () => {
    return pricingTiers.find(tier => tier.contacts >= activeContacts) || pricingTiers[pricingTiers.length - 1];
  };

  const handleSubscription = async (priceId) => {
    setLoading(true);
    setError(null);
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/create-checkout-session`, {
        priceId,
        userId,
        no_of_contacts: pricingTiers.find(tier => tier.priceId === priceId).contacts,
        successUrl: `${window.location.origin}/confirm-deploy-agents`,
      });

      const { id: sessionId } = response.data;

      const result = await stripe.redirectToCheckout({ sessionId });
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        setError(`Server error: ${error.response.data.error || 'Unknown error'}`);
      } else if (error.request) {
        setError('Network error: Unable to reach the server. Please check your internet connection and try again.');
      } else {
        setError(`Error: ${error.message}`);
      }
    }
    setLoading(false);
  };

  const handleActivate = () => {
    const currentTier = getCurrentTier();
    if (currentTier.priceId) {
      handleSubscription(currentTier.priceId);
    } else {
      // Handle "Contact Sales" logic here
      console.log("Contact Sales for custom pricing");
      // You might want to redirect to a contact form or open a modal
    }
  };

  const features = [
    { name: "AI-Driven Meeting Scheduling  " ,description:"Automatically identifies and schedules meetings with high-quality B2B prospects. "},
    { name: "Automated Email Outreach ",description:"Personalized outreach emails and follow-up sequences handled by AI." },
    { name: "Lead Qualification " ,description:"AI filters and qualifies leads before scheduling meetings." },
    { name: "Auto-Generated Emails and Domains",description:"Automatic generation of email IDs and domains to ensure compliance and reduce spam flags." },
    { name: "Task and Process Automation ",description:"Automates the entire lead-to-meeting process" },
    { name: "Seamless Calendar Integration  ",description:"Syncs with popular calendar platforms for automatic scheduling. " },
    { name: "Real-Time Analytics and Reporting ",description:" Provides insights into email engagement, meeting success rates, and conversion metrics. " },
    { name: "Compliance and Spam Control ", description:"Maintains high email deliverability and ensures compliance with spam regulations. " },
    { name: "Unlimited Mailboxes and Contact Storage ",description:"No limits on the number of mailboxes or contacts you can store. " },
    { name: "Unlimited Users & Clients ",description:"Team collaboration with no user limit. " },
  ];

  const currentTier = getCurrentTier();

  const marks = {
    0: '1,000',
    12.5: '5,000',
    25: '10,000',
    37.5: '25,000',
    50: '50,000',
    62.5: '100,000',
    75: '200,000',
    87.5: '300,000',
    100: '500,000'
  };

  const sliderToContacts = (value) => {
    const contactValues = [1000, 5000, 10000, 25000, 50000, 100000, 200000, 300000, 500000];
    return contactValues[Math.round(value / 12.5)];
  };

  const contactsToSlider = (contacts) => {
    const contactValues = [1000, 5000, 10000, 25000, 50000, 100000, 200000, 300000, 500000];
    return contactValues.indexOf(contacts) * 12.5;
  };

  const isCurrentPlan = (currentTier.priceId === basicInfo?.current_plan) && checkSubscriptionValidity(basicInfo.subscription_start_date) ;

  
  const hasExpiredSubscription = () => {
    return basicInfo?.current_plan && 
           !checkSubscriptionValidity(basicInfo?.subscription_start_date);
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/confirm-deploy-agents');
  };

  const menuItems = [
    {
      key: 'sub1',
      label: 'Dashboard',
      icon: <MailOutlined />,
      path: '/',
    },
    {
      key: 'sub2',
      label: 'Onboarding',
      icon: <AppstoreOutlined />,
      path: '/onboarding',
    },
    {
      key: 'sub3',
      label: 'Customize AI Personality',
      icon: <SettingOutlined />,
      path: '/customize-ai-personality',
    },
    {
      key: 'sub4',
      label: 'Workflow & Role',
      icon: <SettingOutlined />,
      path: '/workflow-and-role-setup',
      // disabled: true
    },
    {
      key: 'sub5',
      label: 'Data Integration & Training',
      icon: <SettingOutlined />,
      path: '/data-integration-and-training',
      // disabled: true
    },
    {
      key: 'sub6',
      label: 'Connect Calendly',
      icon: <SettingOutlined />,
      path: '/connect-calendly',
      // disabled: true
    },
    {
      key: 'sub7',
      label: 'Review Samples',
      icon: <SettingOutlined />,
      path: '/review-samples',
      // disabled: true
    }
  ];

  // const onClick = (e) => {
  //   const selectedItem = menuItems.find(item => item.key === e.key);
  //   if (selectedItem && selectedItem.path) {
  //     navigate(selectedItem.path);
  //   }
  // };

  return (
      <PageBackground>
        <Container fluid>

          <Card className='d-none d-lg-block'>
            <Steps
                items={[
                  {
                    title: 'Dashboard',
                    status: 'finish',
                    icon:  <DashboardOutlined />,
                  },
                  {
                    title: 'Onboarding',
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    title: 'Customize AI Personality',
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    title: 'Workflow & Role',
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    title: 'Data Integration & Training',
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    title: 'Connect Calendly',
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    title: 'Review Samples',
                    status: 'finish',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    title: 'Deploy Agents',
                    status: 'process',
                    icon:  <RocketOutlined />,

                  }
                ]}
            />
          </Card>
          <Card className='p-3 d-sm-block d-none d-lg-none'>
            <Steps
               items={[
                {
                  status: 'finish',
                  icon:  <DashboardOutlined />,
                },
                {
                  status: 'finish',
                  icon: <UserOutlined />,
                },
                {
                  status: 'finish',
                  icon:  <RobotOutlined />,
                },
                {
                  status: 'finish',
                  icon: <ApartmentOutlined />,
                },
                {
                  status: 'finish',
                  icon: <DatabaseOutlined />,
                },
                {
                  status: 'finish',
                  icon: <CalendarOutlined />,
                },
                {
                  status: 'finish',
                  icon: <FileSearchOutlined />,
                },
                {
                  status: 'process',
                  icon: <DollarOutlined />,

                }
              ]}
            />
          </Card>

          <div className="d-flex flex-column flex-md-row mt-4">
            <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
            {/* <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block"> */}
              <SideMenu currentStep={9} activePage="/pricing" />
            {/* </Card> */}
            </Card>
            <div className='mb-3'>
              <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
                Navigation Menu
              </Button>
            </div>
            <Drawer title="Navigation Menu" onClose={onClose} open={open}>
                <SideMenu currentStep={8} activePage="/pricing" />
            </Drawer>

            <div className="w-100">
              <Card>
                  {hasExpiredSubscription() && (
                    <div className="alert alert-danger text-center">
                      Your Subscription has Expired.
                    </div>
                  )}
                  <div className='row'>
                    <div className="col-xl-6 col-lg-12">
                        <div className='pricing-container'>
                          <h2 className="pricing-title">AI SDR</h2>
                          <p className="pricing-subtitle">Hire digital 24/7 AI SDRs by Gomeet AI</p>

                          <div className="pricing-details">
                            <h2>Starts from</h2>
                            <h1 className="pricing-amount">
                              ${currentTier.price ? currentTier.price : 'Custom'}
                              <span className="pricing-period">/month</span>
                            </h1>
                            <p className="pricing-billing">Billed annually</p>
                          </div>

                          <div className="active-contacts-slider">
                              <Slider
                                min={0}
                                max={100}
                                step={12.5}
                                marks={marks}
                                value={contactsToSlider(activeContacts)}
                                onChange={(value) => setActiveContacts(sliderToContacts(value))}
                                tooltipVisible={false}
                              />
                            <div className="slider-label">
                              <span>{activeContacts.toLocaleString()} active contacts/month</span>
                              <Tooltip title="Info about active contacts">
                                <FiInfo />
                              </Tooltip>
                            </div>
                          </div>

                          <button
                              className={`activate-button ${isCurrentPlan ? 'current-plan' : ''}`}
                              onClick={handleActivate}
                              disabled={loading || isCurrentPlan}
                          >
                            {isCurrentPlan
                                ? 'Current Plan'
                                : !isCurrentPlan
                                    ? (loading ? 'Processing...' : 'Activate Now')
                                    : 'Contact Sales'}
                          </button>

                          {error && <div className="error-message">{error}</div>}

                          <div className="features-list">
                            {features.map((feature, index) => (
                                 <Tooltip title={feature.description}><div key={index} className={`feature-item ${feature.highlight ? 'highlight' : ''}`}>

                                  <MdOutlineKeyboardDoubleArrowRight /> <span>{feature.name}</span>
                                  {feature.isNew && <span className="new-badge">New</span>}
                                </div></Tooltip>
                            ))}
                          </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                    <div className="lottie-animation">
                                        <Lottie options={defaultOptions}
                                                height={400}
                                                width={400}
                                        />
                                    </div>
                    </div>
                  </div>

              </Card>
            </div>


          </div>



          <Modal
            visible={showModal}
            onOk={handleModalClose}
            onCancel={handleModalClose}
            okText="Continue"
          >
            <div className='text-center'>
            <Lottie options={completedPricingOptions}
                                                height={120}
                                                width={120}
                                        />
              <h4>{modalContent.title}</h4>
              <p>{modalContent.message}</p>
            </div>
          </Modal>
    </Container>
      </PageBackground>
  );
};

export default Pricing;
