import React, { useState, useEffect } from "react";
import { Typography, Button, Checkbox, Space, Spin } from "antd";
import { Form as AntForm } from "antd";
import { Container, Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import { CalendarOutlined } from "@ant-design/icons";
import axios from "axios";
import Cookies from "js-cookie";
import {
  FaCalendarAlt,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { SiCalendly } from "react-icons/si";

const { Title, Paragraph } = Typography;

const BookADemo = ({ document_id, form, handleFormChange,formDetails}) => {
  console.log("document_id", document_id);
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  //   const [isChecked, setIsChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(
    form.getFieldValue("is_pin_meeting") || false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isFetchingEvents, setIsFetchingEvents] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const { userId, basicInfo } = useSelector((state) => state.app);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const error = params.get("error");
    if (code) {
      console.log("handling the code---->");

      handleOAuthCallback(code);
      setIsConnected(true);
    } else if (error) {
      setError("Failed to connect to Calendly. Please try again.");
    } else if (
    formDetails &&
    formDetails.calendly_access_token &&
    formDetails.calendly_refresh_token
    ) {
      setIsConnected(true);
      fetchEvents();
    } else {
      setIsConnected(false);
    }
  }, [location]);

  const handleOAuthCallback = async (code) => {
    setIsLoading(true);
    setError("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/aichat/calendly_oauth_callback_ai_chat`,
        {
          code: code,
          document_id: document_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        setIsConnected(true);
        fetchEvents();
      } else {
        setError(
          response.data.error ||
            "Failed to connect to Calendly. Please try again."
        );
      }
    } catch (error) {
      console.error("Error handling OAuth callback:", error);
      setError(
        "An error occurred while connecting to Calendly. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };
  console.log("isLoading", isLoading);

  const handleConnectCalendly = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/aichat/connect_calendly_aichat`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
          params: {
            document_id,
          },
        }
      );
      console.log("response.data.auth_url", response.data.auth_url);
      window.location.href = response.data.auth_url;
    } catch (error) {
      console.error("Error connecting to Calendly:", error);
      setError("Failed to initiate Calendly connection. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEvents = async () => {
    setIsFetchingEvents(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/aichat/get_calendly_events_ai_chat`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
          params: { document_id: document_id },
        }
      );
      setEvents(response.data.events);
      console.log("response.data.events", response.data.events);

      // Auto-select the first event if available, otherwise use the one from basicInfo
      if (formDetails && formDetails.calendly_event_id) {
        // setSelectedEvent(formDetails.calendly_event_id);
        handleEventSelection(formDetails.calendly_event_id)
      }
      else if (response.data.events.length > 0) {
        // setSelectedEvent(response.data.events[0].id);
        handleEventSelection(response.data.events[0].id)

      }
      setIsConnected(true);
    } catch (error) {
      console.error("Error fetching Calendly events:", error);
      setError(
        "Failed to fetch Calendly events. Please reconnect your calendly."
      );
      setIsConnected(false);
    } finally {
      setIsFetchingEvents(false);
    }
  };
  const handleEventSelection = async (eventValue) => {
    console.log("elected event-------->",eventValue);
    
    setSelectedEvent(eventValue)
    // e.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/aichat/set_calendly_event_ai_chat`,
        {
          document_id: document_id,
          event_id: eventValue,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      
      // navigate('/review-samples');
    } catch (error) {
      console.error("Error selecting event:", error);
      setError("Failed to select event. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // if (selectedEvent){
    //     handleEventSelection()
    // }
    // Set initial state of the checkbox based on form values
  }, [form,selectedEvent]);
  console.log("selectedEvent",selectedEvent);
  

  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      {error && <Alert message={error} type="error" showIcon />}

      {!isConnected ? (
        <>
          <Button
            type="primary"
            icon={<SiCalendly />}
            size="large"
            className="connect-calendly-goals"  
            onClick={handleConnectCalendly}
            loading={isLoading}
          >
            {isConnected ? "Calendly Connected" : "Connect Calendly"}
          </Button>

          <Paragraph>
            Connect your Calendly account to seamlessly integrate scheduling
            into your workflow. This will allow potential clients to book demos
            directly through your Calendly link.
          </Paragraph>
        </>
      ) : (
        <>
          <div>
            <Alert variant="success" className="mb-4">
              <FaCheckCircle className="mr-2 d-inline-block" /> Calendly is
              connected successfully!
            </Alert>
            <p className="mb-3">Please select an event for scheduling:</p>
            {isFetchingEvents ? (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading events...</span>
                </Spinner>
                {/* <Spin tip="Loading events..."/> */}
              </div>
            ) : (
              <Form >
                <Form.Group className="mb-3">
                  <Form.Control
                    as="select"
                    value={selectedEvent}
                    // onChange={(e) => setSelectedEvent(e.target.value)}
                    onChange={(e) => handleEventSelection(e.target.value)}
                    required
                    disabled={isLoading}
                    className="event-select"
                  >
                    <option value="" disabled>
                      Select an event
                    </option>
                    {events.map((event) => (
                      <option key={event.id} value={event.id}>
                        {event.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Paragraph>
                By selecting an event, your preferences are saved automatically.
                </Paragraph>
                <AntForm.Item name="is_pin_meeting" valuePropName="checked">
                  <Checkbox
                    onChange={(e) => {
                      const newValue = e.target.checked;
                      setIsChecked(newValue);
                      form.setFieldsValue({ is_pin_meeting: newValue });
                      handleFormChange({ is_pin_meeting: newValue });
                    }}
                  >
                    Pin Book a Meeting button to chat
                  </Checkbox>
                </AntForm.Item>

                <Paragraph>
                  By checking this box, you agree to allow access to your
                  Calendly scheduling information. This enables us to provide a
                  smooth booking experience for your potential clients.
                </Paragraph>
                {/* <div className="text-center">
                                                        <Button type="submit" className="continue-btn" disabled={isLoading || !selectedEvent}>
                                            
                                                            {isLoading ? (
                                                                <TailSpin height="20" width="20" color="white" />
                                                            ) : (
                                                                'Select Event'
                                                            )}
                                                        </Button>
                                                    </div> */}
              </Form>
            )}
          </div>
        </>
      )}

      {isLoading && <Spin size="large" />}
    </Space>
  );
};

export default BookADemo;
