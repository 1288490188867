import React, { useState, useEffect, useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Form, DatePicker, Pagination, Collapse, Spin, message, Select } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;


const LoadingStates = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error'
};

// Loading spinner component with customizable message
const LoadingSpinner = ({ message: loadingMessage = 'Loading...' }) => (
  <div className="loading-container">
    <Spin size="large" />
    <p className="loading-message">{loadingMessage}</p>
    <style jsx>{`
      .loading-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        gap: 16px;
      }
      .loading-message {
        color: #666;
        margin: 0;
      }
    `}</style>
  </div>
);


const VisitorCard = ({ visitor }) => (
  <div className="visitor-card">
    <div className="visitor-avatar">
      <div className="avatar-placeholder">{visitor.name ? visitor.name.charAt(0) : '?'}</div>
    </div>
    <div className="visitor-info">
      <h4>{visitor.name} <span className="visitor-action">
        {visitor.action === 'visited' ? 'visited your landing page' : 'interacted with your sales agent'}
      </span></h4>
      <p>{new Date(visitor.created_at).toLocaleString()} · {visitor.country}</p>
      <p>{visitor.title} at {visitor.company_name}</p>
    </div>
    <div className="visitor-buttons">
      <button className="btn btn-outline-primary" onClick={() => window.location.href = `mailto:${visitor.email}`}>Follow Up</button>
      {visitor.linkedin_url && (
        <a className="btn btn-outline-secondary" href={visitor.linkedin_url} target="_blank" rel="noopener noreferrer">LinkedIn</a>
      )}
    </div>
  </div>
);

export const Visitors = () => {
  const { userInfo: userInfoState = { is_superuser: false } } = useSelector(state => state.app);
  const { userId } = useSelector(state => state.app);
  
  // Enhanced loading states
  const [visitorsLoadingState, setVisitorsLoadingState] = useState(LoadingStates.IDLE);
  const [graphLoadingState, setGraphLoadingState] = useState(LoadingStates.IDLE);
  const [usersLoadingState, setUsersLoadingState] = useState(LoadingStates.IDLE);
  
  // Regular states
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const [visitors, setVisitors] = useState([]);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedUser, setSelectedUser] = useState();
  const [usersList, setUsersList] = useState([]);
  const [companiesList, setCompanies] = useState([]);
  const [companyFilter, setCompanyFilter] = useState(undefined);
  const [loading, setLoading] = useState({
    companies: false,
    users: false,
    graphs: false
  });

  const [form] = Form.useForm();

 const fetchUsersList = useCallback(async (companyId = null) => {
    if (!userInfoState.is_superuser) return;
    try {
      setUsersLoadingState(LoadingStates.LOADING);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/get-users`, {
        admin: userInfoState.is_superuser,
        company_id: companyId
      });
      setUsersList(response.data.users);
      setUsersLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsersLoadingState(LoadingStates.ERROR);
      message.error('Failed to fetch users list');
    }
  }, [userInfoState.is_superuser]);

  const fetchCompaniesList = useCallback(async () => {
    if (!userInfoState.is_superuser) return;
    try {
      setLoading(prev => ({ ...prev, companies: true }));
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/get-companies`, {
        admin: userInfoState.is_superuser
      });
      setCompanies(response.data.companies);
    } catch (error) {
      console.error('Error fetching companies:', error);
      message.error('Failed to fetch companies');
    } finally {
      setLoading(prev => ({ ...prev, companies: false }));
    }
  }, [userInfoState.is_superuser]);

  const fetchVisitors = useCallback(async () => {
    try {
      setVisitorsLoadingState(LoadingStates.LOADING);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/visitors/fetch_visitors/`, {
        user_id: userInfoState.is_superuser ? selectedUser : userId,
        company_id: companyFilter,
        page: currentPage,
        per_page: pageSize,
        start_date: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null,
        end_date: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null,
        is_admin: userInfoState.is_superuser,
      });
      setVisitors(response.data.visitors);
      setTotalVisitors(response.data.pagination.total_visitors);
      setVisitorsLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      console.error('Error fetching visitors:', error);
      setVisitorsLoadingState(LoadingStates.ERROR);
      message.error('Failed to fetch visitors');
    }
  }, [currentPage, dateRange, pageSize, selectedUser, companyFilter, userId, userInfoState.is_superuser]);

  
  // Update fetchGraphCounts similarly
  const fetchGraphCounts = useCallback(async () => {
    try {
      setGraphLoadingState(LoadingStates.LOADING);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/visitors/fetch_graph_counts/`, {
        user_id: userInfoState.is_superuser ? selectedUser : userId,
        company_id: companyFilter,
        start_date: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null,
        end_date: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null,
        is_admin: userInfoState.is_superuser,
      });
      updateCharts(response.data);
      setGraphLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      console.error('Error fetching graph data:', error);
      setGraphLoadingState(LoadingStates.ERROR);
      message.error('Failed to fetch graph data');
    }
  }, [dateRange, selectedUser, userId, companyFilter, userInfoState.is_superuser]);


  useEffect(() => {
    if (userInfoState.is_superuser) {
      fetchCompaniesList();
      fetchUsersList();
    }
  }, [userInfoState.is_superuser, fetchCompaniesList, fetchUsersList]);

  useEffect(() => {
    fetchVisitors();
    fetchGraphCounts();
  }, [selectedUser, fetchVisitors, fetchGraphCounts]);




  const [dailyVisitorsOptions, setDailyVisitorsOptions] = useState({
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: []
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
    },
    colors: ['#E91E63'],
    legend: {
      position: 'top',
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Visitors'],
      markers: {
        fillColors: ['#E91E63']
      }
    }
  });

  const [dailyVisitorsSeries, setDailyVisitorsSeries] = useState([{
    name: 'Visitors',
    data: []
  }]);

  const [industriesOptions, setIndustriesOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
    },
    colors: ['#E91E63'],
    legend: {
      position: 'top',
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Traction based on Industries'],
      markers: {
        fillColors: ['#E91E63']
      }
    }
  });

  const [industriesSeries, setIndustriesSeries] = useState([{
    name: 'Visitors',
    data: []
  }]);

  const [companySizeOptions, setCompanySizeOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
    },
    colors: ['#E91E63'],
    legend: {
      position: 'top',
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Traction based on Company Size'],
      markers: {
        fillColors: ['#E91E63']
      }
    }
  });

  const [companySizeSeries, setCompanySizeSeries] = useState([{
    name: 'Visitors',
    data: []
  }]);

  const [jobTitleOptions, setJobTitleOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
    },
    colors: ['#E91E63'],
    legend: {
      position: 'top',
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Traction based on Job Title'],
      markers: {
        fillColors: ['#E91E63']
      }
    }
  });

  const [jobTitleSeries, setJobTitleSeries] = useState([{
    name: 'Visitors',
    data: []
  }]);

  const updateCharts = (data) => {
    setDailyVisitorsOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: Object.keys(data.visitors_count)
      }
    }));

    setDailyVisitorsSeries([{
      name: 'Visitors',
      data: Object.values(data.visitors_count)
    }]);

    setIndustriesOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: Object.keys(data.industry)
      }
    }));

    setIndustriesSeries([{
      name: 'Visitors',
      data: Object.values(data.industry)
    }]);

    setCompanySizeOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: Object.keys(data.company_size)
      }
    }));

    setCompanySizeSeries([{
      name: 'Visitors',
      data: Object.values(data.company_size)
    }]);

    setJobTitleOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: Object.keys(data.title)
      }
    }));

    setJobTitleSeries([{
      name: 'Visitors',
      data: Object.values(data.title)
    }]);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };
  const handleUserChange = (value) => {
    setSelectedUser(value);  
    setCurrentPage(1);
    
    console.log("sss"+value);
  };

  
  const handleCompanyFilter = async (value) => {
    setCompanyFilter(value);
    setSelectedUser(undefined);
    
    if (value) {
      await fetchUsersList(value);
    } else {
      setUsersList([]);
    }
    setCurrentPage(1);
  };

  const handleSearch = () => {
    fetchVisitors();
    fetchGraphCounts();
  };
  const filterCompany = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  
  const filterUser = (input, option) => {
    const fullName = `${option.children}`.toLowerCase();
    return fullName.indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <div className='container-fluid visitors-main mt-4'>
    <div className="bg-white rounded shadow p-4">
      <div className='mb-3'>
        <Form
          className='d-flex align-items-end'
          layout="inline"
          form={form}
        >
          {userInfoState.is_superuser && (
            <>
              <Form.Item label="Select Company" className="mb-0">
                <Select
                  showSearch
                  placeholder="Filter by company"
                  onChange={handleCompanyFilter}
                  value={companyFilter}
                  style={{ width: 200 }}
                  allowClear
                  filterOption={filterCompany}
                  loading={loading.companies}
                  optionFilterProp='children'
                >
                  {companiesList.map(company => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Select User" className="mb-0">
                <Select
                  showSearch
                  placeholder="Select a user"
                  onChange={handleUserChange}
                  value={selectedUser}
                  style={{ width: 200 }}
                  allowClear
                  loading={usersLoadingState === LoadingStates.LOADING}
                  filterOption={filterUser}
                  optionFilterProp="children"
                  >
                    {usersList.map(user => (
                    <Option key={user.id} value={user.id}>
                      {`${user.firstname} ${user.lastname}`}
                    </Option>
                  ))}
                  </Select>
              </Form.Item>
            </>
          )}
          <Form.Item label={`VISITORS (${totalVisitors})`}>
            <RangePicker 
              onChange={handleDateChange} 
              disabled={visitorsLoadingState === LoadingStates.LOADING}
            />
          </Form.Item>
          <Form.Item>
            <button 
              className="btn btn-primary" 
              onClick={handleSearch}
              disabled={visitorsLoadingState === LoadingStates.LOADING || graphLoadingState === LoadingStates.LOADING}
            >
              {(visitorsLoadingState === LoadingStates.LOADING || graphLoadingState === LoadingStates.LOADING) ? (
                <Spin size="small" />
              ) : 'Search'}
            </button>
          </Form.Item>
        </Form>
      </div>

        {/* Daily Visitors Chart Section */}
        <div className='card mb-4'>
          <div className='card-body'>
            {graphLoadingState === LoadingStates.LOADING ? (
              <LoadingSpinner message="Loading visitor statistics..." />
            ) : graphLoadingState === LoadingStates.ERROR ? (
              <div className="error-message">Failed to load visitor statistics. Please try again.</div>
            ) : (
              <ReactApexChart options={dailyVisitorsOptions} series={dailyVisitorsSeries} type='area' height={350} />
            )}
          </div>
        </div>

        {/* Traction Based Graphs Section */}
        <Collapse defaultActiveKey={['1']} className='mb-4'>
          <Panel header="Traction Based Graphs" key="0">
            <p>To effectively evaluate these insights, it is essential to view them through the lens of your industry experience.</p>
            {graphLoadingState === LoadingStates.LOADING ? (
              <LoadingSpinner message="Loading traction graphs..." />
            ) : graphLoadingState === LoadingStates.ERROR ? (
              <div className="error-message">Failed to load traction graphs. Please try again.</div>
            ) : (
              <>
                <div style={{border:'1px solid #D3D3D3'}}>
                  <ReactApexChart options={industriesOptions} series={industriesSeries} type='bar' height={300} />
                </div>
                <div style={{border:'1px solid #D3D3D3'}}>
                  <ReactApexChart options={companySizeOptions} series={companySizeSeries} type='bar' height={300} />
                </div>
                <div style={{border:'1px solid #D3D3D3'}}>
                  <ReactApexChart options={jobTitleOptions} series={jobTitleSeries} type='bar' height={300} />
                </div>
              </>
            )}
          </Panel>
        </Collapse>

        {/* Visitors List Section */}
        <div className='visitors-list'>
          {visitorsLoadingState === LoadingStates.LOADING ? (
            <LoadingSpinner message="Loading visitors..." />
          ) : visitorsLoadingState === LoadingStates.ERROR ? (
            <div className="error-message">Failed to load visitors. Please try again.</div>
          ) : visitors.length === 0 ? (
            <div className="no-data-message">No visitors found for the selected criteria.</div>
          ) : (
            visitors.map((visitor) => (
              <VisitorCard key={visitor._id} visitor={visitor} />
            ))
          )}
        </div>

        {/* Pagination Section */}
        {visitors.length > 0 && visitorsLoadingState === LoadingStates.SUCCESS && (
          <div className='d-flex justify-content-center mt-4'>
            <Pagination
              current={currentPage}
              total={totalVisitors}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger={false}
              disabled={visitorsLoadingState === LoadingStates.LOADING}
            />
          </div>
        )}
      </div>

      <style jsx>{`
        .visitors-main {
          font-family: Arial, sans-serif;
        }
        .visitor-card {
          display: flex;
          align-items: center;
          padding: 15px;
          border: 1px solid #e8e8e8;
          margin-bottom: 15px;
          border-radius: 5px;
        }
        .visitor-avatar {
          margin-right: 15px;
        }
        .avatar-placeholder {
          width: 50px;
          height: 50px;
          background-color: #f0f0f0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          color: #333;
        }
        .visitor-info {
          flex-grow: 1;
        }
        .visitor-info h4 {
          margin: 0;
          font-size: 16px;
        }
        .visitor-action {
          font-weight: normal;
          color: #666;
        }
        .visitor-info p {
          margin: 5px 0 0;
          font-size: 14px;
          color: #666;
        }
        .visitor-buttons {
          display: flex;
          gap: 10px;
        }
      `}</style>
    </div>
  );
};

export default Visitors;