import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Breadcrumb, Drawer, Spin, Steps } from 'antd';
import { FaLinkedin, FaFacebook, FaTwitter, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styled from "styled-components";
import {
  ApartmentOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileSearchOutlined,
  MailOutlined,
  RobotOutlined,
  RocketOutlined,
  UserOutlined,
  MessageOutlined
} from "@ant-design/icons";
import { SideMenu } from '../components/Common/SideMenu';
import { Button, Container, Spinner } from "react-bootstrap";
import { useSelector } from 'react-redux';

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  transition: all 0.3s ease;
`;

const EmailThread = styled.div`
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow: hidden;
`;

const EmailHeader = styled.div`
  padding: 15px;
  background-color: #f8f9fa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const EmailContent = styled.div`
  padding: 15px;
  border-top: 1px solid #e6e6e6;
`;

const EmailMetadata = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`;

const Badge = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  ${props => {
    switch (props.type) {
      case 'follow_up':
        return 'background-color: #e3f2fd; color: #1976d2;';
      case 'not_opened':
        return 'background-color: #fff3e0; color: #f57c00;';
      case 'opened':
        return 'background-color: #e8f5e9; color: #2e7d32;';  // Green color for opened
      case 'reply':
        return 'background-color: #e8f5e9; color: #2e7d32;';
      case 'first_step':
        return 'background-color: #e3f2fd; color: #1976d2;';
      default:
        return 'background-color: #f5f5f5; color: #616161;';
    }
  }}
`;

const ReplySection = styled.div`
  margin-left: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-left: 3px solid #1976d2;
  border-radius: 4px;
`;

const ProspectReply = styled.div`
  margin: 15px 0;
  padding: 15px;
  background-color: #e8f5e9;
  border-left: 3px solid #4caf50;
  border-radius: 4px;

  .reply-content {
    font-size: 14px;
    color: #1a1a1a;
    line-height: 1.5;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: start;
  gap: 15px;
  margin-bottom: 20px;
`;

const EmailDisplay = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { documentId, personEmail, user_id, showHeader } = location.state;
  const [loading, setLoading] = useState(false);
  const [emailChain, setEmailChain] = useState([]);
  const [companyDescription, setCompanyDescription] = useState('');
  const [personName, setPersonName] = useState('');
  const [personFacebook, setPersonFacebook] = useState('');
  const [personTwitter, setPersonTwitter] = useState('');
  const [personLinkedin, setPersonLinkedin] = useState('');
  const [continueBtnLoading, setContinueBtnLoading] = useState(false);
  const { userId, basicInfo: basicInfoState } = useSelector(state => state.app);
  const [open, setOpen] = useState(false);
  const wsRef = useRef(null);
  const [expandedEmails, setExpandedEmails] = useState({});

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const generateEmail = async () => {
      setLoading(true);
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/integration/generate_custom_email', {
          person_email: personEmail,
          document_id: documentId,
          user_id: user_id
        });

        if (response.status === 200) {
          setEmailChain(response.data.email_chain || []);
          setCompanyDescription(response.data.person_data.company_description);
          setPersonName(response.data.person_data.name);
          setPersonFacebook(response.data.person_data.person_facebook || '');
          setPersonTwitter(response.data.person_data.person_twitter || '');
          setPersonLinkedin(response.data.person_data.person_linkedin || '');
          
          // Expand the latest email by default
          if (response.data.email_chain?.length > 0) {
            setExpandedEmails({ [response.data.email_chain[0].id]: true });
          }
          
          setLoading(false);
        } else if (response.status === 202) {
          setCompanyDescription(response.data.person_data.company_description);
          setPersonName(response.data.person_data.person_name);
          setPersonFacebook(response.data.person_data.person_facebook || '');
          setPersonTwitter(response.data.person_data.person_twitter || '');
          setPersonLinkedin(response.data.person_data.person_linkedin || '');
          handleWebSocketConnection(process.env.REACT_APP_WS_URL);
        }
      } catch (error) {
        console.error('Error generating email:', error);
        setLoading(false);
      }
    };

    generateEmail();
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  const handleWebSocketConnection = (websocketUrl) => {
    const socket = new WebSocket(websocketUrl);
    wsRef.current = socket;

    socket.onmessage = (event) => {
      const responseData = JSON.parse(event.data);
      if (responseData.status === 'success') {
        const newEmail = {
          id: responseData.message.id,
          subject: responseData.message.subject,
          body: responseData.message.email_body,
          timestamp: new Date().toISOString(),
          sender: 'agent',
          replies: []
        };
        setEmailChain(prevChain => [newEmail, ...prevChain]);
        setExpandedEmails(prev => ({ ...prev, [newEmail.id]: true }));
        setCompanyDescription(responseData.message.person_data.company_info.short_description);
          setPersonName(responseData.message.person_data.name);
          setPersonFacebook(responseData.message.person_data.facebook_url || '');
          setPersonTwitter(responseData.message.person_data.twitter_url || '');
          setPersonLinkedin(responseData.message.person_data.linkedin_url || '');
        setLoading(false);
        socket.close();
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setLoading(false);
    };
  };

  const toggleEmail = (emailId) => {
    setExpandedEmails(prev => ({
      ...prev,
      [emailId]: !prev[emailId]
    }));
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const renderSocialIcons = () => (
    <SocialIcons>
      {personLinkedin && (
        <a href={personLinkedin} target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={20} color="#0077b5" />
        </a>
      )}
      {personFacebook && (
        <a href={personFacebook} target="_blank" rel="noopener noreferrer">
          <FaFacebook size={20} color="#3b5998" />
        </a>
      )}
      {personTwitter && (
        <a href={personTwitter} target="_blank" rel="noopener noreferrer">
          <FaTwitter size={20} color="#1da1f2" />
        </a>
      )}
    </SocialIcons>
  );

  const handleContinue = async () => {
    setContinueBtnLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/update_review_samples`, {
        user_id: userId
      });
      if (basicInfoState && basicInfoState.current_plan) {
        navigate('/confirm-deploy-agents');
      } else {
        navigate('/pricing');
      }
    } catch (error) {
      console.error('Error updating review samples:', error);
    } finally {
      setContinueBtnLoading(false);
    }
  };

  const renderEmailMetadata = (email) => (
    <EmailMetadata>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '8px' }}>
        <div>
          <span style={{ color: '#666', marginRight: '15px' }}>
            <strong>From:</strong> {email.sender === 'agent' ? 'Sales Agent' : personName}
          </span>
          <span style={{ color: '#666' }}>
            <strong>To:</strong> {email.sender === 'agent' ? personName : 'Sales Agent'}
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <CalendarOutlined style={{ color: '#666' }} />
          <span style={{ color: '#666' }}>{formatDate(email.timestamp)}</span>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
       
        
        {email.sequence_number > 1 && (
          <Badge type="sequence">#{email.sequence_number}</Badge>
        )}
        {email.has_prospect_reply && (
          <Badge type="reply">
            <MessageOutlined style={{ marginRight: '4px' }} />
            Has Reply
          </Badge>
        )}
      </div>
    </EmailMetadata>
  );
  
  const renderEmailContent = (email) => (
    <>
      <div dangerouslySetInnerHTML={{ __html: email.body }} />
      
      {/* Render prospect reply */}
      {email.prospect_reply && (
        <ProspectReply>
          <EmailMetadata>
            <CalendarOutlined style={{ color: '#2e7d32' }} />
            <span style={{ color: '#2e7d32' }}>
              {formatDate(email.prospect_reply.timestamp)}
            </span>
            <Badge type="reply">Reply from {personName}</Badge>
          </EmailMetadata>
          <div className="mt-2 reply-content">
            {email.prospect_reply.text}
          </div>
        </ProspectReply>
      )}
    </>
  );


  

  const renderEmailChain = () => (
    <div className="space-y-4">
      {emailChain.map((email) => (
        <EmailThread key={email.id}>
          {/* Email Header */}
          <EmailHeader onClick={() => toggleEmail(email.id)}>
            <div className="w-100">
              {renderEmailMetadata(email)}
              <h5 className="mb-0 mt-2">{email.subject}</h5>
              {(email.replies?.length > 0 || email.has_prospect_reply) && (
                <small style={{ color: '#666' }}>
                  <MailOutlined style={{ marginRight: '5px' }} />
                  {email.has_prospect_reply && email.replies?.length > 0 ? 
                    `${email.replies.length} follow-ups • Has reply` :
                    email.has_prospect_reply ? 
                      'Has reply' :
                      `${email.replies.length} ${email.replies.length === 1 ? 'follow-up' : 'follow-ups'}`
                  }
                </small>
              )}
            </div>
            {expandedEmails[email.id] ? 
              <FaChevronUp size={16} style={{ color: '#666' }} /> : 
              <FaChevronDown size={16} style={{ color: '#666' }} />
            }
          </EmailHeader>
          
          {/* Email Content and Replies */}
          {expandedEmails[email.id] && (
            <EmailContent>
              {renderEmailContent(email)}
              
              {/* Follow-up Emails */}
              {email.replies?.length > 0 && (
                <div className="mt-4 space-y-4">
                  {email.replies.map((reply) => (
                    <ReplySection key={reply.id}>
                      {renderEmailMetadata(reply)}
                      {renderEmailContent(reply)}
                    </ReplySection>
                  ))}
                </div>
              )}
            </EmailContent>
          )}
        </EmailThread>
      ))}
    </div>
  );
  

  return (
    <PageBackground>
      <Container fluid>
        {showHeader && (
          <>
            <Card className='d-none d-lg-block'>
              <Steps
                items={[
                  {
                    title: 'Dashboard',
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    title: 'Onboarding',
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    title: 'Customize AI Personality',
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    title: 'Workflow & Role',
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    title: 'Data Integration & Training',
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    title: 'Connect Calendly',
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    title: 'Review Samples',
                    status: 'process',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    title: 'Deploy Agents',
                    status: 'wait',
                    icon: <RocketOutlined />,
                  }
                ]}
              />
            </Card>
            
            <Card className='p-3 d-sm-block d-none d-lg-none'>
              <Steps
                items={[
                  {
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    status: 'process',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <RocketOutlined />,
                  }
                ]}
              />
            </Card>
          </>
        )}
        
        <div className="d-flex flex-column flex-md-row mt-4">
          {showHeader && (
            <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
              <SideMenu currentStep={7} activePage="/review-samples" />
            </Card>
          )}
          
          <div className='mb-3'>
            <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
              Navigation Menu
            </Button>
          </div>
          
          <Drawer title="Navigation Menu" onClose={onClose} open={open}>
            {showHeader && <SideMenu currentStep={7} activePage="/review-samples" />}
          </Drawer>
          
          <div className="w-100 ps-md-4">
            <Card>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                  <Spinner animation="border" role="status" />
                </div>
              ) : (
                <>
                  <div className="mb-4">
                    <div className="d-flex align-items-start gap-3">
                      <img
                        src="https://via.placeholder.com/50"
                        alt="Profile"
                        style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                      />
                      <div>
                        <h5 className="mb-1">{personName}</h5>
                        {renderSocialIcons()}
                        <p className="mb-0 mt-2 text-muted">
                          <strong>Company:</strong> {companyDescription}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <p className="text-secondary fw-bold">
                      CONVERSATION HISTORY WITH {personName.toUpperCase()}
                    </p>
                  </div>

                  <div>
                    {renderEmailChain()}
                  </div>

                  <div className="text-center mt-4">
                    <Button
                      variant="primary"
                      className="continue-btn"
                      size="lg"
                      onClick={handleContinue}
                      disabled={continueBtnLoading}
                    >
                      {continueBtnLoading ? (
                        <Spinner animation="border" role="status" />
                      ) : (
                        'Continue'
                      )}
                    </Button>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </Container>
    </PageBackground>
  );
}

export default EmailDisplay;