import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { TailSpin } from 'react-loader-spinner';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { Collapse, Drawer, Menu, Steps,  Table, Space, message } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { basicInfo } from '../components/reducers/slice';
import ScrollableTableNegative from '../components/ScrollableTableNegative';
import ScrollableTableProspect from '../components/ScrollableTableProspect';
import ValidateNegativeCompaniesModal from '../components/ValidateNegativeCompaniesModal';
import ValidateProspectListModal from '../components/ValidateProspectListModal';
import './AudienceFilters.css';
import TaskProgress from '../components/TaskProgress';  
import styled from "styled-components";
import TrainingFilesList from '../components/TrainingFiles';

import {
  ApartmentOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  CaretRightOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FileSearchOutlined,
  MailOutlined,
  RobotOutlined,
  RocketOutlined,
  SettingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
  DeleteOutlined,
  LinkOutlined,
  UploadOutlined

} from "@ant-design/icons";
import { SideMenu } from '../components/Common/SideMenu';
import { useTaskProgressDispatch } from '../contexts/TaskProgressContext';

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // margin-bottom: 30px;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }
`;

function AudienceFilters() {
  const { userId, basicInfo: basicInfoState } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');
  const navigate = useNavigate();

    // New state for training section
  const [trainingUrl, setTrainingUrl] = useState('');
  const [trainingFile, setTrainingFile] = useState(null);
  const [trainingFiles, setTrainingFiles] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const trainingFileInputRef = useRef(null);
  const [directInstructions, setDirectInstructions] = useState('');

  // Refs for file inputs
  const negativeCompaniesFileInputRef = useRef(null);
  const ownProspectListFileInputRef = useRef(null);

  // State for multi-select dropdowns
  const [titles, setTitles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [companySizes, setCompanySizes] = useState([]);
  const [idealClientProfileKeywords, setIdealClientProfileKeywords] = useState([]);

  // State for file uploads
  const [negativeCompaniesFile, setNegativeCompaniesFile] = useState(null);
  const [ownProspectListFile, setOwnProspectListFile] = useState(null);

  // State for modal
  const [showModalNegative, setShowModalNegative] = useState(false);
  const [showModalProsPect, setShowModalProspect] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // State to trigger re-render of ScrollableTableNegative and ScrollableTableProspect
  const [negativeCompaniesUploaded, setNegativeCompaniesUploaded] = useState(false);
  const [prospectListUploaded, setProspectListUploaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]); // Initialize with all panels open

  const onCollapseChange = (keys) => {
    setOpenKeys(keys);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (basicInfoState) {
      // Pre-select dropdown options
      setTitles(basicInfoState.ideal_titles ? basicInfoState.ideal_titles.map(title => ({ value: title, label: title })) : []);
      setLocations(basicInfoState.ideal_locations ? basicInfoState.ideal_locations.map(location => ({ value: location, label: location })) : []);
      setIndustries(basicInfoState.ideal_industries ? basicInfoState.ideal_industries.map(industry => ({ value: industry, label: industry })) : []);
      setCompanySizes(basicInfoState.ideal_company_size ? basicInfoState.ideal_company_size.map(size => ({ value: size, label: size })) : []);
      setIdealClientProfileKeywords(basicInfoState.ideal_client_profile_keywords ? basicInfoState.ideal_client_profile_keywords.map(keyword => ({ value: keyword, label: keyword })) : []);
      // Set direct instructions if it exists in basicInfoState
      if (basicInfoState.direct_instructions) {
        setDirectInstructions(basicInfoState.direct_instructions);
      }
    }
  }, [basicInfoState]);

  // Fetch existing training files on component mount
  useEffect(() => {
    fetchTrainingFiles();
  }, []);

  const fetchTrainingFiles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/agent_setup/training_files/${userId}`);
      setTrainingFiles(response.data.files);
    } catch (error) {
      console.error('Error fetching training files:', error);
      message.error('Failed to fetch training files');
    }
  };

  const handleUrlFetch = async () => {
    if (!trainingUrl) {
      message.warning('Please enter a valid URL');
      return;
    }

    setIsFetching(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/fetch_and_train`, {
        url: trainingUrl,
        userId: userId
      });
      message.success('Successfully processed website content');
      setTrainingUrl('');
    } catch (error) {
      console.error('Error processing URL:', error);
      message.error('Failed to process website content');
    } finally {
      setIsFetching(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);

    setIsUploading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/upload_training_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      message.success(`${file.name} uploaded successfully`);
      
      // Reset file input
      if (trainingFileInputRef.current) {
        trainingFileInputRef.current.value = '';
      }

      // Force refresh of training files list
      const trainingFilesListElement = document.querySelector('.custom-table');
      if (trainingFilesListElement) {
        trainingFilesListElement.key = Date.now();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('Failed to upload file');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileDelete = async (fileId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/agent_setup/training_file/${fileId}`);
      message.success('File deleted successfully');
      fetchTrainingFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
      message.error('Failed to delete file');
    }
  };

  const handleSearch = async (inputValue, filterType) => {
    try {
      const response = await axios.post('https://nexusmobileapis.nexus-data.io/people/get_filter_data', {
        filter_value: inputValue,
        filter_type: filterType,
        filter_index: 'index_for_people',
      });
      const data = response.data.unique_names.map(item => ({
        value: item.key,
        label: `${item.key} (${item.doc_count})`,
      }));
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const loadTitlesOptions = async (inputValue) => {
    return handleSearch(inputValue, 'person.title');
  };

  const loadLocationsOptions = async (inputValue) => {
    return handleSearch(inputValue, 'person.country');
  };

  const loadIndustriesOptions = async (inputValue) => {
    return handleSearch(inputValue, 'person.organization.industry');
  };

  const companySizesOptions = [
    { value: '1-10', label: '1-10' },
    { value: '11-20', label: '11-20' },
    { value: '21-50', label: '21-50' },
    { value: '51-100', label: '51-100' },
    { value: '101-200', label: '101-200' },
    { value: '201-500', label: '201-500' },
    { value: '501-1000', label: '501-1000' },
    { value: '1001-2000', label: '1001-2000' },
    { value: '2001-5000', label: '2001-5000' },
    { value: '5001-10000', label: '5001-10000' },
    { value: '10001+', label: '10001+' },
  ];

  const beautifyErrorMessage = (error) => {
    try {
      const errorObj = JSON.parse(error);
      if (errorObj.error) {
        return errorObj.error.replace(/^\["|"\]$/g, '');
      }
    } catch (e) {
      console.error('Error parsing error message:', e);
    }
    return 'An unexpected error occurred. Please try again.';
  };

  const handleSearchAudience = async () => {
    setLoadingSearch(true);
    const formData = new FormData();
    const audienceFilters = {
      ideal_titles: titles.map(t => t.value),
      ideal_industries: industries.map(i => i.value),
      ideal_company_size: companySizes.map(cs => cs.value),
      ideal_locations: locations.map(l => l.value),
      ideal_client_profile_keywords: idealClientProfileKeywords.map(k => k.value),
      direct_instructions: directInstructions
    };

    formData.append('data', JSON.stringify(audienceFilters));
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/add_audience_filters/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch(basicInfo({ ...basicInfo, ...audienceFilters }));
      navigate('/connect-calendly');
    } catch (error) {
      console.error('Error adding audience filters:', error);
      const errorMessage = error.response && error.response.data ? beautifyErrorMessage(JSON.stringify(error.response.data)) : 'Error adding audience filters. Please try again.';
      setFlashMessage(errorMessage);
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleCheckAudience = async () => {
    setLoading(true);
    try {
        const audienceFilters = {
            ideal_titles: titles.map(t => t.value),
            ideal_industries: industries.map(i => i.value),
            ideal_company_size: companySizes.map(cs => cs.value),
            ideal_locations: locations.map(l => l.value),
            ideal_client_profile_keywords: idealClientProfileKeywords.map(k => k.value),
          };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/check_audience`, audienceFilters, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setFlashMessage(`${formatNumber(response.data.count)} available audience`);
    } catch (error) {
      console.error('Error checking audience:', error);
      setFlashMessage('Error checking audience. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num;
  };

  const handleNegativeCompaniesFileChange = (e) => {
    const file = e.target.files[0];
    setNegativeCompaniesFile(file);
    if (file) {
      setShowModalNegative(true);
    }
  };

  const handleOwnProspectListFileChange = (e) => {
    const file = e.target.files[0];
    setOwnProspectListFile(file);
    if (file) {
      localStorage.removeItem('completionViewed');
      setShowModalProspect(true);
    }
  };

  const handleNegativeCompaniesUploadComplete = () => {
    setNegativeCompaniesUploaded(prevState => !prevState);
  };

  const handleProspectListCompaniesUploadComplete = () => {
    setProspectListUploaded(prevState => !prevState);
  };

  const handleNegativeModalClose = () => {
    setShowModalNegative(false);
    setNegativeCompaniesFile(null);
    if (negativeCompaniesFileInputRef.current) {
      negativeCompaniesFileInputRef.current.value = '';
    }
  };

  const handleProspectModalClose = () => {
    setShowModalProspect(false);
    setOwnProspectListFile(null);
    if (ownProspectListFileInputRef.current) {
      ownProspectListFileInputRef.current.value = '';
    }
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      render: (date) => new Date(date).toLocaleDateString()
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            type="text" 
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleFileDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  const UploadContainer = styled.div`
  position: relative;
  
  .file-input-wrapper {
    border: 2px dashed #e8e8e8;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background: #fafafa;
    transition: all 0.3s ease;
    position: relative;
    
    &:hover {
      border-color: #1890ff;
      background: #f0f7ff;
    }
    
    &.uploading {
      border-color: #1890ff;
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .upload-content {
    position: relative;
    z-index: 1;
  }

  .upload-icon {
    font-size: 24px;
    color: #1890ff;
    margin-bottom: 8px;
  }

  .upload-text {
    color: #666;
    margin-bottom: 8px;
  }

  .helper-text {
    color: #666;
    font-size: 12px;
    margin-top: 8px;
  }

  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 2;
  }

  .loading-text {
    margin-top: 12px;
    color: #1890ff;
    font-weight: 500;
  }
`;
  
  return (
    <PageBackground>
      <Container fluid>
        <Card className='d-none d-lg-block'>
          <Steps
            items={[
              {
                title: 'Dashboard',
                status: 'finish',
                icon: <DashboardOutlined />,
              },
              {
                title: 'Onboarding',
                status: 'finish',
                icon: <UserOutlined />,
              },
              {
                title: 'Customize AI Personality',
                status: 'finish',
                icon: <RobotOutlined />,
              },
              {
                title: 'Workflow & Role',
                status: 'finish',
                icon: <ApartmentOutlined />,
              },
              {
                title: 'Data Integration & Training',
                status: 'process',
                icon: <DatabaseOutlined />,
              },
              {
                title: 'Connect Calendly',
                status: 'wait',
                icon: <CalendarOutlined />,
              },
              {
                title: 'Review Samples',
                status: 'wait',
                icon: <FileSearchOutlined />,
              },
              {
                title: 'Deploy Agents',
                status: 'wait',
                icon: <RocketOutlined />,
              }
            ]}
          />
        </Card>
        <Card className='p-3 d-sm-block d-none d-lg-none'>
          <Steps
            items={[
              {
                status: 'finish',
                icon: <DashboardOutlined />,
              },
              {
                status: 'finish',
                icon: <UserOutlined />,
              },
              {
                status: 'finish',
                icon: <RobotOutlined />,
              },
              {
                status: 'finish',
                icon: <ApartmentOutlined />,
              },
              {
                status: 'process',
                icon: <DatabaseOutlined />,
              },
              {
                status: 'wait',
                icon: <CalendarOutlined />,
              },
              {
                status: 'wait',
                icon: <FileSearchOutlined />,
              },
              {
                status: 'wait',
                icon: <DollarOutlined />,
              }
            ]}
          />
        </Card>

        <div className="d-flex flex-column flex-md-row mt-4">
          <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
            <SideMenu currentStep={5} activePage="/audience-filters" />
          </Card>
          <div className='mb-3'>
            <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
              Navigation Menu
            </Button>
          </div>
          <Drawer title="Navigation Menu" onClose={onClose} open={open}>
            <SideMenu currentStep={5} activePage="/audience-filters" />
          </Drawer>

          <div className="w-100">
            <Card>
              <h3 className='main-card-heading'>Select Ideal Customer Profile</h3>
              {flashMessage && <div className='alert alert-info mb-4'>{flashMessage}</div>}
              <Collapse
                activeKey={openKeys}
                onChange={onCollapseChange}
                size="large"
                expandIconPosition="right"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="custom-collapse me-2 mb-4"
                items={[{
                  key: '1',
                  label: 'Select ICP from our database',
                  children: <>
                  <Row>
                      <Col lg={6} md={12}>
                        <Form.Group className="mb-4">
                          <Form.Label>Select Ideal Titles</Form.Label>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={loadTitlesOptions}
                            defaultOptions
                            onChange={(selected) => setTitles(selected)}
                            value={titles}
                          />
                        </Form.Group>
                      </Col>
                      <Col  lg={6} md={12}>
                        <Form.Group className="mb-4">
                          <Form.Label>Select Ideal Locations</Form.Label>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={loadLocationsOptions}
                            defaultOptions
                            onChange={(selected) => setLocations(selected)}
                            value={locations}
                          />
                        </Form.Group>
                      </Col>
                      <Col  lg={6} md={12}>
                        <Form.Group className="mb-4">
                          <Form.Label>Select Ideal Industries</Form.Label>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={loadIndustriesOptions}
                            defaultOptions
                            onChange={(selected) => setIndustries(selected)}
                            value={industries}
                          />
                        </Form.Group>
                      </Col>
                      <Col  lg={6} md={12}>
                        <Form.Group className="mb-4">
                          <Form.Label>Select Ideal Company Sizes</Form.Label>
                          <Select
                            isMulti
                            options={companySizesOptions}
                            onChange={(selected) => setCompanySizes(selected)}
                            value={companySizes}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                }]}
              />
              <Collapse
                activeKey={openKeys}
                onChange={onCollapseChange}
                size="large"
                expandIconPosition="right"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="custom-collapse me-2 mb-4"
                items={[{
                  key: '2',
                  label: 'Upload your own prospect list',
                  children: <>
                  <div className='mb-3'>
                    <Form.Group>
                      <Form.Label>Upload Prospect List</Form.Label>
                      <Form.Control
                        type="file"
                        name="own_prospect_list"
                        ref={ownProspectListFileInputRef}
                        onChange={handleOwnProspectListFileChange}
                        accept=".csv"
                      />
                      <Form.Text className="text-muted">
                        Upload a CSV file with your target prospect contacts.
                      </Form.Text>
                    </Form.Group>
                    <a href="/sample_prospect_list.csv" download="sample_prospect_list.csv">
                      Download Sample File
                    </a>
                    <div className="mt-3 " >
                      <ScrollableTableProspect key={prospectListUploaded} />
                    </div>
                  </div>
                  </>
                }]}
              />
              <Collapse
                activeKey={openKeys}
                onChange={onCollapseChange}
                size="large"
                expandIconPosition="right"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="custom-collapse me-2 mb-4"
                items={[{
                  key: '3',
                  label: 'Exclude Companies',
                  children: <>
                    <div className='mb-3'>
                      <Form.Group controlId="negativeCompanies">
                        <Form.Label>Upload Exclusion List</Form.Label>
                        <Form.Control
                          type="file"
                          name="negative_companies"
                          ref={negativeCompaniesFileInputRef}
                          onChange={handleNegativeCompaniesFileChange}
                          accept=".csv"
                        />
                        <Form.Text className="text-muted">
                          Upload a CSV file with contacts you want to exclude (e.g., individuals from competitors or existing customers). The system will ensure no emails are sent to these contacts.
                        </Form.Text>
                      </Form.Group>
                      <a href="/sample_exclusion_list.csv" download="sample_exclusion_list.csv">
                        Download Sample File
                      </a>
                      <div className="mt-3">
                        <ScrollableTableNegative key={negativeCompaniesUploaded} />
                      </div>
                    </div>
                  </>
                }]}
              />
              <Collapse
                activeKey={openKeys}
                onChange={onCollapseChange}
                size="large"
                expandIconPosition="right"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="custom-collapse me-2 mb-4"
                items={[{
                  key: '4',
                  label: 'Train your assistant',
                  children: <>
                    
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                    <Form.Label>Direct Instructions</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={8}
                        value={directInstructions}
                        onChange={(e) => setDirectInstructions(e.target.value)}
                        placeholder="Enter direct instructions for your assistant to ensure while crafting personalized emails ..."
                        style={{ minHeight: '200px' }}
                      />
                      <UploadContainer>
                        <Form.Label>Upload Training Files</Form.Label>
                        <div className={`file-input-wrapper ${isUploading ? 'uploading' : ''}`}>
                          <div className="upload-content">
                            <UploadOutlined className="upload-icon" />
                            <div className="upload-text">
                              {isUploading ? 'Uploading...' : 'Click or drag file to upload'}
                            </div>
                          </div>
                          <input
                            type="file"
                            ref={trainingFileInputRef}
                            onChange={handleFileUpload}
                            accept=".pdf,.doc,.docx,.txt"
                            className="file-input"
                            disabled={isUploading}
                          />
                          <div className="helper-text">
                            Supported formats: PDF, DOC, DOCX, TXT
                          </div>
                          
                          {isUploading && (
                            <div className="loader-overlay">
                              <TailSpin
                                height={40}
                                width={40}
                                color="#1890ff"
                                ariaLabel="uploading"
                              />
                              <div className="loading-text">
                                Uploading your file...
                              </div>
                            </div>
                          )}
                        </div>
                      </UploadContainer>

                      <TrainingFilesList userId={userId} key={isUploading} />
                    </Form.Group>
                  </Col>
                </Row>
                  </>
                }]}
              />


              <div className='my-3 text-center'>
                <button onClick={handleSearchAudience} disabled={loading} className='continue-btn btn btn-md btn-primary me-2'>
                  {loadingSearch ? (
                    <TailSpin height="20" width="20" color="white" />
                  ) : (
                    'Continue'
                  )}
                </button>
              </div>
              <ValidateNegativeCompaniesModal
                show={showModalNegative}
                onHide={handleNegativeModalClose}
                negativeCompaniesFile={negativeCompaniesFile}
                userId={userId}
                onUploadComplete={handleNegativeCompaniesUploadComplete}
              />
              <ValidateProspectListModal
                show={showModalProsPect}
                onHide={handleProspectModalClose}
                ownProspectListFile={ownProspectListFile}
                userId={userId}
                onUploadComplete={handleProspectListCompaniesUploadComplete}
              />
            </Card>
          </div>
        </div>
      </Container>
      
      
    </PageBackground>
  );
}

export default AudienceFilters;
