import React, { useState, useEffect } from 'react';
import { Collapse, Typography, Button, Form, Spin, message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import axios from 'axios';
import GetContactInformation from './GetContactInformation';
import StartATrial from './StartATrial';
import BookADemo from './BookADemo';

const { Panel } = Collapse;
const { Title } = Typography;

const CustomToggle = ({ checked, onChange }) => (
  <div
    style={{
      width: '44px',
      height: '22px',
      backgroundColor: checked ? '#1890ff' : '#f0f0f0',
      borderRadius: '11px',
      display: 'flex',
      alignItems: 'center',
      padding: '2px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    }}
    onClick={onChange}
  >
    <div
      style={{
        width: '18px',
        height: '18px',
        backgroundColor: 'white',
        borderRadius: '50%',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        transition: 'transform 0.3s',
        transform: `translateX(${checked ? '22px' : '0'})`,
      }}
    />
  </div>
);

const GoalsTab = ({ form, handleFormChange, formDetails, document_id, codeParam, activeTab }) => {
  const [loading, setLoading] = useState(false);
  const [changedValues, setChangedValues] = useState({});
  // const [editform] = Form.useForm();

  
  const [goals, setGoals] = useState([
    { id: 2, title: 'Start a trial', enabled: true },
    { id: 3, title: 'Book a demo', enabled: true },
  ]);


  const [activeKeys, setActiveKeys] = useState(['2','3']);
 
  // const [activeKeys, setActiveKeys] = useState([]);

  useEffect(() => {
    if (formDetails.goals) {
      const updatedGoals = formDetails.goals.map((goal, index) => ({
        id: index + 1,
        title: goal.title,
        // enabled: goal.enabled || (goal.title === 'Book a demo' && codeParam !== null),
      }));
  
      form.setFieldsValue({ goals: updatedGoals });
      handleFormChange({ goals: updatedGoals });
      
    }
    setChangedValues({});
    console.log("activekeys----->",activeKeys);
    console.log("goalsssss----->",goals);
    

    return () => {
      setChangedValues({});
    };
  }, [document_id, activeTab, codeParam]);

  const handleValuesChange = (changedValue, allValues) => {
    setChangedValues(prevChangedValues => ({ ...prevChangedValues, ...changedValue }));
    handleFormChange(allValues);
  };

  const handleToggle = (goalId) => {
    const updatedGoals = goals.map(goal => 
      goal.id === goalId ? { ...goal, enabled: !goal.enabled } : goal
    );
    setGoals(updatedGoals);
    
    const updatedValues = { goals: updatedGoals };
    form.setFieldsValue(updatedValues);
    handleValuesChange(updatedValues, form.getFieldsValue());
    
    setActiveKeys(prevKeys => {
      const key = goalId.toString();
      return prevKeys.includes(key)
        ? prevKeys.filter(k => k !== key)
        : [...prevKeys, key];
    });
  };
  
  

  const renderGoalContent = (title) => {
    switch (title) {
      case 'Start a trial':
        return (
          <StartATrial
            form={form}
            actionButton={formDetails.action_button}
            actionButtonUrl={formDetails.action_button_url}
            handleFormChange={handleValuesChange}
          />
        );
      case 'Book a demo':
        return <BookADemo form={form} handleFormChange={handleValuesChange} document_id={document_id} codeParam={codeParam} is_pin_meeting={formDetails.is_pin_meeting} formDetails={formDetails} />;
      default:
        return null;
    }
  };

  function isValidURL(url) {

    try {
      if (url !==undefined && url !=='' && url !==null){
        
        const regex = /^https?:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=]*)?$/;
        console.log(regex.test(url));
        
        return regex.test(url);

      }
      return true
    } catch (error) {
      return false;
    }
  }



  const handleSave = async () => {
    if (Object.keys(changedValues).length === 0) {
      message.destroy();
      message.info("No changes to save");
      return;
    }
   

    setLoading(true);
    try {
      const values = await form.validateFields();
      const goalsData = goals.map(goal => ({
        title: goal.title,
        enabled: goal.enabled,
      }));
      console.log("values just before--->",values);
 

      const updatedValues = {
        ...changedValues,
        goals: goalsData,
        contact_information: values.contact_information,
        action_button: values.action_button==''?null:values.action_button,
        action_button_url: values.action_button_url==''?null:values.action_button_url,
        pin_action_button: values.pin_action_button,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/aichat/update_chatbot/${document_id}/`,
        updatedValues
      );
      if (response.status==200){

        message.destroy();
        message.success("Chat bot goals updated successfully");
        // handleFormChange(response.data);
        setChangedValues({});
      }
      else {
        message.destroy();
        message.error('Failed to save Goals');
      }

    } catch (error) {
      if (error.response){
        message.destroy()
        message.error(error.response.data.message || 'Failed to Goals');
      }
      else if (!error.isAxiosError) {
        console.error('Validation failed:', error);
        return;
      }
      else{
        console.error("Error updating chat bot goals:", error);
        message.destroy();
        message.error("Failed to update chat bot goals");

      }

    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading} tip="Saving goals...">
      <Form form={form} onValuesChange={handleValuesChange}>
        <Title level={2}>Goals</Title>
        <Collapse
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          activeKey={activeKeys}
          className='me-2'
          style={{ backgroundColor: 'transparent', border: 'none' }}
        >
          {goals.map((goal, index) => (
            <Panel
              key={goal.id}
              style={{
                marginBottom: '16px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
                overflow: 'hidden',
              }}
              header={
                <div 
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '3px 16px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggle(goal.id);
                  }}
                >
                  <span style={{ fontWeight: '500' }}>{goal.title}</span>
                  <CustomToggle
                    checked={goal.enabled}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleToggle(goal.id);
                    }}
                  />
                </div>
              }
            >
              <div style={{ padding: '16px' }}>
                {renderGoalContent(goal.title)}
              </div>
            </Panel>
          ))}
        </Collapse>
        <div style={{ marginTop: '20px', textAlign: 'right' }} className='me-2 mb-5'>
          <Button type="primary" onClick={handleSave} loading={loading} disabled={loading} >
            {loading ? 'Saving...' : 'Save Goals'}
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default GoalsTab;
