// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.search-input-knowledge-base .css-dev-only-do-not-override-qnu6hi).ant-input-outlined:focus, :where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined:focus-within {
    border-color: #1677ff;
    box-shadow: none;
    outline: 0;
    background-color: #ffffff;
}
.knowledge-base-modal .ant-modal-footer {
    text-align: right !important;
}
.knowledgebase-list ul.ant-list-items li.ant-list-item {
    border: 1px solid #ddd;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 8px;
}

`, "",{"version":3,"sources":["webpack://./src/components/KnowledgeBaseTab.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,UAAU;IACV,yBAAyB;AAC7B;AACA;IACI,4BAA4B;AAChC;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[":where(.search-input-knowledge-base .css-dev-only-do-not-override-qnu6hi).ant-input-outlined:focus, :where(.css-dev-only-do-not-override-qnu6hi).ant-input-outlined:focus-within {\n    border-color: #1677ff;\n    box-shadow: none;\n    outline: 0;\n    background-color: #ffffff;\n}\n.knowledge-base-modal .ant-modal-footer {\n    text-align: right !important;\n}\n.knowledgebase-list ul.ant-list-items li.ant-list-item {\n    border: 1px solid #ddd;\n    padding: 10px 20px;\n    margin-bottom: 10px;\n    border-radius: 8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
