import React, { useState, useEffect } from 'react';
import { Input, Button, List, Avatar, Upload, message, Typography, Popconfirm, Row, Col, Spin } from 'antd';
import { SearchOutlined, UploadOutlined, FileTextOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Spinner } from "react-bootstrap";

const { Paragraph } = Typography;

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB in bytes

const UploadFileTab = ({ parentId }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchDocuments();
  }, [parentId]);

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/aichat/get_ai_upload_files/?parent_id=${parentId}`);
      setDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
      message.error('Failed to fetch documents');
    }
    setLoading(false);
  };

  const handleUpload = async (info) => {
    const { status } = info.file;
    if (status === 'uploading') {
      setUploading(true);
    } else if (status === 'done') {
      setUploading(false);
      message.success(`${info.file.name} file uploaded successfully.`);
      fetchDocuments(); // Refresh the list after successful upload
    } else if (status === 'error') {
      setUploading(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const beforeUpload = (file) => {
    const isValidType = file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (!isValidType) {
      message.error('You can only upload PDF or DOC files!');
      return false;
    }
    if (file.size > MAX_FILE_SIZE) {
      message.error('File size must be less than 10 MB!');
      return false;
    }
    return true;
  };

  const customUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('parent_id', parentId);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/ai_upload_file/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onSuccess(response, file);
    } catch (error) {
      console.error('Error uploading file:', error);
      onError(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/aichat/ai_upload_file/${id}/delete/`);
      message.success('File deleted successfully');
      fetchDocuments(); // Refresh the list after successful deletion
    } catch (error) {
      console.error('Error deleting file:', error);
      message.error('Failed to delete file');
    }
  };

  const filteredDocuments = documents.filter(doc => 
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
    <Row gutter={24}>
      <Col lg={7} md={12} sm={12}  xs={12}>
      <Paragraph>
          Elevate your website experience by contributing PDF or DOC files. Simply drag and drop your documents into the upload area, empowering our AI bot to better serve your needs. Unleash the potential with seamless training – the future of enhanced visitor interaction awaits.
        </Paragraph>
        <Upload.Dragger
          name="file"
          customRequest={customUpload}
          onChange={handleUpload}
          beforeUpload={beforeUpload}
          accept=".pdf,.doc,.docx"
          showUploadList={false}
          style={{ 
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1890ff',
            borderRadius: '8px',
            background: '#fafafa',
            marginTop: '20px'
          }}
        >
          {uploading ? (
            <Spin size="large" />
          ) : (
            <>
              <p style={{ marginBottom: '8px', color: '#1890ff', fontSize: '48px' }}>
                <UploadOutlined />
              </p>
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '8px' }}>Click or drag file to this area to upload</p>
              <p style={{ fontSize: '14px', color: '#888' }}>
                Support for PDF and DOC files only. File size must be less than 10 MB. Strictly prohibit from uploading company data or other sensitive files.
              </p>
            </>
          )}
        </Upload.Dragger>
      </Col>
      <Col lg={17} md={12}  sm={12} xs={12}>
      <Input
          size="large"
          placeholder="Search documents"
          prefix={<SearchOutlined />}
          style={{ marginBottom: '20px' }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ flexGrow: 1, overflowY: 'auto', maxHeight: '500px' }}>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={filteredDocuments}
          renderItem={item => (
            <List.Item
              actions={[
                <Popconfirm
                  title="Are you sure you want to delete this file?"
                  onConfirm={() => handleDelete(item._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} danger />
                </Popconfirm>
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar icon={<FileTextOutlined />} style={{ backgroundColor: '#1890ff' }} />}
                title={<a href={item.file_url} target="_blank" rel="noopener noreferrer">{item.name}</a>}
                description={
                  <>
                    <div>Type: {item.name.split('.').pop().toUpperCase()}</div>
                    <div>Updated: {formatDate(item.updated_at)}</div>
                  </>
                }
              />
            </List.Item>
          )}
        />
        </div>
      )}
      </Col>
    </Row>
    </>
  );
};

export default UploadFileTab;