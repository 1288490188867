import React, { useState, useEffect,useCallback} from "react";
import { Table, Input, Button, Space, message, Switch, Avatar, Tooltip } from "antd";
import { SearchOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import "./AIChat.css";
const { Search } = Input;

const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 1rem;
`;

const StyledTable = styled(Table)`
  .ant-table {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
`;

const AIChat = () => {
  const [chatBots, setChatBots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setcreateLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({
    search: '',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const { userId } = useSelector((state) => state.app);

  useEffect(() => {
    fetchChatBots();

    // Check for the specific URL pattern and redirect if it matches
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (location.pathname === "/chats" && code && state) {
      navigate(`/chats/${state}?code=${code}`);
    }
  }, [location,pagination.current, pagination.pageSize, filters]);

  const fetchChatBots = async () => {
    const requestData = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      ...filters
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/aichat/list_chatbots_new/`,
        { user_id: userId,...requestData}
      );
      setChatBots(response.data.chats);
      setPagination({
        ...pagination,
        total: response.data.total
      })
    } catch (error) {
      console.error("Error fetching chat bots:", error);
      message.error("Failed to fetch chat bots");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Status',
      // key: 'status',
      width: 50,
      render: () => (
        <Switch  size="small"/>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Space>
          <Avatar  src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" style={{ backgroundColor: '#1677ff' }} />
          <Link to={`/chats/${record.id}`}>{text}</Link>
        </Space>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "last_updated",
      key: "last_updated",
      sorter: (a, b) => new Date(a.last_updated) - new Date(b.last_updated),
      render: (text) => new Date(text).toLocaleString(),
    }
  ];

  const debouncedSearch = useCallback(
    (() => {
      let timeoutId;
      return (value) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setFilters(prev => ({ ...prev, search: value.trim() }));
          setPagination(prev => ({ ...prev, current: 1 }));
        }, 500);
      };
    })(),
    []
  );

  const filteredChaBots = chatBots.filter(chat =>
    chat.name.toLowerCase().includes(searchText.toLowerCase())
  
);

  const handleCreate = async () => {

    try {
      setcreateLoading(true)
      const newChatBot = {
        name: "New chatbot",
        agent_name: "AI Agent",
        company_name: "Your Company",
        welcome_message: "Welcome! How can I assist you today?",
        input_placeholder: "Type your message here...",
        customer_support_email: "support@example.com",
        response_length: "short",
        tone_of_voice: "neutral",
        language: "English",
        respond_in_conversation_language: true,
        context_type: "context_only",
        sales_role: "General Assistant",
        direct_instructions: "",
        user_id: userId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/aichat/create_chatbot/`,
        newChatBot
      );
      message.destroy()
      message.success("Chat bot created successfully");
      // Fetch the updated list of chatbots
      await fetchChatBots();
      // Navigate to the newly created chatbot
      if (response.data && response.data.id) {
        navigate(`/chats/${response.data.id}`);
      } else {
        console.error(
          "Failed to get the ID of the new chatbot:",
          response.data
        );
        message.error("Failed to get the ID of the new chatbot");
      }
    } catch (error) {
      console.error("Error creating chat bot:", error);
      message.destroy()
      message.error("Failed to create chat bot");
    }
    finally{
      setcreateLoading(false)
    }
  };
  const handleTableChange = (pagination) => {
    setPagination(pagination)
    console.log("pagination--->",pagination);
   
  };
  return (
    <PageBackground>
      <div className='card border-0'>
       <div className='card-body'>
       <div className="">
          <div className='d-flex justify-content-between align-items-center mb-4'>
          <h4>AI Chat</h4>
          <Space style={{ marginBottom: 16 }}>
        
        <Input
              placeholder="Search chat bots"
              prefix={<SearchOutlined />}
              className='search-input-aichat'
              style={{ width: "300px" }}
              onChange={(e) => debouncedSearch(e.target.value)}
        />
            <Button loading={createLoading} disabled={createLoading} type="primary" color='#f3f3f3' icon={<PlusOutlined />} onClick={handleCreate}>
              Create AI Chat
            </Button>
          </Space>
          </div>
          <Table
            columns={columns}
            dataSource={chatBots}
            loading={loading}
            rowKey="id"
            pagination={{
              ...pagination,
              
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `Total ${total} items`
            }}
            onChange={handleTableChange}
          />
        </div>
       </div>
      </div>
    </PageBackground>
  );
};

export default AIChat;
