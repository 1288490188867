import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, message, Table, Spin, Checkbox,Popconfirm} from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

// const Spinner = () => (
//   // <div className="spinner-border text-primary" role="status">
//   //   <span className="visually-hidden">Loading...</span>
//   // </div>
//   <Spin />
// );

const ScrollableTableNegative = ({ columns }) => {
  const { userId } = useSelector(state => state.app);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/agent_setup/get_negative_companies`, {
        params: { user_id: userId, page: page }
      });
      const newData = response.data.results;
      setTotalCount(response.data.count)
      
      if (newData.length === 0) {
        setHasMore(false);
      } else {
        setData(prevData => [...prevData, ...newData]);
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [userId, page]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectRow = (record) => {
    setSelectAll(false)
    const selectedRowKeys = [...selectedRows];
    if (selectedRowKeys.includes(record.company_domain)) {
      const index = selectedRowKeys.indexOf(record.company_domain);
      selectedRowKeys.splice(index, 1);
    } else {
      selectedRowKeys.push(record.company_domain);
    }
    setSelectedRows(selectedRowKeys);
  };

  const handleSelectAll = (selected) => {
    if (selected) {
      setSelectAll(true)
      const allKeys = data.map(item => item.company_domain);
      setSelectedRows(allKeys);
    } else {
      setSelectAll(false)
      setSelectedRows([]);
    }
  };

  const handleMultiDelete = async () => {
    try {
      setLoadingDelete(prev => {
        const newState = { ...prev };
        selectedRows.forEach(key => {
          newState[key] = true;
        });
        return newState;
      });

      await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/delete_multiple_negative_companies`, {
        user_id: userId,
        company_domains: selectedRows
      });

      setData(prevData => prevData.filter(item => !selectedRows.includes(item.company_domain)));
      setSelectedRows([]);
      fetchData()
      message.destroy()
      message.success('Selected items deleted successfully');
    } catch (error) {
      console.error('Error deleting multiple prospects:', error);
      message.error('Failed to delete selected items');
    } finally {
      setLoadingDelete({});
    }
  };

  const handleDeleteAll = async () => {
    try {
      setLoadingDelete(prev => {
        const newState = { ...prev };
        selectedRows.forEach(key => {
          newState[key] = true;
        });
        return newState;
      });

      await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/delete_all_prospect_negative`, {
        user_id: userId,
        collection_name: "negative_companies"
      });
      setData(prevData => prevData.filter(item => !selectedRows.includes(item.company_domain)));
      setSelectedRows([]);
      fetchData()
      message.destroy()
      message.success('All items deleted successfully');
    } catch (error) {
      console.error('Error deleting All prospects:', error);
      message.error('Failed to delete all items');
    } finally {
      setLoadingDelete({});
    }
  };

  const antColumns = [
    {
      title: <Checkbox 
        indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
        checked={selectedRows.length === data.length}
        onChange={(e) => handleSelectAll(e.target.checked)}
      />,
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => (
        <Checkbox
          checked={selectedRows.includes(record.company_domain)}
          onChange={() => handleSelectRow(record)}
        />
      ),
    },
    {
      title: 'Company Domain',
      dataIndex: 'company_domain',
      key: 'company_domain',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Popconfirm 
        title="Are you sure you want to delete this data?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => handleDelete(record)}
        >   
        <Button
        loading={loadingDelete[record.person_email]} 
        disabled={loadingDelete[record.person_email]}
          type="link"
          danger
        >
          Delete
        </Button>
        </Popconfirm>
      ),
    },
  ];

  const handleDelete = async (record) => {
    try {
      setLoadingDelete(prev => ({ ...prev, [record.company_domain]: true }));
      await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/delete_prospect_negative_row`,
        { user_id: userId, collection_name: "negative_companies", unique_col: record.company_domain }
      );
      
      setData(prevData => prevData.filter(item => item.company_domain !== record.company_domain));
      fetchData()
      message.destroy()
      message.success('Deleted successfully');

    } catch (error) {
      console.error('Error deleting prospect:', error);
      message.error('Failed to delete prospect');
    } finally {
      setLoadingDelete(prev => ({ ...prev, [record.company_domain]: false }));
    }
  };

  if (isLoading && data.length === 0) {
    return (
      <div className="text-center p-3">
        <Spin size='large' />
      </div>
    );
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <h5>Exclusion List</h5>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
      {(selectedRows.length > 0 && selectAll==false) && (
        <Popconfirm
        title="Are you sure you want to delete selected data?"
        onConfirm={handleMultiDelete}
         okText="Yes"
        cancelText="No"
        >
        <Button
          type="primary"
          danger
          style={{ marginBottom: '10px' }}
        >
          Delete Selected ({selectedRows.length})
        </Button>
        </Popconfirm>
      )} 
      {
        (selectAll==true) && (
      <Popconfirm
        title="Are you sure you want to delete all data?"
        onConfirm={handleDeleteAll}
         okText="Yes"
        cancelText="No"
        >
        <Button
          type="primary"
          danger
          style={{ marginBottom: '10px' }}
        >
          Delete All ({totalCount})
        </Button>
        </Popconfirm>
        ) 
      }

      </div>
      <div style={{ border: "1px solid #ccc" }}>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMore}
          loader={  
            data.length > 4 && (
              <div className="text-center p-2">
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </div>
            )
          }
          height={200}
          endMessage={<p style={{ textAlign: 'center' }}><b>No data to load.</b></p>}
        >
          <Table
            columns={antColumns}
            dataSource={data}
            pagination={false}
            size="small"
            rowKey={(record) => record.company_domain}
          />
        </InfiniteScroll>
      </div>
        <div style={{display:'flex',flexDirection:'row-reverse',marginTop:'15px'}}>
          <p>total results: {totalCount}</p>
        </div>
    </>
  );
};

export default ScrollableTableNegative;