// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-btn {
  background: linear-gradient(11deg, #5876fd, #c975ff);
  transition: 0.5s;
  color: #fff !important;
  border: none;
}

.ai-btn:hover {
  background: linear-gradient(102deg, #5876fd, #c975ff) !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-btn-default:disabled, :where(.css-dev-only-do-not-override-qnu6hi).ant-btn-default.ant-btn-disabled {
  cursor: not-allowed;
  color: #fff;
  border-color: #4096ff;
  background: linear-gradient(11deg, #3fc1f4, #496aff);
}

.review-accordion .accordion-item {
  border: none !important;
}

.review-accordion .accordion-body {
  padding: 21px 0 0;
}

.review-accordion button.accordion-button.collapsed {
  border: 1px solid #dcdcdc;
}

.review-accordion button.accordion-button.collapsed {
  border: 1px solid #dcdcdc;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding.scss"],"names":[],"mappings":"AAAE;EACE,oDAAA;EACA,gBAAA;EACE,sBAAA;EACA,YAAA;AACN;;AACE;EACE,gEAAA;AAEJ;;AAOE;EACE,mBAAA;EACA,WAAA;EACA,qBAAA;EACA,oDAAA;AAJJ;;AAMA;EACE,uBAAA;AAHF;;AAKA;EACE,iBAAA;AAFF;;AAIA;EACE,yBAAA;AADF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":["  .ai-btn{\n    background: linear-gradient(11deg, #5876fd, #c975ff);\n    transition: 0.5s;\n      color: #fff !important;\n      border: none;\n  }\n  .ai-btn:hover {\n    background: linear-gradient(102deg, #5876fd, #c975ff) !important;\n}  \n\n  // :where(.ai-btn.css-dev-only-do-not-override-qnu6hi).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {\n  //   color: #fff;\n  //   border-color: none;\n  //   background: linear-gradient(102deg, #5876fd, #c975ff);\n    \n  // }\n  :where(.css-dev-only-do-not-override-qnu6hi).ant-btn-default:disabled, :where(.css-dev-only-do-not-override-qnu6hi).ant-btn-default.ant-btn-disabled {\n    cursor: not-allowed;\n    color: #fff;\n    border-color: #4096ff;\n    background: linear-gradient(11deg,#3fc1f4, #496aff);\n  }\n.review-accordion .accordion-item {\n  border: none !important;\n}\n.review-accordion .accordion-body {\n  padding: 21px 0 0;\n}\n.review-accordion button.accordion-button.collapsed {\n  border: 1px solid #dcdcdc;\n}\n.review-accordion button.accordion-button.collapsed {\n  border: 1px solid #dcdcdc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
