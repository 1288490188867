import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Switch, Button, message, Spin, Collapse, Divider } from 'antd';
import axios from 'axios';
import { CaretRightOutlined } from '@ant-design/icons';
import { FaArrowRight } from "react-icons/fa6";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const { TextArea } = Input;
const { Option } = Select;

const languageList=[
  "English",
  "Hindi",
  "Kannada",
  "Bengali",
  "Odia",
  "Marathi",
]
const ContentTab = ({ form, handleFormChange, formDetails, botToneOfVoice, document_id, user_id, activeTab }) => {
  const [loading, setLoading] = useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [openKeys, setOpenKeys] = useState([]); // Initialize with all panels open
  const [isFormValid, setIsFormValid] = useState(false);
  const messages="This field is required"

 
console.log("changedValues in contentTab", changedValues);

  const onCollapseChange = (keys) => {
    setOpenKeys(keys);
  };
  useEffect(() => {
    setChangedValues({});
  
    return () => {
      // Cleanup function to reset changedValues when component unmounts
      setChangedValues({});
    };
  }, [document_id, activeTab]);

  const validateForm = async (values) => {
    const requiredFields = [
      'name', 'agent_name', 'company_name', 'welcome_message', 'input_placeholder',
      'customer_support_email', 'response_length', 'tone_of_voice', 'language',
      'context_type'
    ];

    const isValid = requiredFields.every(field => {
      const value = values[field];
      if (value === undefined) {
        console.log(`Field ${field}: Skipped validation because value is undefined`);
        return true;
      }
      const isValidValue = value && (typeof value === 'string' ? value.trim() !== '' : true);
      console.log(`Field ${field}: ${isValidValue ? 'Valid' : 'Invalid'}`);
      return isValidValue;
    });

    console.log("Form is valid:", isValid);
    setIsFormValid(isValid);
  };

  const handleValuesChange = (changedValue, allValues) => {
    console.log("Values changed", changedValue, allValues);
    setChangedValues(prevChangedValues => ({ ...prevChangedValues, ...changedValue }));
    handleFormChange(allValues);
    validateForm(allValues);
  };

  console.log("isFormValid:", isFormValid);

  const onSave = async () => {
    if (Object.keys(changedValues).length === 0) {
      message.destroy();
      message.info("No changes to save");
      return;
    }

    setLoading(true);
    const updatedValues = {
      ...changedValues,
      user_id: user_id
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/aichat/update_chatbot/${document_id}/`,
        updatedValues
      );
      message.destroy();
      message.success("Chat bot content updated successfully");
      // handleFormChange(response.data);
      setChangedValues({});
    } catch (error) {
      console.error("Error updating chat bot content:", error);
      message.destroy();
      message.error("Failed to update chat bot content");
    } finally {
      setLoading(false);
    }
  };

  const trimRule = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || value.trim() !== '') {
        return Promise.resolve();
      }
      return Promise.reject(new Error('This field cannot be just spaces'));
    },
  });

  return (
    <Form
      form={form}
      onValuesChange={handleValuesChange}
      layout="vertical"
      initialValues={formDetails}
    >
      <Collapse
          activeKey={openKeys}
          onChange={onCollapseChange}
          size="large"
          expandIconPosition="right"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="custom-collapse me-2 mb-3"
          items={[{
            key: '1',
            label: 'Bot name and welcome message',
            children: <>
             <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="name"
                    label="Chat name"
                    rules={[{ required: true,message:messages},trimRule]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                    <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> This is the name of your chat only visible to you and your team.</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="agent_name"
                    label="Agent name"
                    rules={[{ required: true,message:messages},trimRule]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> The name your AI chat will use in its messages, adding a personal touch to the interaction.</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="company_name"
                    label="Company name"
                    rules={[{ required: true,message:messages},trimRule]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Your company name displayed in the header of the chat.</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="welcome_message"
                    label="Welcome message"
                    rules={[{ required: true,message:messages},trimRule]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> The initial message your AI chat will send to greet users.</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="input_placeholder"
                    label="Input box placeholder"
                    rules={[{ required: true,message:messages},trimRule]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> The text displayed in the input box before the user starts typing.</small>
                </div>
              </div>
            </>
          }]}
          />
           {/* <Divider orientation="left" /> */}

           <Collapse
          activeKey={openKeys}
          onChange={onCollapseChange}
          expandIconPosition="right"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="custom-collapse me-2 mb-3"
          size="large"
          items={[{
            key: '2',
            label: 'Customer support email',
            children: <>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="customer_support_email"
                    label="Customer support email"
                    rules={[{ required: true,message:messages,type:'email'},trimRule]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> The email address where customer support inquiries will be sent.</small>
                </div>
              </div>
            </>
          }]}
        />
        {/* <Divider orientation="left" /> */}
        <Collapse
          activeKey={openKeys}
          onChange={onCollapseChange}
          expandIconPosition="right"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="custom-collapse me-2 mb-3"
          size="large"
          items={[{
            key: '3',
            label: 'Tone of voice',
            children: <>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="response_length"
                    label="Response length"
                    rules={[{ required: true },trimRule]}
                  >
                    <Select>
                      <Option value="short">Short</Option>
                      <Option value="medium">Medium</Option>
                      <Option value="long">Long</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> The preferred length of responses generated by the AI chat.</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="tone_of_voice"
                    label="Bot's tone of voice"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {botToneOfVoice.map((val, id) => (
                        <Option key={id} value={val.tone}>{val.tone}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> The overall tone and style of the AI chat's responses.</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="language"
                    label="Language"
                    rules={[{ required: true,message:messages},trimRule]}
                  >

                    {/* <Input /> */}
                    <Select showSearch>
                      {languageList.map((val, id) => (
                        <Option key={id} value={val}>{val}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> The primary language for the AI chat's responses.</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="respond_in_conversation_language"
                    label="Respond to the website visitor in the language started a conversation"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Enable this to allow the AI to respond in the language used by the visitor.</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="context_type"
                    label="Context"
                    rules={[{ required: true },trimRule]}
                  >
                    <Select>
                      <Option value="context_only">Context only</Option>
                      <Option value="context_and_chatgpt">Context + Chat GPT knowledge</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Choose the type of context the AI should use for generating responses.</small>
                </div>
              </div>
              {/* <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="sales_role"
                    label="AI Bot's sales role"
                    rules={[{ required: true,message:messages}]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Define the specific sales role or function of the AI chat.</small>
                </div>
              </div> */}
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="direct_instructions"
                    label="Direct instructions"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Provide specific instructions or guidelines for the AI chat's behavior.</small>
                </div>
              </div>
            </>
          }]}
          />
      <Form.Item className='mt-4 text-end' style={{marginBottom : '100px'}}>
          <Button onClick={onSave} loading={loading} disabled={loading || !isFormValid} className='me-2' type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
    </Form>
  );
};

export default ContentTab;
