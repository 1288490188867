import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { Breadcrumb, Drawer, Menu, Spin, Steps } from 'antd';
import { FaCalendarAlt, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import Lottie from 'react-lottie';
import animationData from '../assets/images/meetingSchedule.json';
import './ConnectCalendly.css';
import styled from "styled-components";
import {
    ApartmentOutlined,
    AppstoreOutlined,
    CalendarOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    DollarOutlined,
    FileSearchOutlined,
    MailOutlined,
    RobotOutlined,
    RocketOutlined,
    SettingOutlined,
    SmileOutlined,
    SolutionOutlined,
    UserOutlined
} from "@ant-design/icons";
import { SideMenu } from '../components/Common/SideMenu';
import { TailSpin } from 'react-loader-spinner';

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // margin-bottom: 30px;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }
`;

function ConnectCalendly() {
    const [isConnected, setIsConnected] = useState(false);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingEvents, setIsFetchingEvents] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, basicInfo } = useSelector(state => state.app);
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const error = params.get('error');

        if (code) {
            handleOAuthCallback(code);
        } else if (error) {
            setError('Failed to connect to Calendly. Please try again.');
        } else if (basicInfo && basicInfo.calendly_access_token && basicInfo.calendly_refresh_token) {
            setIsConnected(true);
            fetchEvents();
        } else {
            setIsConnected(false);
        }
    }, [location, userId, basicInfo]);

    const handleOAuthCallback = async (code) => {
        setIsLoading(true);
        setError('');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/integration/calendly_oauth_callback`, {
                code: code,
                user_id: userId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.success) {
                setIsConnected(true);
                fetchEvents();
            } else {
                setError(response.data.error || 'Failed to connect to Calendly. Please try again.');
            }
        } catch (error) {
            console.error('Error handling OAuth callback:', error);
            setError('An error occurred while connecting to Calendly. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleConnectCalendly = async () => {
        setIsLoading(true);
        setError('');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/integration/connect_calendly`, {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('accessToken')}`,
                }
            });
            window.location.href = response.data.auth_url;
        } catch (error) {
            console.error('Error connecting to Calendly:', error);
            setError('Failed to initiate Calendly connection. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchEvents = async () => {
        setIsFetchingEvents(true);
        setError('');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/integration/get_calendly_events`, {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('accessToken')}`,
                },
                params: { user_id: userId }
            });
            setEvents(response.data.events);

            // Auto-select the first event if available, otherwise use the one from basicInfo
            if (response.data.events.length > 0) {
                setSelectedEvent(response.data.events[0].id);
            } else if (basicInfo && basicInfo.calendly_event_id) {
                setSelectedEvent(basicInfo.calendly_event_id);
            }
            setIsConnected(true);
        } catch (error) {
            console.error('Error fetching Calendly events:', error);
            setError('Failed to fetch Calendly events. Please reconnect your calendly.');
            setIsConnected(false);
        } finally {
            setIsFetchingEvents(false);
        }
    };

    const handleEventSelection = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/integration/set_calendly_event`, {
                user_id: userId,
                event_id: selectedEvent
            }, {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('accessToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            navigate('/review-samples');
        } catch (error) {
            console.error('Error selecting event:', error);
            setError('Failed to select event. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };





    return (
        <PageBackground>
            <Container fluid >
                <Card className='d-none d-lg-block'>
                    <Steps
                        items={[
                            {
                                title: 'Dashboard',
                                status: 'finish',
                                icon: <DashboardOutlined />,
                            },
                            {
                                title: 'Onboarding',
                                status: 'finish',
                                icon: <UserOutlined />,
                            },
                            {
                                title: 'Customize AI Personality',
                                status: 'finish',
                                icon: <RobotOutlined />,
                            },
                            {
                                title: 'Workflow & Role',
                                status: 'finish',
                                icon: <ApartmentOutlined />,
                            },
                            {
                                title: 'Data Integration & Training',
                                status: 'finish',
                                icon: <DatabaseOutlined />,
                            },
                            {
                                title: 'Connect Calendly',
                                status: 'process',
                                icon: <CalendarOutlined />,
                            },
                            {
                                title: 'Review Samples',
                                status: 'wait',
                                icon: <FileSearchOutlined />,
                            },
                            {
                                title: 'Deploy Agents',
                                status: 'wait',
                                icon: <RocketOutlined />,

                            }
                        ]}
                    />
                </Card>
                <Card className='p-3 d-sm-block d-none d-lg-none'>
                    <Steps
                        items={[
                            {
                                status: 'finish',
                                icon: <DashboardOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <UserOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <RobotOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <ApartmentOutlined />,
                            },
                            {
                                status: 'finish',
                                icon: <DatabaseOutlined />,
                            },
                            {
                                status: 'process',
                                icon: <CalendarOutlined />,
                            },
                            {
                                status: 'wait',
                                icon: <FileSearchOutlined />,
                            },
                            {
                                status: 'wait',
                                icon: <DollarOutlined />,

                            }
                        ]}
                    />
                </Card>
                <div className="d-flex  flex-column flex-md-row mt-4">
                    <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
                        <SideMenu currentStep={6} activePage="/connect-calendly" />
                    </Card>
                    <div className='mb-3'>
                        <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
                            Navigation Menu
                        </Button>
                    </div>
                    <Drawer title="Navigation Menu" onClose={onClose} open={open}>
                        <SideMenu currentStep={6} activePage="/connect-calendly" />
                    </Drawer>
                    <div className="w-100">
                        <Card className="px-lg-5">
                            <Row className="align-items-center mx-lg-5">
                                <Col lg={6} md={12}>
                                    <h2 className="text-center mb-4">
                                        Connect Calendly
                                    </h2>
                                    {error && (
                                        <Alert variant="danger" className="mb-4">
                                            <FaExclamationCircle className="mr-2" /> {error}
                                        </Alert>
                                    )}
                                    {!isConnected ? (
                                        <div className="text-center">
                                            <p className="mb-4">Connect your Calendly account to seamlessly integrate scheduling into your workflow.</p>
                                            <Button onClick={handleConnectCalendly} disabled={isLoading} size="lg" className="continue-btn">
                                                {/* {isLoading ? (
                                                    <>
                                                        <Spinner animation="border" size="sm" className="mr-2" />
                                                    </>
                                                ) : (
                                                    <span className='d-flex align-items-center justify-content-center'>
                                                    <FaCalendarAlt className="me-2" /> Connect with Calendly
                                                </span>
                                                )} */}
                                                {isLoading ? (
                                                    <TailSpin height="20" width="20" color="white" />
                                                ) : (
                                                    'Connect with Calendly'
                                                )}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Alert variant="success" className="mb-4">
                                                <FaCheckCircle className="mr-2 d-inline-block" /> Calendly is connected successfully!
                                            </Alert>
                                            <p className="mb-3">Please select an event for scheduling:</p>
                                            {isFetchingEvents ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading events...</span>
                                                    </Spinner>
                                                    {/* <Spin tip="Loading events..."/> */}
                                                </div>
                                            ) : (
                                                <Form onSubmit={handleEventSelection}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            as="select"
                                                            value={selectedEvent}
                                                            onChange={(e) => setSelectedEvent(e.target.value)}
                                                            required
                                                            disabled={isLoading}
                                                            className="event-select"
                                                        >
                                                            <option value="" disabled>Select an event</option>
                                                            {events.map(event => (
                                                                <option key={event.id} value={event.id}>{event.name}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <div className="text-center">
                                                        <Button type="submit" className="continue-btn" disabled={isLoading || !selectedEvent}>
                                                            {/* {isLoading ? (
                                                                <>
                                                                    <Spinner animation="border" size="sm" className="mr-2" />
                                                                    Selecting...
                                                                </>
                                                            ) : (
                                                                'Select Event'
                                                            )} */}
                                                            {isLoading ? (
                                                                <TailSpin height="20" width="20" color="white" />
                                                            ) : (
                                                                'Select Event'
                                                            )}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                        </div>
                                    )}
                                </Col>
                                <Col lg={6} md={12} className="d-flex justify-content-center">
                                    <div className="lottie-animation">
                                        <Lottie options={defaultOptions}
                                                height={400}
                                                width={400}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>

                </div>





                {/*<Card className="connect-calendly-card">*/}
                {/*    <Card.Body>*/}
                {/*        <Row className="align-items-center">*/}
                {/*            <Col md={6}>*/}
                {/*                <h2 className="text-center mb-4">*/}
                {/*                     Connect Calendly*/}
                {/*                </h2>*/}
                {/*                {error && (*/}
                {/*                    <Alert variant="danger" className="mb-4">*/}
                {/*                        <FaExclamationCircle className="mr-2" /> {error}*/}
                {/*                    </Alert>*/}
                {/*                )}*/}
                {/*                {!isConnected ? (*/}
                {/*                    <div className="text-center">*/}
                {/*                        <p className="mb-4">Connect your Calendly account to seamlessly integrate scheduling into your workflow.</p>*/}
                {/*                        <Button onClick={handleConnectCalendly} disabled={isLoading} size="lg" className="connect-button">*/}
                {/*                            {isLoading ? (*/}
                {/*                                <>*/}
                {/*                                    <Spinner animation="border" size="sm" className="mr-2" />*/}
                {/*                                    Connecting...*/}
                {/*                                </>*/}
                {/*                            ) : (*/}
                {/*                        <span className='d-flex align-items-center justify-content-center'>*/}
                {/*                                    <FaCalendarAlt className="mr-2" /> Connect with Calendly*/}
                {/*                        </span>*/}
                {/*                            )}*/}
                {/*                        </Button>*/}
                {/*                    </div>*/}
                {/*                ) : (*/}
                {/*                    <div>*/}
                {/*                        <Alert variant="success" className="mb-4">*/}
                {/*                            <FaCheckCircle className="mr-2" /> Calendly is connected successfully!*/}
                {/*                        </Alert>*/}
                {/*                        <p className="mb-3">Please select an event for scheduling:</p>*/}
                {/*                        {isFetchingEvents ? (*/}
                {/*                            <div className="text-center">*/}
                {/*                                <Spinner animation="border" role="status">*/}
                {/*                                    <span className="visually-hidden">Loading events...</span>*/}
                {/*                                </Spinner>*/}
                {/*                            </div>*/}
                {/*                        ) : (*/}
                {/*                            <Form onSubmit={handleEventSelection}>*/}
                {/*                                <Form.Group className="mb-3">*/}
                {/*                                    <Form.Control*/}
                {/*                                        as="select"*/}
                {/*                                        value={selectedEvent}*/}
                {/*                                        onChange={(e) => setSelectedEvent(e.target.value)}*/}
                {/*                                        required*/}
                {/*                                        disabled={isLoading}*/}
                {/*                                        className="event-select"*/}
                {/*                                    >*/}
                {/*                                        <option value="" disabled>Select an event</option>*/}
                {/*                                        {events.map(event => (*/}
                {/*                                            <option key={event.id} value={event.id}>{event.name}</option>*/}
                {/*                                        ))}*/}
                {/*                                    </Form.Control>*/}
                {/*                                </Form.Group>*/}
                {/*                                <div className="text-center">*/}
                {/*                                    <Button type="submit" className="select-event-button" disabled={isLoading || !selectedEvent}>*/}
                {/*                                        {isLoading ? (*/}
                {/*                                            <>*/}
                {/*                                                <Spinner animation="border" size="sm" className="mr-2" />*/}
                {/*                                                Selecting...*/}
                {/*                                            </>*/}
                {/*                                        ) : (*/}
                {/*                                            'Select Event'*/}
                {/*                                        )}*/}
                {/*                                    </Button>*/}
                {/*                                </div>*/}
                {/*                            </Form>*/}
                {/*                        )}*/}
                {/*                    </div>*/}
                {/*                )}*/}
                {/*            </Col>*/}
                {/*            <Col md={6} className="d-flex justify-content-center">*/}
                {/*                <div className="lottie-animation">*/}
                {/*                    <Lottie options={defaultOptions}*/}
                {/*                            height={400}*/}
                {/*                            width={400}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </Card.Body>*/}
                {/*</Card>*/}
            </Container>
        </PageBackground>
    );
}

export default ConnectCalendly;
