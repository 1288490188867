import React, { useState, useEffect,useCallback } from 'react';
import { Input, Button, Typography, Table, Empty, Card, Modal, message, Spin } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

const { Title } = Typography;

const KnowledgeBases = () => {
    const { userId } = useSelector(state => state.app);
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [filters, setFilters] = useState({
        search: '',
      });
      const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
      });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newKnowledgeBaseName, setNewKnowledgeBaseName] = useState('');
    const [creatingKnowledgeBase, setCreatingKnowledgeBase] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchKnowledgeBases();
    }, [pagination.current, pagination.pageSize,filters]);

    const fetchKnowledgeBases = async () => {
        const requestData = {
            page: pagination.current,
            pageSize: pagination.pageSize,
            ...filters
          };

        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/list_knowledge_bases_new`,  { "user_id": userId,...requestData} );
            setKnowledgeBases(response.data.kbases);
            setPagination({
                ...pagination,
                total: response.data.total
              })
        } catch (error) {
            console.error('Error fetching knowledge bases:', error);
            message.error('Failed to fetch knowledge bases');
        }
        setLoading(false);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <a onClick={() => navigate(`/knowledge-base/${record._id}`,{state:record})}>{text}</a>
            ),
        },
        {
            title: 'Last updated',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
            render: (text) => new Date(text).toLocaleString(),
        }
    ];

   
    const debouncedSearch = useCallback(
        (() => {
          let timeoutId;
          return (value) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
              setFilters(prev => ({ ...prev, search: value.trim() }));
              setPagination(prev => ({ ...prev, current: 1 }));
            }, 500);
          };
        })(),
        []
      );
      const handleTableChange = (pagination) => {
        setPagination(pagination)
        console.log("pagination--->",pagination);
       
      };

    const filteredKnowledgeBases = knowledgeBases.filter(kb =>
        kb.name.toLowerCase().includes(searchText.toLowerCase())
       
    );
    console.log("knowledgeBases",knowledgeBases);
    
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setNewKnowledgeBaseName('');
    };

    const handleCreate = async () => {
        if (!newKnowledgeBaseName.trim()) {
            message.error('Please enter a name for the knowledge base');
            return;
        }

        setCreatingKnowledgeBase(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/create_knowledge_base`, { name: newKnowledgeBaseName, user_id: userId });
            message.success('Knowledge base created successfully');
            setKnowledgeBases([response.data, ...knowledgeBases]);
            handleCancel();
        } catch (error) {
            console.error('Error creating knowledge base:', error);
            message.error('Failed to create knowledge base');
        }
        setCreatingKnowledgeBase(false);
    };

    return (
        <Card style={{ margin: '24px' }}>
            <Title level={4}>Knowledge Bases</Title>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
                <Input
                    placeholder="Search knowledge bases"
                    prefix={<SearchOutlined />}
                    style={{ width: '300px' }}
                    onChange={(e) => debouncedSearch(e.target.value)}
                />
                <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
                    Create knowledge base
                </Button>
            </div>

                <Table
                    columns={columns}
                    dataSource={filteredKnowledgeBases}
                    rowKey={(record) => record.id}
                    loading={loading}
                    pagination={{...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => `Total ${total} items`
                    }}
                    onChange={handleTableChange}
                    onRow={(record) => ({
                        onClick: () => navigate(`/knowledge-base/${record._id}`,{state:record}),
                        style: { cursor: 'pointer' }
                    })}
                />

            <Modal
                title="Create Knowledge Base"
                visible={isModalVisible}
                onOk={handleCreate}
                onCancel={handleCancel}
                okText="Create"
                confirmLoading={creatingKnowledgeBase}
            >
                <Input
                    placeholder="Enter knowledge base name"
                    value={newKnowledgeBaseName}
                    onChange={(e) => setNewKnowledgeBaseName(e.target.value)}
                    disabled={creatingKnowledgeBase}
                />
                {creatingKnowledgeBase && (
                    <div style={{ marginTop: '16px', textAlign: 'center' }}>
                        <Spin /> Creating knowledge base...
                    </div>
                )}
            </Modal>
        </Card>
    );
};

export default KnowledgeBases;
