import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Form, Input, DatePicker } from 'antd';
import MeetingsCard from '../components/Common/MeetingsCard';
import ConcersationCard from '../components/Common/ConcersationCard';
export const Conversations = () => {
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%";
        }
      }
    },
    title: {
      text: 'Monthly Inflation in Argentina, 2002',
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  });
  const [series, setSeries] = useState([{
    name: 'Inflation',
    data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
  }]);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('inline');
  const { RangePicker } = DatePicker;

  return (
    <div className='container-fluid'>
        <div className='my-4'>
            <div className='card-body'>
                <Form className='d-flex align-items-end'
                    // {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    initialValues={{
                        layout: formLayout,
                    }}
                    // onValuesChange={onFormLayoutChange}
                    style={{
                        maxWidth: formLayout === 'inline',
                    }}
                    >
                    <Form.Item >
                        <label for="daterange" className='fw-medium mb-1'>Date Range</label><br/>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item>
                        <label for="daterange" className='fw-medium mb-1'>Agent ID</label><br/>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    {/* <Form.Item>
                        <label for="daterange" className='fw-medium mb-1'>Search</label><br/>
                        <Input placeholder="input placeholder" />
                    </Form.Item> */}
                    <Form.Item >
                        <Button type="primary">Search</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>

        <div className='card mb-4'>
            <div className='card-body'>
                <ReactApexChart options={options} series={series} type="bar" height={250} />
            </div>
        </div>
        <div className='card'>
            <div className='card-body'>
                <ConcersationCard/>
                <ConcersationCard/>

            </div>
        </div>
    </div>
  );
}

