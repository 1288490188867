import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { FiLogOut, FiUser } from "react-icons/fi";
import { Skeleton } from 'primereact/skeleton';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

const userDropdown = [
  {
    to: "https://app."+process.env.REACT_APP_DOMAIN+".com/profile",
    icon: 'user',
    label: "My Profile",
  },
  {
    to: "https://buyersflow."+process.env.REACT_APP_DOMAIN+".com/inbox",
    icon: 'mail',
    label: "Inbox",
  }
];

const DropdownUser = () => {
  const userInfo = useSelector(state => state.app.userInfo);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const handleLogout = () => {
    const keyToKeep = 'tourDone';

    // Get all keys from localStorage
    const keys = Object.keys(localStorage);

    // Iterate over keys and remove all except the one to keep
    keys.forEach(key => {
      if (key !== keyToKeep) {
        localStorage.removeItem(key);
      }
    });

    // Dispatch the logout action
    // dispatch(logout());

    // Remove cookies
    Cookies.remove('accessToken', { domain: '.'+process.env.REACT_APP_DOMAIN+'.com' });
    Cookies.remove('refreshToken', { domain: '.'+process.env.REACT_APP_DOMAIN+'.com' });

    // Redirect to login page
    window.location.href = 'https://gomeet.ai/?logout'
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });


  const getNameInitials = () => {
    const firstInitial = userInfo && userInfo.firstname ? userInfo.firstname.charAt(0) : '';
    const lastInitial = userInfo && userInfo.lastname ? userInfo.lastname.charAt(0) : '';
    return (firstInitial + lastInitial).toUpperCase();
  }

  return (
    <div className="relative">
      <Link
        ref={trigger}
        onClick={(e) => {
          e.preventDefault();
          setDropdownOpen(!dropdownOpen);
        }}
        className="flex items-center gap-4"

      >
        <span className="h-10 w-10 rounded-full">
          {userInfo && userInfo.profile_image_url ? <img src={userInfo.profile_image_url} style={{borderRadius: '50%'}} /> : <div
                                                        style={{
                                                            backgroundColor: "#E8E8E8",
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'black',
                                                            fontWeight: 'bold'
                                                        }}>{userInfo && userInfo.firstname ? getNameInitials() : <Skeleton shape="circle" width="40px" height="40px" />}</div>}
        </span>
      </Link>

      {/* <!-- Dropdown Start --> */}
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`dropdown-menu p-0 shadow-sm ${dropdownOpen === true ? "show" : ""}`}
          style={{ right: "0", marginTop: "10px", width: "15rem" }}
      >
        <div className="py-2 px-4 border-b">
          <h5 className="text-dark mb-0 fs-14 fw-semibold">{userInfo && userInfo.firstname + ' ' + userInfo.lastname}</h5>
           <span className="block text-xs text-muted">{userInfo && userInfo.email}</span>
        </div>
        <ul className="list-unstyled p-3 m-0">
          {/* {userDropdown.map((item) => (
            <li key={item.label} className="text-nowrap mb-2">
              <Link
                to={item.to}
                className="d-flex align-items-center text-dark text-decoration-none profile-menu-list"
              >
                <span><FeatherIcon icon={item.icon} size={15} /> </span>
                <h6 className="ms-2">{item.label}</h6>
              </Link>
            </li>
          ))} */}
          <li onClick={handleLogout} className="text-nowrap d-flex align-items-center text-dark text-decoration-none profile-menu-list" style={{"cursor":"pointer"}}>
            <span className="text-primary me-1"><FeatherIcon icon='log-out' size={14} /> </span>
            <h6 className="ms-2 mb-0">Logout</h6>

          </li>
        </ul>


      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  );
};

export default DropdownUser;
