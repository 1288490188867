import React from 'react';
import { Button, message, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const EmbedTab = ({ chatbotId }) => {
  const chatbotScriptUrl = `${window.location.origin}/chatbot/chat.umd.cjs`;
  
  const embedCode = `<script src="${chatbotScriptUrl}" data-client-id="${chatbotId}"></script>`;
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(embedCode).then(() => {
      message.success('Embed code copied to clipboard');
    }, (err) => {
      message.error('Failed to copy embed code');
      console.error('Could not copy text: ', err);
    });
  };

  const ColoredScriptTag = () => (
    <pre style={{
      backgroundColor: '#f5f5f5',
      border: '1px solid #d9d9d9',
      padding: '8px',
      borderRadius: '4px',
      fontFamily: 'monospace',
      // whiteSpace: 'pre-wrap',
      // wordBreak: 'break-all'
    }}>
      <span style={{ color: 'green' }}>&lt;script </span>
      <span style={{ color: 'blue' }}>src=</span>
      "<span style={{ color: 'black' }}>{chatbotScriptUrl}</span>"
      <span style={{ color: 'blue' }}> data-client-id=</span>
      "<span style={{ color: 'black' }}>{chatbotId}</span>"
      <span style={{ color: 'green' }}>&gt;&lt;/script&gt;</span>
    </pre>
  );

  return (
    <div>
      <Title level={4}>Embed Code</Title>
      <Paragraph>Copy and paste this code into your HTML file, just before the closing &lt;/body&gt; tag, to embed the GoMeet chatbot:</Paragraph>
      <ColoredScriptTag />
      <Button type="primary" className='button-embaded' icon={<CopyOutlined />} onClick={copyToClipboard}>
        Copy
      </Button>
    </div>
  );
};

export default EmbedTab;
