import React, { useEffect } from 'react';
import { Typography, Input, Checkbox, Form } from 'antd';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

const { Paragraph } = Typography;

const StartATrial = ({ form, actionButton, actionButtonUrl, handleFormChange }) => {

  useEffect(() => {
    // Initial form validation
   
  }, [form]);

  const handleValuesChange = (changedValues, allValues) => {
    handleFormChange(changedValues);
  };

  return (  
    <>
    <div className='row flexG1'>
      <div className='col-md-6'>
        <Form.Item
          name="action_button"
          label="Text for action button"
           layout="vertical"
          initialValue={actionButton}
          rules={[
            { whitespace: true, message: 'Text cannot be empty spaces' }
          ]}
        >
          <Input 
            placeholder="Enter the text for the action button" 
            // onChange={(e) => {
            //   const newValue = e.target.value;
            //   form.setFieldsValue({ action_button: newValue });
            //   handleValuesChange({ action_button: newValue }, form.getFieldsValue());
            // }}
          />
        </Form.Item>
      </div>
      <div className='col-md-6'>
        <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Enter the text that will be displayed on the action button prompting users to start a trial, customizing the call-to-action message.</small>
      </div>
    </div>
    <div className='row flexG1'>
      <div className='col-md-6'>
      <Form.Item
        name="action_button_url"
        label="Link for action button"
         layout="vertical"
        initialValue={actionButtonUrl}
        rules={[
          { type: 'url', message: 'Please enter a valid Link' },
          { whitespace: true, message: 'Link cannot be empty spaces' }
        ]}
      >
        <Input 
          placeholder="Enter the link for the action button" 
          // onChange={(e) => {
          //   const newValue = e.target.value;
          //   form.setFieldsValue({ action_button_url: newValue });
          //   handleValuesChange({ action_button_url: newValue }, form.getFieldsValue());
          // }}
        />
      </Form.Item>
      </div>
      <div className='col-md-6'>
        <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Provide the link that users will be directed to when they click the action button, guiding them to the specific page or process to initiate a trial.</small>
      </div>
    </div>
    <div className='row flexG1'>
      <div className='col-md-6'>
          <Form.Item
            name="is_action_button"
            valuePropName="checked"
          >
            <Checkbox
            //  onChange={(e) => {
            //   const newValue = e.target.checked;
            //   form.setFieldsValue({ is_action_button: newValue });
            //   handleValuesChange({ is_action_button: newValue }, form.getFieldsValue());
            // }}
            >
              Pin action button start a trial to chat
            </Checkbox>
          </Form.Item>
      </div>
      <div className='col-md-6'>
        <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Select this option to keep the action button for starting a trial visible and easily accessible in the chat interface, ensuring users can readily initiate a trial at any point during the conversation.</small>
      </div>
    </div>

      
      

      
      
      </>
  );
};

export default StartATrial;