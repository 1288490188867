// TaskProgress.js
import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';
import styled from 'styled-components';

const ProgressOverlay = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  width: 300px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 15px;
  animation: slideIn 0.3s ease;

  @keyframes slideIn {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.fade-out {
    animation: slideOut 0.3s ease forwards;
  }

  @keyframes slideOut {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
  border-radius: 2px;
  margin: 8px 0;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: ${props => props.status === 'failed' ? '#ff4d4f' : '#1890ff'};
  width: ${props => props.progress}%;
  transition: width 0.3s ease;
`;

const StatusText = styled.div`
  font-size: 14px;
  color: ${props => props.status === 'failed' ? '#ff4d4f' : props.status === 'completed' ? '#52c41a' : '#666'};
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: #999;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  
  &:hover {
    color: #666;
  }
`;

const STORAGE_KEY = 'taskProgressState';
const COMPLETION_VIEWED_KEY = 'completionViewed';

const TaskProgress = ({ userId }) => {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [client, setClient] = useState(null);

    useEffect(() => {
        // Load saved state
        const savedState = localStorage.getItem(STORAGE_KEY);
        const completionViewed = localStorage.getItem(COMPLETION_VIEWED_KEY);
        
        if (savedState) {
            const state = JSON.parse(savedState);
            if (state.userId === userId && !completionViewed) {
                setProgress(state.progress || 0);
                setStatus(state.status || '');
                setMessage(state.message || '');
                setVisible(true);
            }
        }

        // Setup MQTT
        const mqttClient = mqtt.connect('ws://broker.emqx.io:8083/mqtt', {
            clientId: `web_${userId}_${Math.random().toString(16).slice(2)}`,
        });

        mqttClient.on('connect', () => {
            const topic = `task_progress/${userId}/#`;
            mqttClient.subscribe(topic);
        });

        mqttClient.on('message', (topic, messageData) => {
            try {
                const data = JSON.parse(messageData.toString());
                
                // Save state
                localStorage.setItem(STORAGE_KEY, JSON.stringify({
                    userId,
                    ...data,
                    timestamp: Date.now()
                }));

                setProgress(data.progress || 0);
                setStatus(data.status || '');
                setMessage(data.message || '');
                setVisible(true);

                // Handle completion
                if (data.status === 'completed' && data.progress === 100) {
                    setTimeout(() => {
                        setFadeOut(true);
                        setTimeout(() => {
                            setVisible(false);
                            localStorage.setItem(COMPLETION_VIEWED_KEY, 'true');
                        }, 300); // Match fade-out animation duration
                    }, 5000);
                }
            } catch (error) {
                console.error('Error processing MQTT message:', error);
            }
        });

        setClient(mqttClient);

        return () => {
            if (mqttClient) {
                mqttClient.end();
            }
        };
    }, [userId]);

    if (!visible) return null;

    const handleClose = () => {
        setFadeOut(true);
        setTimeout(() => {
            setVisible(false);
            localStorage.setItem(COMPLETION_VIEWED_KEY, 'true');
        }, 300);
    };

    return (
        <ProgressOverlay className={fadeOut ? 'fade-out' : ''}>
            <Title>
                <span>Upload Progress</span>
                <CloseButton onClick={handleClose}>&times;</CloseButton>
            </Title>
            <ProgressBar>
                <ProgressFill progress={progress} status={status} />
            </ProgressBar>
            <StatusText status={status}>
                {message || `${status} - ${progress}%`}
            </StatusText>
        </ProgressOverlay>
    );
};

export default TaskProgress;