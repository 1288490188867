import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'primeicons/primeicons.css';
import Dashboard from './pages/Dashboard';
import ReviewSamples from './pages/ReviewSamples';
import EmailDisplay from './pages/EmailDisplay';
import Onboarding from './pages/Onboarding';
import AudienceFilters from './pages/AudienceFilters';
import AILanding from './pages/AILanding';
import Agents from './pages/Agents';
import ScheduleMeeting from './pages/ScheduleMeeting';
import ConnectCalendly from './pages/ConnectCalendly';
import CustomizeAIPersonality from './pages/CustomizeAIPersonality';
import WorkflowAndRoleSetup from './pages/WorkflowAndRoleSetup'
import OptOut from './pages/OptOut';
import ConfirmDeployAgents from './pages/ConfirmDeployAgents';
import Header from './components/Header';
import Cookies from 'js-cookie';
import Pricing from './pages/Pricing';
import { Meeting } from './pages/Meetings';
import { Conversations } from './pages/Conversations';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import { userId, basicInfo, userInfo, resetAppState } from './components/reducers/slice';
import Prospects from './pages/Prospects';
import Visitors from './pages/Visitors';
import AIChat from './pages/AIChat';
import AIChatDetail from './pages/AIChatDetail';
import KnowledgeBases from './pages/KnowledgeBases';
import KnowledgeBaseDetail from './pages/KnowledgeBaseDetail';

import Contacts from './pages/Contacts'; // Add this line
import WebchatInbox from './pages/WebchatInbox'; // Add this line

function MainApp() {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUserId = useSelector(state => state.app.userId);
  const currentUserInfo = useSelector((state) => state.app.userInfo);
  const currentBasicInfo = useSelector((state) => state.app.basicInfo);

  const publicRoutes = ['/login', '/register', '/ai-landing', '/schedule-meeting', '/opt-out'];

  useEffect(() => {
    // Reset the app state when the component mounts
    dispatch(resetAppState());
  }, [dispatch]);

  useEffect(() => {
    console.log('Current route:', location.pathname);
    console.log('Is public route:', publicRoutes.includes(location.pathname));

    // Function to get URL parameters
    const getUrlParameter = (name) => {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    // Check for access_token in URL
    const urlAccessToken = getUrlParameter('access_token');
    if (urlAccessToken) {
      // If access_token is in URL, save it to cookies
      Cookies.set('accessToken', urlAccessToken, { domain: '.'+process.env.REACT_APP_DOMAIN+'.com' });
      
      // Remove the access_token from the URL to avoid exposing it
      const newUrl = window.location.href.split('?')[0];
      window.history.replaceState({}, document.title, newUrl);
    }

    // Now check for the token in cookies
    const accessToken = Cookies.get('accessToken');
    if (!currentUserId && !accessToken && !publicRoutes.includes(location.pathname)) {
      console.log('Redirecting to login page');
      window.location.href = 'https://gomeet.ai/?logout';
    } else {
      // If we have a token, dispatch the userId action
      console.log('Dispatching userId action');
      dispatch(userId());
    }
  }, [dispatch, currentUserId, location.pathname, publicRoutes]);

  useEffect(() => {
    if (currentUserId && !currentUserInfo && !publicRoutes.includes(location.pathname)) {
      console.log('Fetching user info for route:', location.pathname);
      dispatch(userInfo(currentUserId));
    }
  }, [currentUserId, currentUserInfo, dispatch, location.pathname, publicRoutes]);

  useEffect(() => {
    if (currentUserId && currentBasicInfo === null && !publicRoutes.includes(location.pathname)) {
      console.log('Fetching basic info for route:', location.pathname);
      dispatch(basicInfo(currentUserId));
    }
  }, [currentUserId, currentBasicInfo, dispatch, location.pathname, publicRoutes]);
  
  const noHeaderFooterRoutes = ['/login', '/register', '/ai-landing', '/schedule-meeting','/opt-out'];

  if (currentBasicInfo === null && !publicRoutes.includes(location.pathname)) {
    console.log('Showing loading spinner for route:', location.pathname);
    return (
      <div className="App">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      {!noHeaderFooterRoutes.includes(location.pathname) && <Header />}

      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/agents" element={<Agents/>} />
        <Route path="/conversations" element={<Conversations/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="/meetings" element={<Meeting/>} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/customize-ai-personality" element={<CustomizeAIPersonality />} />
        <Route path="/audience-filters" element={<AudienceFilters />} />
        <Route path="/connect-calendly" element={<ConnectCalendly />} />
        <Route path="/review-samples" element={<ReviewSamples />} />
        <Route path="/email-display" element={<EmailDisplay />} />
        <Route path="/ai-landing" element={<AILanding />} />
        <Route path="/confirm-deploy-agents" element={<ConfirmDeployAgents />} />
        <Route path="/schedule-meeting" element={<ScheduleMeeting />} />
        <Route path="/workflow-and-role-setup" element={<WorkflowAndRoleSetup />} />
        <Route path="/prospects" element={<Prospects />} />
        <Route path="/visitors" element={<Visitors />} />
        <Route path="/opt-out" element={<OptOut />} />
        <Route path="/chats" element={<AIChat />} />
        <Route path="/chats/:id" element={<AIChatDetail />} />
        <Route path="/knowledge-bases" element={<KnowledgeBases />} />
        <Route path="/knowledge-base/:id" element={<KnowledgeBaseDetail />} />
        <Route path="/webchat-inbox" element={<WebchatInbox />} /> {/* Add this line */}
        <Route path="/contacts" element={<Contacts />} /> {/* Add this line */}
      </Routes>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <MainApp />
    </BrowserRouter>
  );
}

export default App;
