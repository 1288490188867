import React from 'react'
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Flex } from 'antd';

const Login = () => {
    const onFinish = (values) => {
      };
  return (
    // <div>
    //     <div class="container d-flex justify-content-center align-items-center vh-100">
    //     <div class="card p-4">
    //         <div class="text-center">
    //             <img src="logo.png" alt="Logo" class="mb-4"/>
    //         </div>
    //         <h5 class="text-center mb-4">Sign In</h5>
    //         <form>
    //             <div class="form-group">
    //                 <label for="username">User Name</label>
    //                 <input type="text" class="form-control" id="username" placeholder="user name"/>
    //             </div>
    //             <div class="form-group">
    //                 <label for="password">Password</label>
    //                 <input type="password" class="form-control" id="password" placeholder="password"/>
    //                 <small class="form-text text-right"><a href="#" class="text-danger">Forget password?</a></small>
    //             </div>
    //             <div class="form-group form-check">
    //                 <input type="checkbox" class="form-check-input" id="rememberPassword"/>
    //                 <label class="form-check-label" for="rememberPassword">Remember password?</label>
    //             </div>
    //             <button type="submit" class="btn btn-primary btn-block">Sign In</button>
    //             <p class="text-center mt-3">Don't have an account? <a href="#">Sign Up</a></p>
    //             <div class="text-center mt-3">
    //                 <span>OR</span>
    //             </div>
    //             <div class="d-flex justify-content-center mt-3">
    //                 <button class="btn btn-outline-primary mx-2"><i class="fab fa-facebook-f"></i></button>
    //                 <button class="btn btn-outline-primary mx-2"><i class="fab fa-google"></i></button>
    //                 <button class="btn btn-outline-primary mx-2"><i class="fab fa-twitter"></i></button>
    //             </div>
    //         </form>
    //     </div>
    // </div>
    // </div>
    <div className='container-fluid'>
        <div class="row justify-content-center align-items-center authentication authentication-basic ">
            <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                <div class="my-4 d-flex justify-content-center">
                <a href="index.html" className='authlogo'>
                    Gomeet AI
                </a>
                </div>
                <div class="card custom-card">
                <div class="card-body p-5 login-auth-gomeet">
                    <h4 className='text-center fw-700'>Sign In</h4>
                    <p className='text-center'>Welcome back Jhon !</p>
                     <Form
                     size='large'
                    name="login"
                    initialValues={{
                        remember: true,
                        
                    }}
                    layout="vertical" 
                    onFinish={onFinish}
                    >
                    <Form.Item
                        name="username"
                         label="User Name"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                        ]}
                        className='mb-2'
                    >
                        <Input prefix={<UserOutlined />}  autoComplete="off" placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                        className="mb-2"
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            autoComplete="off"
                            placeholder="Password"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}  // Optional: Custom icons
                        />
                    </Form.Item>
                    <Form.Item  className='mb-2'>
                        <Flex justify="space-between" align="center">
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <a href="">Forgot password</a>
                        </Flex>
                    </Form.Item>

                    <Form.Item>
                        <Button block type="primary" htmlType="submit" style={{backgroundColor:"#3a5892"}}>
                        Log in
                        </Button>
                       
                    </Form.Item>
                    <p>Dont have an account?  <a href="">Register now!</a></p>
                    </Form>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login