import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message, Row, Col, Spin } from 'antd';
import axios from 'axios';
import { HiOutlineExclamationCircle } from "react-icons/hi";
const { Option } = Select;

const ConnectToDatabaseTab = ({ document_id }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dbName, setDbName] = useState("")
  const [fetchLoading, setFetchLoading] = useState(false);

  useEffect(() => {
    const fetchDbConfig = async () => {
      setFetchLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/aichat/get_db_config`,
             { parent_id: document_id }
          
        );
        
        // Map the API response to form fields
        form.setFieldsValue({
          connection_string: response.data.connection_string,
          database: response.data.database_name,
          dbType: response.data.db_type
        });
      } catch (error) {
        message.error('Failed to fetch database configuration');
      } finally {
        setFetchLoading(false);
      }
    };

    if (document_id) {
      fetchDbConfig();
    }
  }, [document_id, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const payload = {
        parent_id: document_id,
        database_name: values.database,
        connection_string: values.connection_string,  
        db_type:values.dbType
      };
      console.log("payload--->",payload);
      

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/aichat/create_db_config`,
        payload
      );
      console.log('Database connection values:', values);
      message.destroy()
      message.success('Database connection saved successfully');

    } catch (error) {
      message.destroy()
      message.error('Failed to connect/save database connection');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Spin spinning={fetchLoading}>
      <div style={{margin: '0 auto', padding: '24px',marginTop:'10px'}}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="dbType"
                label="Database Type"
                rules={[{ required: true, message: 'Please select database type' }]}
              >
                <Select onChange={(value) => {setDbName(value)}} placeholder="Select database type">
                
                  <Option value="mysql">MySQL</Option>
                  <Option value="mongodb">Mongo DB</Option>
                  <Option disabled value="postgresql">PostgreSQL</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
            <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Select your database type from the supported options</small>
            </Col>

            <Col span={12}>
            <Form.Item
                name="connection_string"
                label="Connection String"
                rules={[{ required: true, message: 'Please input the string' },{ whitespace: true, message: 'host cannot be empty spaces' }]}
              >
                {
                  dbName=='mysql' && (
                    <Input placeholder="e.g., mysql+pymysql://username:password@host:3306/database_name" />
                  )
                  }
                  {
                    dbName=='mongodb' && (
                      <Input placeholder="e.g., mongodb://username:password@host" />
                    )
                  }
                  {
                    dbName=='postgresql' && (
                      <Input placeholder="e.g., postgresql://username:password@host:5432/database_name" />
                    )
                  }
                  {
                    dbName.length==0 && (
                      <Input placeholder="Enter Connection String"/>
                    )
                  }
                
                
              </Form.Item>
            </Col>
            <Col span={12}>
            <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Your connection string will be encrypted before storage for security</small>
            </Col>
            {
            (dbName=='mongodb' || form.getFieldValue('dbType')=='mongodb') &&
            <>
            <Col span={12}>
              <Form.Item
                name="database"
                label="Database Name"
                rules={[{ required: true, message: 'Please input database name' },{ whitespace: true, message: 'database name cannot be empty spaces' }]}
              >
                <Input placeholder="Enter database name" />
              </Form.Item>
            </Col>
            <Col span={12}>
            <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span> Name of the database you want to connect to</small>
            </Col>
            </>
            }

            {/* <Col span={12}>
              <Form.Item
                name="host"
                label="Host"
                rules={[{ required: true, message: 'Please input host' },{ whitespace: true, message: 'host cannot be empty spaces' }]}
              >
                <Input placeholder="e.g., localhost or database.example.com" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="port"
                label="Port"
                rules={[{ required: true, message: 'Please input port' },{ whitespace: true, message: 'port cannot be empty spaces' }]}
              >
                <Input placeholder="e.g., 3306" />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="username"
                label="Username"
                rules={[{ required: true, message: 'Please input username' },{ whitespace: true, message: 'username cannot be empty spaces' }]}
              >
                <Input placeholder="Enter username" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input password' },{ whitespace: true, message: 'password cannot be empty spaces' }]}
              >
                <Input.Password placeholder="Enter password" />
              </Form.Item>
            </Col> */}
          </Row>
          
          <div style={{width:'200px',margin:'0 auto'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Connect and Save Database
            </Button>
          </Form.Item>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default ConnectToDatabaseTab;
