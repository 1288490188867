import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Table, Space, Button, message, Typography, Popconfirm, Spin } from 'antd';
import { DeleteOutlined, FileTextOutlined, FileOutlined, FilePdfOutlined, FileWordOutlined, LoadingOutlined } from '@ant-design/icons';
import { formatDistanceToNow, parseISO } from 'date-fns';
import styled from 'styled-components';

const { Text } = Typography;

const TableWrapper = styled.div`
  .ant-table-thead > tr > th {
    background: #f8f9fa;
    font-weight: 600;
  }
  
  .ant-table-tbody > tr > td {
    padding: 12px 16px;
  }

  .file-name {
    color: #1890ff;
    &:hover {
      text-decoration: underline;
    }
  }

  .delete-button {
    &:hover {
      background-color: #fff1f0;
    }

    &.deleting {
      pointer-events: none;
      opacity: 0.7;
    }
  }
`;

const IconWrapper = styled.span`
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
`;

const LoadingIcon = styled(LoadingOutlined)`
  font-size: 16px;
  color: #ff4d4f;
`;

const EmptyStateWrapper = styled.div`
  text-align: center;
  padding: 40px 0;
  
  .ant-empty-image {
    margin-bottom: 16px;
  }
  
  .empty-text {
    color: #8c8c8c;
  }
`;

const TrainingFilesList = ({ userId }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deletingIds, setDeletingIds] = useState(new Set());

  const getFileIcon = useCallback((filename) => {
    if (!filename) return <FileOutlined />;
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <FilePdfOutlined style={{ color: '#ff4d4f' }} />;
      case 'doc':
      case 'docx':
        return <FileWordOutlined style={{ color: '#1890ff' }} />;
      case 'txt':
        return <FileOutlined style={{ color: '#52c41a' }} />;
      default:
        return <FileOutlined />;
    }
  }, []);

  const fetchFiles = useCallback(async () => {
    if (!userId) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/agent_setup/training_files/${userId}`);
      
      // Handle both array response and object with files property
      const filesData = Array.isArray(response.data) ? response.data : 
                       Array.isArray(response.data?.files) ? response.data.files : [];
      
      const transformedFiles = filesData.map(file => ({
        ...file,
        key: file.id || Math.random().toString(36).substr(2, 9),
        type: file.type || 'file',
        filename: file.filename || 'Unnamed File',
        uploadDate: file.uploadDate || new Date().toISOString(),
      }));
      
      setFiles(transformedFiles);
    } catch (error) {
      console.error('Error fetching training files:', error);
      setError('Failed to fetch training files');
      message.error('Failed to fetch training files');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const handleDelete = async (fileId, filename) => {
    setDeletingIds(prev => new Set(prev).add(fileId));
    
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/agent_setup/training_file/${fileId}`);
      message.success(`${filename} deleted successfully`);
      fetchFiles(); // Refresh the list
    } catch (error) {
      console.error('Error deleting file:', error);
      message.error(`Failed to delete ${filename}`);
    } finally {
      setDeletingIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(fileId);
        return newSet;
      });
    }
  };

  const formatUploadDate = (dateString) => {
    try {
      if (!dateString) return 'Invalid date';
      
      // Convert the date string to Date object
      const date = new Date(dateString);
      
      // Check if it's a valid date
      if (isNaN(date.getTime())) {
        return 'Invalid date';
      }

      // Format the distance
      return formatDistanceToNow(date, { 
        addSuffix: true,
        includeSeconds: true
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const columns = [
    {
      title: 'Type',
      key: 'type',
      width: '15%',
      render: (_, record) => (
        <Space>
          <IconWrapper>
            {getFileIcon(record.filename)}
          </IconWrapper>
          {record.type === 'url' ? 'URL' : 'File'}
        </Space>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'filename',
      key: 'filename',
      width: '40%',
      render: (text) => (
        <Text ellipsis={{ tooltip: text }} className="file-name">
          {text}
        </Text>
      ),
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      width: '30%',
      render: (date) => formatUploadDate(date),
      sorter: (a, b) => new Date(a.uploadDate) - new Date(b.uploadDate),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '15%',
      render: (_, record) => {
        const isDeleting = deletingIds.has(record.id);
        
        return (
          <Popconfirm
            title="Delete file"
            description={`Are you sure you want to delete ${record.filename}?`}
            onConfirm={() => handleDelete(record.id, record.filename)}
            okText="Yes"
            cancelText="No"
            disabled={isDeleting}
            destroyTooltipOnHide={true}
          >
            <Button
              type="text"
              danger
              icon={isDeleting ? <LoadingIcon /> : <DeleteOutlined />}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting' : 'Delete'}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  if (error) {
    return (
      <EmptyStateWrapper>
        <Text type="danger">{error}</Text>
        <Button type="primary" onClick={fetchFiles} className="mt-3">
          Retry
        </Button>
      </EmptyStateWrapper>
    );
  }

  return (
    <TableWrapper>
      <h5 className="mb-3">Training Files</h5>
      <Table
        columns={columns}
        dataSource={files}
        rowKey="id"
        loading={{
          spinning: loading,
          indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        }}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 5,
          showTotal: (total) => `Total ${total} files`,
        }}
        className="custom-table"
        locale={{ 
          emptyText: (
            <EmptyStateWrapper>
              <FileOutlined style={{ fontSize: '24px', marginBottom: '8px', color: '#8c8c8c' }} />
              <div className="empty-text">No training files uploaded yet</div>
            </EmptyStateWrapper>
          )
        }}
        onChange={(pagination, filters, sorter) => {
          console.log('Table params:', pagination, filters, sorter);
        }}
        scroll={{ x: 'max-content' }}
      />
    </TableWrapper>
  );
};

export default TrainingFilesList;