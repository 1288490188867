import React, { useState, useEffect } from 'react';
import { Input, Button, Typography, Space, Row, Col, message, Popconfirm, Spin,Form} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const FAQsTab = ({ parentId }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingFaq, setEditingFaq] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingFaq, setIsAddingFaq] = useState(false);
  const [isUpdatingFaq, setIsUpdatingFaq] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  useEffect(() => {
    fetchFAQs();
  }, [parentId]);

  const fetchFAQs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/aichat/ai_faqs?parent_id=${parentId}`);
      setFaqs(response.data);
    } catch (error) {
      message.error('Failed to fetch FAQs');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddFAQ = async () => {
    // if (question && answer) {
      try {
        const values = await form.validateFields();
        setIsAddingFaq(true);
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
          acc[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
          return acc;
        }, {});

        const FaqData = {
          ...trimmedValues,
          parent_id: parentId
        }; 
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/ai_faqs/create`, {
          ...FaqData
        });
        if (response.status===201){

          setFaqs([response.data,...faqs]);
          form.resetFields();
          message.success('FAQ added successfully');
        } else {
          message.error('Failed to save faqs');
        }
      } catch (error) {
        if (error.response){
          message.error(error.response.data.message || 'Failed to save Faqs');
        }
        else if (!error.isAxiosError) {
          console.error('Validation failed:', error);
          return;
        }
        else {

          message.error('Failed to add FAQ');
        }

      } finally {
        setIsAddingFaq(false);
      }
    // }
  };

  const handleUpdateFAQ = async (id) => {
    setIsUpdatingFaq(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/aichat/ai_faqs/${id}/update/`, {
        question: editingFaq.question,
        answer: editingFaq.answer
      });
      setFaqs(faqs.map(faq => faq._id === id ? response.data : faq));
      setEditingFaq(null);
      message.success('FAQ updated successfully');
    } catch (error) {
      message.error('Failed to update FAQ');
    } finally {
      setIsUpdatingFaq(false);
    }
  };

  const handleDeleteFAQ = async (id) => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/aichat/ai_faqs/${id}/delete/`);
      setFaqs(faqs.filter(faq => faq._id !== id));
      message.success('FAQ deleted successfully');
    } catch (error) {
      message.error('Failed to delete FAQ');
    }
  };

  const filteredFAQs = faqs.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm?.trim().toLowerCase())
  );

  return (
    <Row gutter={24}>
      <Col lg={7} md={12} sm={12}  xs={12}>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Paragraph>
            Create a comprehensive FAQs page by organizing a list of questions and answers. 
            Empower your AI chat with relevant information to efficiently address user queries 
            and provide a seamless and informative user experience.
          </Paragraph>

          <Form form={form} layout="vertical">         
          <div>
            {/* <Title level={5}>Question</Title> */}
            {/* <Input
              placeholder="Enter your question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            /> */}
            <Form.Item
              name="question"
              label="Question"
              rules={[
                { required: true, message: 'This field is required'},
                { whitespace: true, message: 'This field cannot have empty spaces'}
              ]}
            >
              <Input placeholder="Enter your question" />
              </Form.Item>
          </div>  
          <div>
            {/* <Title level={5}>Answer</Title> */}
            {/* <TextArea
              placeholder="Enter your answer (max 600 characters)"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              maxLength={600}
              rows={4}
            /> */}
            <Form.Item name="answer" label="Answer"
              rules={[
                { required: true, message: 'This field is required'},
                { whitespace: true, message: 'This field cannot have empty spaces' }
              ]}>
                <TextArea
              placeholder="Enter your answer (max 600 characters)"
              value={answer}
              maxLength={600}
              rows={4}
            />

          </Form.Item>
          </div>
          <Form.Item>

          <Button type="primary" onClick={handleAddFAQ} loading={isAddingFaq}>
            Add FAQ to knowledge base
          </Button>
          </Form.Item>
          </Form>
        </Space>
      </Col>
      <Col lg={17} md={12}  sm={12} xs={12}>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Input
            placeholder="Search FAQs"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div style={{ height: '450px', overflowY: 'auto', padding: '0 16px 0 0' }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {filteredFAQs.map((faq) => (
                  <div key={faq._id} style={{ border: '1px solid #f0f0f0', borderRadius: '4px', padding: '16px', marginBottom: '16px' }}>
                    {editingFaq && editingFaq._id === faq._id ? (
                      <>
                        <Input
                          value={editingFaq.question}
                          onChange={(e) => setEditingFaq({ ...editingFaq, question: e.target.value })}
                          style={{ marginBottom: '8px' }}
                        />
                        <TextArea
                          value={editingFaq.answer}
                          onChange={(e) => setEditingFaq({ ...editingFaq, answer: e.target.value })}
                          rows={4}
                          style={{ marginBottom: '8px' }}
                        />
                        <Button onClick={() => handleUpdateFAQ(faq._id)} style={{ marginRight: '8px' }} disabled={isUpdatingFaq} loading={isUpdatingFaq}>Save</Button>
                        <Button onClick={() => setEditingFaq(null)}>Cancel</Button>
                      </>
                    ) : (
                      <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                          <Title level={5} style={{ margin: 0 }}>{faq.question}</Title>
                          <Space>
                            <Button icon={<EditOutlined />} onClick={() => setEditingFaq(faq)} />
                            <Popconfirm
                              title="Are you sure you want to delete this FAQ?"
                              onConfirm={() => handleDeleteFAQ(faq._id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button icon={<DeleteOutlined />} danger />
                            </Popconfirm>
                          </Space>
                        </div>
                        <Paragraph style={{ margin: '0 0 8px 0' }}>{faq.answer}</Paragraph>
                        <Paragraph type="secondary" style={{ margin: 0, fontSize: '12px' }}>Updated on {new Date(faq.updated_at).toLocaleString()}</Paragraph>
                      </>
                    )}
                  </div>
                ))}
              </Space>
            </div>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default FAQsTab;
