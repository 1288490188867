import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { InlineWidget } from "react-calendly";
import axios from 'axios';
import Cookies from 'js-cookie';

function ScheduleMeeting() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    
    const firstName = queryParams.get('firstName') || '';
    const email = queryParams.get('email') || '';
    const documentId = queryParams.get('documentId') || '';
    const pitchingCompanyUserId = queryParams.get('pitchingCompanyUserId') || '';
    const selectedDate = queryParams.get('selectedDate') || '';
    const selectedTime = queryParams.get('selectedTime') || '';

    const [calendlyUrl, setCalendlyUrl] = useState('');

    useEffect(() => {
        const fetchCalendlyUrl = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/integration/get_calendly_url`, {
                    user_id: pitchingCompanyUserId
                });
                if (response.data && response.data.scheduling_url) {
                    setCalendlyUrl(response.data.scheduling_url);
                }
            } catch (error) {
                console.error('Error fetching Calendly URL:', error);
            }
        };

        fetchCalendlyUrl();
    }, [pitchingCompanyUserId]);

    useEffect(() => {
        const handleCalendlyEvent = async (e) => {
            if (e.data.event && e.data.event === "calendly.event_scheduled") {
        
                const eventDetails = e.data.payload;
        
                const eventUri = eventDetails.event.uri;
                const inviteeUri = eventDetails.invitee.uri;
        
                try {
                    // Fetch event details
                    const eventResponse = await axios.get(eventUri, {
                        headers: {
                            'Authorization': `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY}`,
                            'Content-Type': 'application/json'
                        }
                    });
        
                    const eventData = eventResponse.data;
                    const startTime = eventData.resource.start_time;
                    const endTime = eventData.resource.end_time;

                    // Fetch invitee details to get the email
                const inviteeResponse = await axios.get(inviteeUri, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });
  
                const inviteeData = inviteeResponse.data;
                const inviteeEmail = inviteeData.resource.email;
                const inviteeName = inviteeData.resource.name;

                    await axios.post(process.env.REACT_APP_API_URL + '/meetings/schedule_meeting', {
                        eventUri: eventUri,
                        inviteeUri: inviteeUri,
                        startTime: startTime,
                        endTime: endTime,
                        documentId: documentId,
                        email: inviteeEmail,
                        name:inviteeName,
                        user_id: pitchingCompanyUserId
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                } catch (error) {
                    console.error('Error processing Calendly event:', error);
                }
            }
        };
    
        window.addEventListener('message', handleCalendlyEvent);
    
        return () => {
            window.removeEventListener('message', handleCalendlyEvent);
        };
    }, [documentId, email, pitchingCompanyUserId]);
    
    // Parse the selectedDate to create a Date object
    const parsedDate = selectedDate ? new Date(selectedDate) : null;

    // Format the date for Calendly (YYYY-MM-DD)
    const formattedDate = parsedDate ? parsedDate.toISOString().split('T')[0] : null;

    // Extract the time from selectedTime (assuming format like "Wed, Sep 13 at 02:00 PM PDT")
    const timeMatch = selectedTime ? selectedTime.match(/(\d{1,2}:\d{2} [AP]M)/) : null;
    const formattedTime = timeMatch ? timeMatch[1] : null;

    // Convert time to 24-hour format for Calendly
    const convertTo24Hour = (time12h) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    };

    const time24 = formattedTime ? convertTo24Hour(formattedTime) : null;

    // Construct the Calendly URL with date and time parameters
    const finalCalendlyUrl = calendlyUrl
        ? `${calendlyUrl}${formattedDate ? `?date=${formattedDate}` : ''}${time24 ? `&time=${time24}` : ''}`
        : '';

    return (
        <Container>
            <Row>
                <Col>
                    {finalCalendlyUrl ? (
                        <InlineWidget
                            url={finalCalendlyUrl}
                            styles={{
                                height: '1000px'
                            }}
                            prefill={{
                                name: firstName,
                                email: email
                            }}
                        />
                    ) : (
                        <p>Loading Calendly...</p>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default ScheduleMeeting;