import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { Card, Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import icon1 from '../assets/images/ai-agent.png'
import img1 from '../assets/images/img01.png'
import img2 from '../assets/images/img02.png'
import img3 from '../assets/images/img03.png'
import img4 from '../assets/images/img04.png'
import img5 from '../assets/images/img05.png'
import img6 from '../assets/images/img06.png'

import { useSelector } from 'react-redux';

function Dashboard() {
  const { userId, basicInfo } = useSelector(state => state.app);
  const userInfo = useSelector(state => state.app.userInfo);
  const navigate = useNavigate();

  useEffect(() => {
    const trackMenuClick = async () => {
      try {
        const accessToken = Cookies.get('accessToken');
        if (!accessToken) {
          console.error('Access token not found in cookies');
          return;
        }

        await axios.post('https://globalapi.dbi360.com/api/v1/menu/track-click/', 
          { menu_id: 25 },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
      } catch (error) {
        console.error('Error tracking menu click:', error);
      }
    };

    trackMenuClick();
  }, []);

  console.log('basicInfo', basicInfo)

  const isPreviousStepsCompleted = (currentStep, basicInfo) => {
    const steps = [
      'company_name',
      'assistant_name',
      'department',
      'audience_filters',
      'review_samples',
      'deploy_agents'
    ];
    
    const currentIndex = steps.indexOf(currentStep);
    if (currentIndex === 0) return true;
    
    for (let i = 0; i < currentIndex; i++) {
      if (!basicInfo?.[steps[i]]) return false;
    }
    return true;
  };

  const getButtonStyle = (condition, fieldName) => {
    const isCompleted = condition;
    const isPreviousCompleted = isPreviousStepsCompleted(fieldName, basicInfo);
    
    if (isCompleted) {
      return {
        variant: "success",
        className: 'rounded-pill text-uppercase',
        children: "COMPLETED"
      };
    }
    
    return {
      variant: !isPreviousCompleted ? "secondary" : "primary",
      className: 'rounded-pill text-uppercase',
      children: "SETUP",
      disabled: !isPreviousCompleted,
      'aria-disabled': !isPreviousCompleted,
      style: { 
        cursor: !isPreviousCompleted ? 'not-allowed' : 'pointer'
      }
    };
  };

  
  return (
    <Container>
      <div className="text-center pt-5 mb-3">
        <h2 className='fw-bold mb-2'>Welcome, {userInfo && userInfo.firstname + ' ' + userInfo.lastname}!</h2>
        <p className='mb-4'>We are so happy you are here. Continue setting up your new agent!</p>
      </div>
      <Row className="g-3 justify-content-md-center mb-4 dashboard">
        <Col md={4} xl={3}>
          <Card className="h-100">
            <Card.Body className='d-flex flex-column text-center p-0'>
              <Card.Img variant="top" src={img1} style={{ width: '150px'}} className='mb-3 mx-auto' />
              <Card.Title className="fw-bold fs-6">Set Up Company</Card.Title>
              <Card.Text className="small">
                Set up an agent with your Marketing Proposal/Pitch
              </Card.Text>
              <div className="mt-auto">
                <Link to="/onboarding">
                  <Button {...getButtonStyle(basicInfo?.company_name, 'company_name')}  />
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} xl={3}>
          <Card className="h-100">
            <Card.Body className='d-flex flex-column text-center p-0'>
              <Card.Img variant="top" src={img2} style={{ width: '150px'}} className='mb-3 mx-auto' />
              <Card.Title className="fw-bold fs-6">Customize AI Personality</Card.Title>
              <Card.Text className="small">
                Tailor your AI agent's personality
              </Card.Text>
              <div className="mt-auto">
                <Link to="/customize-ai-personality">
                  <Button {...getButtonStyle(basicInfo?.assistant_name, 'assistant_name')}  />
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} xl={3}>
          <Card className="h-100">
            <Card.Body className='d-flex flex-column text-center p-0'>
              <Card.Img variant="top" src={img3} style={{ width: '150px'}} className='mb-3 mx-auto' />
              <Card.Title className="fw-bold fs-6">Workflow and Role Setup</Card.Title>
              <Card.Text className="small">
                Configure workflow and roles
              </Card.Text>
              <div className="mt-auto">
                <Link to="/workflow-and-role-setup">
                  <Button {...getButtonStyle(basicInfo?.department, 'department')}  />
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 justify-content-md-center dashboard mb-4">
        <Col md={4} xl={3}>
          <Card className="h-100">
            <Card.Body className='d-flex flex-column text-center p-0'>
              <Card.Img variant="top" src={img4} style={{ width: '150px'}} className='mb-3 mx-auto' />
              <Card.Title className="fw-bold fs-6">Data Integration and Training</Card.Title>
              <Card.Text className="small">
                Integrate data and train AI agents
              </Card.Text>
              <div className="mt-auto">
                <Link to="/audience-filters">
                  <Button {...getButtonStyle(basicInfo?.audience_filters, 'audience_filters')}  />
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} xl={3}>
          <Card className="h-100">
            <Card.Body className='d-flex flex-column text-center p-0'>
              <Card.Img variant="top" src={img5} style={{ width: '150px'}} className='mb-3 mx-auto' />
              <Card.Title className="fw-bold fs-6">Review samples</Card.Title>
              <Card.Text className="small">
                Review and approve AI-generated samples
              </Card.Text>
              <div className="mt-auto">
              <Link to="/review-samples">
              <Button {...getButtonStyle(basicInfo?.review_samples, 'review_samples')}  />
              </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} xl={3}>
          <Card className="h-100">
            <Card.Body className='d-flex flex-column text-center p-0'>
              <Card.Img variant="top" src={img6} style={{ width: '150px'}} className='mb-3 mx-auto' />
              <Card.Title className="fw-bold fs-6">Deploy Agents</Card.Title>
              <Card.Text className="small">
                Finalize and deploy AI agents
              </Card.Text>
              <div className="mt-auto">
              <Link to={basicInfo?.deploy_agents ? "/confirm-deploy-agents" : "/pricing"}>
                <Button {...getButtonStyle(basicInfo?.deploy_agents, 'deploy_agents')}  />
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
