import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/logo.png'
// import Button from 'react-bootstrap/Button';
import DropdownUser from "./DropdownUser";
import HeaderMenu from "./HeaderMenu";
import { Button } from "antd";
import { FiArrowUpCircle } from "react-icons/fi";

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="sticky top-0 z-50 flex w-full items-center justify-between bg-white drop-shadow dark:bg-boxdark dark:drop-shadow-none py-2 px-4">
      <div className="lg:w-11/12 flex items-center">
        {/* <button className="block lg:hidden text-xl p-2">
          <FiAlignLeft className="text-white" />
        </button> */}

        <Link className="hidden lg:block flex-shrink-0" to="/">
          <img src={Logo} alt="Logo" className="inline-block w-10" />
        </Link>
        
        <HeaderMenu />
      </div>
      
      <div className="flex items-center gap-3">
      <Button 
          type="primary" color='#f3f3f3'
          onClick={() => navigate('/pricing')}
        >
          <FiArrowUpCircle />
          Upgrade
        </Button>
        <DropdownUser />
      </div>
      <Link className="w-full block lg:hidden flex-shrink-0 absolute inset-x-0 text-center" to="/" style={{zIndex: '-1', left: '0'}}>
          <img src={Logo} alt="Logo" className="inline-block w-10" />
      </Link>
    </header>
  );
};

export default Header;
