import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Input, List, message, Table } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
const { Search } = Input;

const ConnectToAIChatsModal = ({ visible, onCancel, onConnect, parentId }) => {
  const [selectedChats, setSelectedChats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatBots, setChatBots] = useState([]);
  const [connecting, setConnecting] = useState(false);
  const { userId } = useSelector(state => state.app);

  const fetchChatBots = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/list_chatbots_to_connect/`, {user_id: userId,"knowledge_base_id":parentId});
      setChatBots(response.data);
    } catch (error) {
      console.error('Error fetching chat bots:', error);
      message.error('Failed to fetch chat bots');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible==true){

      fetchChatBots();
    }
  }, [visible]);

  const filteredChats = chatBots.filter(chat =>
    chat.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
  );

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedChats(filteredChats.map(chat => chat.id));
    } else {
      setSelectedChats([]);
    }
  };

  const handleSelectChat = (chatId) => {
    setSelectedChats(prev =>
      prev.includes(chatId)
        ? prev.filter(id => id !== chatId)
        : [...prev, chatId]
    );
  };

  console.log("selectedChats", selectedChats);

  const handleConnect = async () => {
    setConnecting(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/store_ai_chats/`, {
        parent_id: parentId,
        ai_chat_ids: selectedChats
      });
      message.success('Successfully connected AI Chats to Knowledge Base');
      onConnect(response.data); // Pass response.data to the parent component
      setSelectedChats([]); // Clear selected chats
      setSearchTerm(""); // Clear search term
      onCancel(); // Close the modal after successful connection
    } catch (error) {
      console.error('Error connecting AI Chats:', error);
      message.error('Failed to connect AI Chats to Knowledge Base');
    } finally {
      setConnecting(false);
    }
  };

  const handleCancel = () => {
    setSelectedChats([]);
    setSearchTerm(""); // Clear search term
    onCancel(); // Call the original onCancel function
  };

  return (
    <Modal
      title="Connect Knowledge Base to AI Chats"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleConnect}
      okText="Connect"
      confirmLoading={connecting}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Checkbox
          onChange={handleSelectAll}
          checked={filteredChats.length > 0 && selectedChats.length === filteredChats.length}
          disabled={filteredChats.length === 0}
        >
          Select All
        </Checkbox>
        <Input
          placeholder="Search AI Chats"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 200 }}
        />
      </div>
      <div style={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid #f0f0f0', borderRadius: '4px' }}>
        <List
          loading={loading}
          dataSource={filteredChats}
          className='p-2'
          renderItem={chat => (
            <List.Item>
              <Checkbox
                checked={selectedChats.includes(chat.id)}
                onChange={() => handleSelectChat(chat.id)}
              >
                {chat.name}
              </Checkbox>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default ConnectToAIChatsModal;