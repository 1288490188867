import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Input, List, Avatar, Typography, Card, Button, message, Spin } from 'antd';
import {
  SearchOutlined,
  SendOutlined,
  MessageOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  FolderOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import './WebchatInbox.css';

const { Text } = Typography;

const WebchatInbox = () => {
  const { userId } = useSelector(state => state.app);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState(false);
  const [chatPage, setChatPage] = useState(1);
  const [messagePage, setMessagePage] = useState(1);
  const [hasMoreChats, setHasMoreChats] = useState(true);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);

  useEffect(() => {
    fetchChatResponses();
  }, []);

  const trimMessage = (message, maxLength = 40) => {
    if (message.length <= maxLength) return message;
    return message.substring(0, maxLength) + '...';
  };

  const fetchChatResponses = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/get_aichat_response_list/`, {
        user_id: userId,
        page: chatPage,
        page_size: 5
      });
      const newChats = response.data.results.map(chat => ({
        id: chat.id,
        name: chat.user || `Chat ${chat.id}`,
        chatbotName: chat.parent_chatbot_name,
        chatBotId: chat.parent_chatbot_id,
        lastMessage: trimMessage(chat?.latest_message || 'No messages'),
        timestamp: new Date(chat?.updated_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        messages: [],
        is_read: chat.is_read
      }));
      setChats(prevChats => [...prevChats, ...newChats]);
      setChatPage(prevPage => prevPage + 1);
      setHasMoreChats(response.data.page < response.data.total_pages);
    } catch (error) {
      console.error('Error fetching chat responses:', error);
      message.error('Failed to load chat responses');
    } finally {
      setLoading(false);
    }
  };

  const fetchChatMessages = async (chatId, page = 1) => {
    try {
      setMessageLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/aichat/get_aichat_messages/${chatId}`, {
        params: { page: page, page_size: 5 }
      });
      const newMessages = response.data.messages.map(msg => ({
        id: msg.id,
        text: msg.message,
        sender: msg.sender,
        timestamp: new Date(msg.updated_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }));
      setChats(prevChats => prevChats.map(chat => {
        if (chat.id === chatId) {
          return {
            ...chat,
            messages: page === 1 ? newMessages : [...chat.messages, ...newMessages],
            is_read: true
          };
        }
        return chat;
      }));
      setSelectedChat(prevChat => ({
        ...prevChat,
        messages: page === 1 ? newMessages : [...prevChat.messages, ...newMessages],
      }));
      setMessagePage(page + 1);
      setHasMoreMessages(response.data.page < response.data.total_pages);
    } catch (error) {
      console.error('Error fetching chat messages:', error);
      message.error('Failed to load chat messages');
    } finally {
      setMessageLoading(false);
    }
  };

  const handleChatSelect = (chatId) => {
    const selected = chats.find(chat => chat.id === chatId);
    setSelectedChat({...selected, messages: []});
    setMessagePage(1);
    setHasMoreMessages(true);
    fetchChatMessages(chatId, 1);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredChats = chats.filter(chat =>
    chat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    chat.lastMessage.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSendMessage = async () => {
    if (newMessage.trim() && selectedChat) {
      try {
        const response = await axios.post('/send_chat_message', {
          chat_id: selectedChat.id,
          message: newMessage,
          sender: 'user'
        });

        const updatedChats = chats.map(chat => {
          if (chat.id === selectedChat.id) {
            return {
              ...chat,
              lastMessage: trimMessage(newMessage),
              timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
              messages: [
                {
                  id: response.data._id,
                  text: newMessage,
                  sender: 'user',
                  timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                },
                ...chat.messages
              ]
            };
          }
          return chat;
        });
        setChats(updatedChats);
        setSelectedChat(updatedChats.find(chat => chat.id === selectedChat.id));
        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
        message.error('Failed to send message');
      }
    }
  };

  return (
    <div className="webchat-layout">
      <div className="sidebar">
        <div className="sidebar-icon"><MessageOutlined /></div>
        <div className="sidebar-icon"><UserOutlined /></div>
        <div className="sidebar-icon"><FolderOutlined /></div>
        <div className="sidebar-icon" style={{ marginTop: 'auto' }}><SettingOutlined /></div>
        <div className="sidebar-icon"><LogoutOutlined /></div>
      </div>

      <div className="main-content">
        <div className="chat-list-container">
          <div className="search-container">
            <Input
              className="search-input"
              prefix={<SearchOutlined style={{ color: '#6366f1' }} />}
              placeholder="Search chats"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <div className="chat-list">
            <InfiniteScroll
              dataLength={filteredChats.length}
              next={fetchChatResponses}
              hasMore={hasMoreChats}
              height="calc(100vh - 280px)"
              loader={filteredChats.length > 4 && (<div style={{ textAlign: 'center', padding: '20px' }}><Spin /></div>)}
              
            >
              <List
                dataSource={filteredChats}
                renderItem={chat => (
                  <List.Item
                    key={chat.id}
                    onClick={() => handleChatSelect(chat.id)}
                    className={`chat-item ${!chat.is_read ? 'unread-chat' : ''} ${selectedChat && selectedChat.id === chat.id ? 'active' : ''}`}
                  >
                    <List.Item.Meta
                      avatar={<Avatar style={{ backgroundColor: '#6366f1' }}>{chat.name[0]}</Avatar>}
                      title={<span className={!chat.is_read ? 'unread-chat-title' : ''}>{chat.name}</span>}
                      description={
                        <>
                          <Text className={!chat.is_read ? 'unread-chat-text' : ''}>{chat.lastMessage}</Text>
                          <br />
                          <Text type="secondary">{chat.chatbotName}</Text>
                        </>
                      }
                    />
                    <Text type="secondary">{chat.timestamp}</Text>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </div>

        <div className="chat-view">
          {selectedChat ? (
            <>
              <div className="chat-header">
                <Avatar style={{ backgroundColor: '#6366f1' }}>{selectedChat.name[0]}</Avatar>
                <div>
                  <Text strong>{selectedChat.name}</Text>
                  <br />
                  <Text type="secondary">{selectedChat.chatbotName}</Text>
                </div>
                <div className="actions">
                  <Button type="text" icon={<PhoneOutlined />} />
                  <Button type="text" icon={<VideoCameraOutlined />} />
                  <Button type="text" icon={<SettingOutlined />} />
                </div>
              </div>

              <div className="messages-container" id="messages">
                <InfiniteScroll
                  dataLength={selectedChat.messages.length}
                  next={() => fetchChatMessages(selectedChat.id, messagePage)}
                  hasMore={hasMoreMessages}
                  loader={<div style={{ textAlign: 'center', padding: '20px' }}><Spin /></div>}
                  height="calc(100vh - 280px)"
                  inverse={true}
                  style={{ display: 'flex', flexDirection: 'column-reverse' }}
                >
                  {selectedChat.messages.map(message => (
                    <div
                      key={message.id}
                      className={`message-bubble ${message.sender === 'bot' ? 'bot' : 'user'}`}
                    >
                      <div>{message.text}</div>
                      <Text type="secondary" style={{ fontSize: '0.8em', marginTop: '5px', display: 'block' }}>
                        {message.timestamp}
                      </Text>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>

              <div className="message-input">
                <Input.Group compact style={{ display: 'flex' }}>
                  <Input
                    disabled
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onPressEnter={handleSendMessage}
                  />
                  <Button
                    disabled
                    type="primary"
                    className='send-button'
                    icon={<SendOutlined />}
                    onClick={handleSendMessage}
                    style={{ backgroundColor: '#6366f1', borderColor: '#6366f1' }}
                  />
                </Input.Group>
              </div>
            </>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Text>Select a chat to view messages</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebchatInbox;
