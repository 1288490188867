import React, { useState, useEffect } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import { Card, Typography, Tabs, Button, message } from 'antd';
import UploadFileTab from '../components/UploadFileTab';
import AddLinkTab from '../components/AddLinkTab';
import FAQsTab from '../components/FAQsTab';
import ConnectionsTab from '../components/ConnectionsTab';
import ConnectToAIChatsModal from '../components/ConnectToAIChatsModal';

const { Title } = Typography;
const { TabPane } = Tabs;

const KnowledgeBaseDetail = () => {
  const { id } = useParams();
  const location=useLocation()
  const locationState=location.state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [updateTrigger, setUpdateTrigger] = useState(0);
 
  

  useEffect(() => {
    // This effect will run whenever the activeTab, isModalVisible, or updateTrigger changes
    console.log('Effect triggered. Active tab:', activeTab, 'Modal visible:', isModalVisible);
    // Add your logic here, e.g., fetching data based on the active tab
  }, [activeTab, isModalVisible, updateTrigger]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleConnect = (selectedChats) => {
    // Here you would typically make an API call to connect the knowledge base to the selected AI chats
    console.log('Connecting knowledge base to:', selectedChats);
    // message.success(`Connected knowledge base to ${selectedChats.length} AI chat(s)`);
    setUpdateTrigger(prev => prev + 1); // Trigger the effect
    setActiveTab('4'); // Switch to the Connections tab
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <Card style={{ margin: '24px', height: '700px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Title level={4} style={{ margin: 0 }}>{locationState?.name}</Title>
        <Button type="primary" onClick={showModal}>Connect To</Button>
      </div>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Upload File" key="1">
          <UploadFileTab parentId={id} />
        </TabPane>
        <TabPane tab="Add a Link" key="2">
          <AddLinkTab parentId={id}/>
        </TabPane>
        <TabPane tab="FAQs" key="3">
          <FAQsTab parentId={id}/>
        </TabPane>
        <TabPane tab="Connections" key="4">
          <ConnectionsTab parentId={id} updateTrigger={updateTrigger}/>
        </TabPane>
      </Tabs>
      <ConnectToAIChatsModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onConnect={(selectedChats) => {
          handleConnect(selectedChats);
          setIsModalVisible(false);
        }}
        parentId={id}
      />
    </Card>
  );
};

export default KnowledgeBaseDetail;