import React from "react";
import { Button } from "antd";
import { HiOutlineMail } from "react-icons/hi";
import { IoIosCall } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa6";
import { MdHistory } from "react-icons/md";
import { FiDisc } from "react-icons/fi";
import { LuContact2 } from "react-icons/lu";
import { FaRegStopCircle } from "react-icons/fa";
import { FaVideo } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";
import defaultImage from "../../assets/images/linkedinpic.jpg";

function formatStartTime(startTime) {
  const date = new Date(startTime);
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Asia/Kolkata', // IST timezone
    hour12: true
  };
  const formattedDate = date.toLocaleString('en-US', options).toUpperCase();
  return formattedDate.replace(',', '').replace(' AT ', ' AT ');
}

const MeetingsCard = ({ meets }) => {
  const formattedStartTime = formatStartTime(meets?.startTime);

  return (
    <div
      className="contact-card p-3"
      style={{
        border: "1px solid #e8e8e8",
        borderRadius: "8px",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <div className="d-flex align-items-start mb-3">
        <img
          src={meets?.photo_url || defaultImage}
          alt="Profile"
          className="profile-img me-3"
          style={{ width: "60px", height: "60px", borderRadius: "50%" }}
        />
        <div className="text-start meetings-card-info flex-grow-1">
          <h4
            className="mb-1"
            style={{ fontSize: "18px", fontWeight: "bold", color: "#1890ff" }}
          >
            {meets?.name || "Name"}
          </h4>
          <div>
            <div className="row" style={{ fontSize: "10px" }}>
              {meets?.email && (
                <div className="col">
                  <p
                    className="mb-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <HiOutlineMail className="me-2" /> 
                    <a href={`mailto:${meets.email}`}>Email</a>
                  </p>
                </div>
              )}
              {meets?.phone && (
                <div className="col">
                  <p
                    className="mb-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <IoIosCall className="me-2"/>
                    <a href={`tel:${meets.phone}`}>Phone</a>
                  </p>
                </div>
              )}
              {console.log(meets)}
              {meets?.linkedinUrl && (
                <div className="col-md-6">
                  <p
                    className="mb-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FaLinkedinIn className="me-2" />
                    <a href={meets.linkedinUrl} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-start">
        <p className="text-muted my-4" style={{ fontSize: "16px" }}>
          {`${meets?.title || "Owner"} at ${meets?.company_name || "Company"}`}
        </p>
      </div>
      
      <div className="meeting-details mb-3 text-start" style={{fontSize:"12px"}}>
        <p className="mb-1 inline text-muted inline-block" style={{ fontSize: "12px"}}>
          Meeting confirmed for {" "}
          <strong>{formattedStartTime} IST</strong>{" "}
          
          <span
            className="mb-2"
            style={{ fontSize: "12px", display: "flex", alignItems: "center", }}
          >
            (<FaCheckSquare className="me-2" style={{ color: "green" }} />
            Added to their calendar)
          </span>
        </p>
        <h6>User- {meets?.user_name}</h6>
      </div>

      {/* <div className="p-2">
        <div className="d-grid">
          <div className="d-flex">
            <Button
              style={{
                flex: 1,
                borderColor: "green",
                color: "green",
                borderRadius: "0",
              }}
              icon={<MdHistory />}
            >
              HISTORY
            </Button>
            <Button
              style={{
                flex: 1,
                borderColor: "red",
                color: "red",
                borderRadius: "0",
              }}
              icon={<FiDisc />}
            >
              NO SHOW
            </Button>
          </div>
          <Button
            style={{ width: "100%", borderRadius: "0",
              borderColor: "#E75480",
              color: "#E75480",}}
            icon={<LuContact2 />}
          >
            UPDATE CONTACTS
          </Button>
          <Button
            style={{ width: "100%", borderRadius: "0",
              borderColor: "grey",
              color: "grey",}}
            icon={<FaRegStopCircle />}
          >
            STOP REMINDERS
          </Button>
          <Button
            style={{ width: "100%", borderRadius: "0",borderColor:'#7CB9E8',color:'#7CB9E8'
              }}
            icon={<FaVideo />}
          >
            MEETING NOTES
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default MeetingsCard;
