import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/images/logo.png';
import { Button, Card, Spinner } from 'react-bootstrap';
import { Spin } from 'antd';

const OptOut = () => {
  const [email, setEmail] = useState('');
  const [documentId, setDocumentId] = useState('');
  const [message, setMessage] = useState('');
  const [isAlreadyOptedOut, setIsAlreadyOptedOut] = useState(false);
  const [error, setError] = useState(false);
  const [userId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email') || '';
    const documentIdParam = searchParams.get('document_id') || '';
    setEmail(emailParam);
    setDocumentId(documentIdParam);

    if (emailParam && documentIdParam) {
      validateOptOutStatus(emailParam, documentIdParam);
    }
  }, [location]);

  const validateOptOutStatus = async (email, documentId) => {
    setIsValidating(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/emailapp/fetch_opt_out', { email, document_id:documentId });

      if (response.data.status==true) {
        setIsAlreadyOptedOut(true);
      }
      else{
        setUserId(response.data.user_id)
      }
    } catch (error) {
        setError(true)
      console.error('Error validating opt-out status:', error);
    } finally {
      setIsValidating(false);
    }
  };

  const handleOptOut = async () => {
    setIsLoading(true);
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/emailapp/insert_opt_out', { email, documentId,user_id:userId});
      setMessage(response.data.message || 'You have been successfully opted out.');
      setIsAlreadyOptedOut(true);
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      setIsAlreadyOptedOut(true);
    } finally {
      setIsLoading(false);
    }
  };

  const renderCardContent = () => {
    if (isValidating) {
      return (
        <div className="text-center">
          {/* <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner> */}
          {/* <Spin tip="Loading">Loading...</Spin> */}
          <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          <p className="mt-2">Please Wait...</p>
        </div>
      );
    }

    if (!email || !documentId || error) {
      return (
        <div className="text-center">
          <p className="text-red-600 font-bold">ERROR</p>
          <p className="text-sm text-gray-600 mt-2">
            The email or document ID is missing or incorrect. Please make sure both are provided in the URL.
          </p>
        </div>
      );
    }

    if (isAlreadyOptedOut) {
      return (
        <div className="text-center">
          <p className="text-green-600 font-bold text-2xl mb-2">✓</p>
          <p className="text-xl font-bold">Thank You</p>
          <p className="text-sm text-gray-600 mt-2">
            You have already opted out of communications from Gomeet AI.
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2 bold">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            readOnly
            className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100"
          />
          <p className="text-sm text-gray-600 mb-6 mt-3">
            By opting out, you will not receive any communication from Gomeet AI, helping businesses
            connect over serendipitous opportunities with AI.
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
            style={{ width: '50%', margin: '10px 0' }} 
            onClick={handleOptOut} 
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
            
              </>
            ) : (
              'OPT OUT'
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-md" style={{padding:10,marginTop:10}}>
        
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <img src={logo} alt="Gomeet AI Logo" className="w-8 h-8" style={{width:'40px',height:'40px'}} />
          <h4 className="text-md font-bold">OPT OUT</h4>
        </div>
        <div className="max-w-md mx-auto" style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
          <Card style={{ width: '50%', padding: 10 }}>
            {renderCardContent()}
            {message && !isAlreadyOptedOut && (
              <p className="mt-4 text-sm text-center text-gray-600">{message}</p>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OptOut;