import React, { useState, useEffect, useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Form, Input, DatePicker, Pagination, Collapse, Spin, Select } from 'antd';
import MeetingsCard from '../components/Common/MeetingsCard';
import { useSelector } from 'react-redux';
import axios from 'axios';

const { Panel } = Collapse;
const { Option } = Select;


export const Meeting = () => {
  // Redux selectors
  const { 
    userInfo: userInfoState = { 
      is_superuser: false, 
      is_admin: false,
      company_id: null 
    }, 
    userId,
    basicInfo: basicInfoState 
  } = useSelector(state => state.app);

  // State declarations
  const [meetingsDataState, setMeetingsDataState] = useState({
    meetings: [],
    pagination: {}
  });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const [meetingsData, setMeetingsData] = useState({
    meetings_count: {},
    industry: {},
    title: {},
    company_size: {}
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [usersList, setUsersList] = useState([]);
  const [companiesList, setCompanies] = useState([]);
  const [companyFilter, setCompanyFilter] = useState(undefined);


  const showCompanyFilter = userInfoState.is_superuser;
  const showUserFilter = userInfoState.is_superuser || userInfoState.is_admin;


  console.log('Current user permissions:', { 
    is_superuser: userInfoState.is_superuser,
    is_admin: userInfoState.is_admin,
    showCompanyFilter,
    showUserFilter
  });

  // Chart options and series states
  const [dailyMeetingsOptions, setDailyMeetingsOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758']
      }
    },
    xaxis: {
      categories: [],
      position: 'bottom',
    },
    colors: ['#90EE90'],
    legend: {
      position: 'top',
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Meetings'],
      markers: {
        fillColors: ['#90EE90']
      }
    }
  });

  const [dailyMeetingsSeries, setDailyMeetingsSeries] = useState([{
    name: 'Meetings',
    data: []
  }]);

  const [industriesOptions, setIndustriesOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
    },
    colors: ['#90EE90'],
    legend: {
      position: 'top',
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Traction based on Industries'],
      markers: {
        fillColors: ['#90EE90']
      }
    }
  });

  const [industriesSeries, setIndustriesSeries] = useState([{
    name: 'Meetings',
    data: []
  }]);

  const [companySizeOptions, setCompanySizeOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
    },
    colors: ['#90EE90'],
    legend: {
      position: 'top',
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Traction based on Company Size'],
      markers: {
        fillColors: ['#90EE90']
      }
    }
  });

  const [companySizeSeries, setCompanySizeSeries] = useState([{
    name: "Meetings",
    data: []
  }]);

  const [jobTitleOptions, setJobTitleOptions] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
    },
    colors: ['#90EE90'],
    legend: {
      position: 'top',
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Traction based on Job Title'],
      markers: {
        fillColors: ['#90EE90']
      }
    }
  });

  const [jobTitleSeries, setJobTitleSeries] = useState([{
    name: "Meetings",
    data: []
  }]);

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('inline');


  const fetchCompaniesList = useCallback(async () => {
    if (!userInfoState.is_superuser) return;
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/get-companies`, {
        admin: userInfoState.is_superuser
      });
      setCompanies(response.data.companies);
    } catch (error) {
      console.error('Error fetching companies:', error);
    
    }
  }, [userInfoState.is_superuser]);

  // Fetch users list
  const fetchUsersList = useCallback(async (companyId = null) => {
    try {
      setSearchLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/get-users`, {
        admin: userInfoState.is_superuser,
        is_company_admin: userInfoState.is_admin,
        company_id: companyId || userInfoState.company_id
      });
      setUsersList(response.data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setSearchLoading(false);
    }
  }, [userInfoState.is_superuser]);

  const filterCompany = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const filterUser = (input, option) => {
    const fullName = `${option.children}`.toLowerCase();
    return fullName.indexOf(input.toLowerCase()) >= 0;
  };

  const handleCompanyFilter = async (value) => {
    setCompanyFilter(value);
    setSelectedUser(undefined);
    await fetchUsersList(value || null);
    setCurrentPage(1);
  };
  // Update charts with new data
  const updateCharts = (data) => {
    // Update Daily Meetings Chart
    const categories = Object.keys(data.meetings_count);
    const meetingsCount = Object.values(data.meetings_count);

    setDailyMeetingsOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: categories
      }
    }));

    setDailyMeetingsSeries([{
      name: 'Meetings',
      data: meetingsCount
    }]);

    // Update Industries Chart
    const industryCategories = Object.keys(data.industry);
    const industryData = Object.values(data.industry);

    setIndustriesOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: industryCategories
      }
    }));

    setIndustriesSeries([{
      name: 'Meetings',
      data: industryData
    }]);

    // Update Company Size Chart
    const companySizeCategories = Object.keys(data.company_size);
    const companySizeData = Object.values(data.company_size);

    setCompanySizeOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: companySizeCategories
      }
    }));

    setCompanySizeSeries([{
      name: "Meetings",
      data: companySizeData
    }]);

    // Update Job Title Chart
    const titleCategories = Object.keys(data.title);
    const titleData = Object.values(data.title);

    setJobTitleOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: titleCategories
      }
    }));

    setJobTitleSeries([{
      name: "Meetings",
      data: titleData
    }]);
  };

  // Fetch all meetings data
  const fetchMeetingsData = async () => {
    setLoading(true);
    try {
      const [meetingsResponse, graphResponse] = await Promise.all([
        axios.post(`${process.env.REACT_APP_API_URL}/meetings/fetch_meetings/`, {
          user_id: showUserFilter ? selectedUser : userId,
          company_id: showCompanyFilter ? companyFilter : userInfoState.company_id,
          page: currentPage,
          start_date: startDate?.format('YYYY-MM-DD'),
          end_date: endDate?.format('YYYY-MM-DD'),
          is_admin: userInfoState.is_superuser,
          is_company_admin: userInfoState.is_admin
        }),
        axios.post(`${process.env.REACT_APP_API_URL}/meetings/fetch_graph_counts/`, {
          user_id: showUserFilter ? selectedUser : userId,
          company_id: showCompanyFilter ? companyFilter : userInfoState.company_id,
          start_date: startDate?.format('YYYY-MM-DD'),
          end_date: endDate?.format('YYYY-MM-DD'),
          is_admin: userInfoState.is_superuser,
          is_company_admin: userInfoState.is_admin
        })
      ]);

      setMeetingsDataState({
        meetings: meetingsResponse.data.meetings,
        pagination: meetingsResponse.data.pagination
      });
      
      setMeetingsData(graphResponse.data);
      updateCharts(graphResponse.data);
    } catch (error) {
      console.error('Error fetching meetings data:', error);
    } finally {
      setLoading(false);
    }
  };
  



console.log("Meetings-: "+meetingsDataState.meetings)
  // Handle user selection change
  const handleUserChange = async (value) => {
    setSelectedUser(value);
    setCurrentPage(1);
  };

  // Handle pagination change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle date range change
  const handleDateChange = (dates) => {
    setStartDate(dates ? dates[0] : null);
    setEndDate(dates ? dates[1] : null);
    setCurrentPage(1);
  };

  // Format user options for select
  const userSelectOptions = usersList.map(user => ({
    value: user.id,
    label: user.firstname + " " + user.lastname,
  }));

  // Effects
  useEffect(() => {
    if (userInfoState.is_superuser || userInfoState.is_admin) {
      fetchCompaniesList();
      fetchUsersList(companyFilter);
    }
  }, [userInfoState.is_superuser,companyFilter, fetchCompaniesList, fetchUsersList]);

  useEffect(() => {
    fetchMeetingsData();
  }, [currentPage, startDate, endDate, selectedUser, selectedUser, companyFilter]);

  return (
    <div className='container-fluid meeeting-main mt-4'>
    <div className="bg-white rounded shadow p-4">
      <div className='mb-3'>
        <Form
          className='d-flex align-items-end gap-3'
          layout={formLayout}
          form={form}
          initialValues={{ layout: formLayout }}
        >
          {userInfoState.is_superuser && (
            <>
              {showCompanyFilter && (
              <Form.Item label="Select Company" className="mb-0">
                <Select
                  showSearch
                  placeholder="Filter by company"
                  onChange={handleCompanyFilter}
                  value={companyFilter}
                  style={{ width: 200 }}
                  allowClear
                  filterOption={filterCompany}
                  loading={searchLoading}
                  optionFilterProp='children'
                >
                  {companiesList.map(company => (
                    <Option key={company.id} value={company.id}>{company.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
              {showUserFilter && (
              <Form.Item label="Select User" className="mb-0">
                <Select
                  showSearch
                  placeholder="Select a user"
                  onChange={handleUserChange}
                  value={selectedUser}
                  style={{ width: 200 }}
                  allowClear
                  loading={searchLoading}
                  filterOption={filterUser}
                  optionFilterProp="children"
                >
                  {usersList.map(user => (
                    <Option key={user.id} value={user.id}>
                      {`${user.firstname} ${user.lastname}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            </>
          )}
          <Form.Item className="mb-0">
            <DatePicker.RangePicker onChange={handleDateChange} />
          </Form.Item>
        </Form>
      </div>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className='card mb-4'>
            <div className='card-body'>
              <ReactApexChart options={dailyMeetingsOptions} series={dailyMeetingsSeries} type='bar' height={350} />
            </div>
          </div>

            <Collapse defaultActiveKey={['1']} className='mb-4'>
              <Panel header="Traction Based Graphs" key="0">
                <div style={{border:'1px solid #D3D3D3'}}>
                  <ReactApexChart options={industriesOptions} series={industriesSeries} type='bar' height={300} />
                </div>
                <div style={{border:'1px solid #D3D3D3'}}>
                  <ReactApexChart options={companySizeOptions} series={companySizeSeries} type='bar' height={300} />
                </div>
                <div style={{border:'1px solid #D3D3D3'}}>
                  <ReactApexChart options={jobTitleOptions} series={jobTitleSeries} type='bar' height={300} />
                </div>
              </Panel>
            </Collapse>

            <div className='row'>
              {meetingsDataState?.meetings?.map((meet, id) => (
                <div className='col-md-3 my-2' key={id}>
                  <MeetingsCard meets={meet} />
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <Pagination
                current={currentPage}
                total={meetingsDataState?.pagination?.total_meetings}
                pageSize={meetingsDataState?.pagination?.per_page}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};