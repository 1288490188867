import React, { createContext, useContext, useReducer } from 'react';

const TaskProgressContext = createContext();
const TaskProgressDispatchContext = createContext();

const STORAGE_KEY = 'taskProgressState';

const initialState = {
    showProgress: false,
    status: 'processing',
    message: '',
    userId: null,
    progress: 0,
    timestamp: null
};

const taskProgressReducer = (state, action) => {
    switch (action.type) {
        case 'RESTORE_STATE':
            return {
                ...action.payload,
                showProgress: true
            };

        case 'SHOW_PROGRESS':
            return {
                ...state,
                showProgress: true,
                status: 'processing',
                message: action.message || '',
                userId: action.userId,
                progress: 0,
                timestamp: Date.now()
            };
            
        case 'UPDATE_PROGRESS':
            return {
                ...state,
                status: action.status || state.status,
                message: action.message || state.message,
                progress: action.progress !== undefined ? action.progress : state.progress,
                timestamp: Date.now(),
                showProgress: true
            };
            
        case 'HIDE_PROGRESS':
            return {
                ...state,
                showProgress: false
            };
            
        default:
            return state;
    }
};

export function TaskProgressProvider({ children }) {
    const [state, dispatch] = useReducer(taskProgressReducer, initialState);

    return (
        <TaskProgressContext.Provider value={state}>
            <TaskProgressDispatchContext.Provider value={dispatch}>
                {children}
            </TaskProgressDispatchContext.Provider>
        </TaskProgressContext.Provider>
    );
}

export function useTaskProgress() {
    return useContext(TaskProgressContext);
}

export function useTaskProgressDispatch() {
    return useContext(TaskProgressDispatchContext);
}