import React from 'react'
import { Table, Button } from 'antd';
import { CheckOutlined, EditOutlined, SettingOutlined, EyeOutlined } from '@ant-design/icons';

const dataSource = [
    {
      key: '1',
      campaign: '18643',
      company: '', // You can add the company logo here
      agents: 'Glenn, Elenor, Gerardo etc.',
      channel: 'Email',
      owner: 'Divya Shruthee',
      conversations: 'Not Yet',
      audiencePersona: 'Chief Executive Officer, Chief Strategy Officersa dasdas Available Total: 664K',
      calendar: 'Synced',
    },
    {
        key: '2',
        campaign: '18643',
        company: '', // You can add the company logo here
        agents: 'Glenn, Elenor, Gerardo etc.',
        channel: 'Email',
        owner: 'Divya Shruthee',
        conversations: 'Not Yet',
        audiencePersona: 'Chief Executive Officer, Chief Strategy Officersa dasdas Available Total: 664K',
        calendar: 'Synced',
      },
      {
        key: '3',
        campaign: '18643',
        company: '', // You can add the company logo here
        agents: 'Glenn, Elenor, Gerardo etc.',
        channel: 'Email',
        owner: 'Divya Shruthee',
        conversations: 'Not Yet',
        audiencePersona: 'Chief Executive Officer, Chief Strategy Officersa dasdas Available Total: 664K',
        calendar: 'Synced',
      },
      {
        key: '4',
        campaign: '18643',
        company: '', // You can add the company logo here
        agents: 'Glenn, Elenor, Gerardo etc.',
        channel: 'Email',
        owner: 'Divya Shruthee',
        conversations: 'Not Yet',
        audiencePersona: 'Chief Executive Officer, Chief Strategy Officersa dasdas Available Total: 664K',
        calendar: 'Synced',
      },
  ];

  const columns = [
    {
      title: 'CAMPAIGN',
      dataIndex: 'campaign',
      key: 'campaign',
      render: (text) => (
        <Button type="primary" ghost size="small">
          {text}
        </Button>
      ),
    },
    {
      title: 'COMPANY',
      dataIndex: 'company',
      key: 'company',
      render: () => (
        // You can add an <img> tag here for the company logo
        null
      ),
    },
    {
      title: 'AI AGENTS',
      dataIndex: 'agents',
      key: 'agents',
    },
    {
      title: 'CHANNEL',
      dataIndex: 'channel',
      key: 'channel',
    },
    {
      title: 'OWNER',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: 'CONVERSATIONS',
      dataIndex: 'conversations',
      key: 'conversations',
      render: (text) => (
        <div className="conversation-avatars">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: 'AUDIENCE PERSONA',
      dataIndex: 'audiencePersona',
      key: 'audiencePersona',
      render: (text) => (
        <div>
          {text.split(' Available Total: ')[0]}
          <br />
          Available Total: <strong>{text.split(' Available Total: ')[1]}</strong>
        </div>
      ),
    },
    {
      title: 'CALENDAR',
      dataIndex: 'calendar',
      key: 'calendar',
      render: (text) => (
        <div className="synced-status">
          <CheckOutlined />
          {text}
          <Button type="primary" ghost size="small" style={{ marginLeft: 8 }}>
            Reconnect
          </Button>
        </div>
      ),
    },
    {
      title: 'ACTION ITEMS',
      key: 'actionItems',
      render: () => (
        <div className="actions">
          <Button type="secondary" icon={<EditOutlined />} />
          <Button type="secondary" icon={<SettingOutlined />} />
          <Button type="secondary" icon={<EyeOutlined />} />
        </div>
      ),
    },
  ];
  
const Agents = () => {
  return (
    <>
    <div className="container-fluid table-wrapper">
       
        <Table dataSource={dataSource} columns={columns} bordered />;
    </div>
    </>
    
  )
}

export default Agents