import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Drawer, Button } from 'antd';
import { HiOutlineCash } from "react-icons/hi";
import { FiGrid, FiChevronDown, FiMenu } from "react-icons/fi";
import { BsChatDots } from "react-icons/bs";
import Logo from '../../assets/images/logo.png'

const menuItems = [
  { key: "home", label: "Home", icon: <FiGrid />, path: "/" },
  {
    key: "sales",
    label: "Sales",
    icon: <HiOutlineCash />,
    path: "/sales",
    children: [
      {
        key: "sales-engagement",
        label: "Sales Engagement",
        children: [
          { key: "meetings", label: "Meetings", path: "/meetings" },
          { key: "prospects", label: "Prospects", path: "/prospects" },
          { key: "visitors", label: "Visitors", path: "/visitors" },
        ],
      },
    ],
  },
  { key: "recruitment", label: "Recruitment", icon: <FiGrid />, path: "/", disabled: true },
  { key: "marketing", label: "Marketing", icon: <FiGrid />, path: "/", disabled: true },
  { key: "supply-chain", label: "Supply Chain", icon: <FiGrid />, path: "/", disabled: true },
  { key: "finance", label: "Finance", icon: <FiGrid />, path: "/", disabled: true },
  { key: "reporting-analytics", label: "Reporting and Analytics", icon: <FiGrid />, path: "/", disabled: true },
  {
    key: "ai-chat",
    label: "AI Chat",
    icon: <BsChatDots />,
    children: [
      { key: "ai-chat-list", label: "AI Chat", path: "/chats" },
      { key: "knowledge-bases", label: "Knowledge Bases", path: "/knowledge-bases" },
       { key: "webchat-inbox", label: "Webchat Inbox", path: "/webchat-inbox" },
       { key: "contacts", label: "Contacts", path: "/contacts" },
    ],
  },
];

const HeaderMenu = () => {
  const location = useLocation();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const convertToAntdItems = (items) => {
    return items.map((item) => {
      const antdItem = {
        key: item.key,
        icon: item.icon,
        label: item.children ? (
          <span>
            {item.label}
            <FiChevronDown style={{ marginLeft: '5px' }} />
          </span>
        ) : (
          <Link to={item.path}>{item.label}</Link>
        ),
        disabled: item.disabled,
      };

      if (item.children) {
        antdItem.children = [
          ...convertToAntdItems(item.children),
        ];
      }

      return antdItem;
    });
  };

  const findSelectedKeys = (pathname, items) => {
    for (const item of items) {
      if (item.path === pathname) {
        return [item.key];
      }
      if (item.children) {
        const childKeys = findSelectedKeys(pathname, item.children);
        if (childKeys.length > 0) {
          return [item.key, ...childKeys];
        }
      }
    }
    return [];
  };

  const selectedKeys = findSelectedKeys(location.pathname, menuItems);
  const antdMenuItems = convertToAntdItems(menuItems);

  const menuComponent = (
    <Menu
      mode={isDesktop ? "horizontal" : "inline"}
      selectedKeys={selectedKeys}
      items={antdMenuItems}
      className={isDesktop ? "w-full" : ""}
    />
  );

  return (
    <nav className="navigation_cs lg:flex items-center mr-auto w-full">
      {isDesktop ? (
        menuComponent
      ) : (
        <>
          <Button icon={<FiMenu />} onClick={() => setDrawerVisible(true)} />
          <Drawer
            title="Menu"
            placement="left"
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
            className="drawer-custom"
          >
            {menuComponent}
          </Drawer>
        </>
      )}
      
    </nav>
  );
};

export default HeaderMenu;
