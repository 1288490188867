// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar.show {
  right: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e8e8e8;
  flex-shrink: 0;
}

.sidebar-header h3 {
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.sidebar-content {
  padding: 20px;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-content .ant-space {
  width: 100%;
}
/* 
.sidebar-content .ant-select,
.sidebar-content .ant-input {
  margin-bottom: 15px;
} */

/* Custom scrollbar styles */
.sidebar-content::-webkit-scrollbar {
  width: 6px;
}

.sidebar-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Contacts.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,yCAAyC;EACzC,kCAAkC;EAClC,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;AACA;;;;GAIG;;AAEH,4BAA4B;AAC5B;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".sidebar {\n  position: fixed;\n  top: 0;\n  right: -300px;\n  width: 300px;\n  height: 100%;\n  background-color: #fff;\n  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);\n  transition: right 0.3s ease-in-out;\n  z-index: 1000;\n  display: flex;\n  flex-direction: column;\n}\n\n.sidebar.show {\n  right: 0;\n}\n\n.sidebar-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 20px;\n  border-bottom: 1px solid #e8e8e8;\n  flex-shrink: 0;\n}\n\n.sidebar-header h3 {\n  margin: 0;\n}\n\n.close-btn {\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.sidebar-content {\n  padding: 20px;\n  flex: 1;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.sidebar-content .ant-space {\n  width: 100%;\n}\n/* \n.sidebar-content .ant-select,\n.sidebar-content .ant-input {\n  margin-bottom: 15px;\n} */\n\n/* Custom scrollbar styles */\n.sidebar-content::-webkit-scrollbar {\n  width: 6px;\n}\n\n.sidebar-content::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n.sidebar-content::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 3px;\n}\n\n.sidebar-content::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
