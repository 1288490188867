import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import { Typography, Table, Avatar, Spin, message, Button, Popconfirm, Tooltip } from 'antd';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import { Spinner } from "react-bootstrap";

const { Title, Text } = Typography;

const ConnectionsTab = ({ parentId, updateTrigger }) => {
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchConnections = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/aichat/connections/${parentId}`);
      setConnections(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching connections:', error);
      message.error('Failed to fetch connections');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConnections();
  }, [parentId, updateTrigger]);

  const handleDelete = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/aichat/delete_connected_knowledge_base/`,{
        aichat_id: id,
        knowledge_base_id: parentId
    });
      message.success('Connection disconnected successfully');
      fetchConnections(); // Refresh the connections list
    } catch (error) {
      console.error('Error disconnecting connection:', error);
      message.error('Failed to delete connection');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span>
          <Avatar src={record.chat_icon || "https://api.dicebear.com/7.x/miniavs/svg?seed=1"} style={{ marginRight: 8,backgroundColor: '#1677ff'}}  />
          <Link to={`/chats/${record.id}`}>{text}</Link>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this connection?"
          onConfirm={() => handleDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Disconnect">
            <Button icon={<DeleteOutlined />} danger></Button>
          </Tooltip>
        </Popconfirm>
      ),
    },
  ];

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
  }

  if (connections.length === 0) {
    return (
      <div>
        <Title level={3}>Connections</Title>
        <Text>This knowledge base is not connected to any chats.</Text>
      </div>
    );
  }

  return (
    <div>
      <Title level={3}>Connections</Title>
      <Table
        columns={columns}
        dataSource={connections}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

export default ConnectionsTab;