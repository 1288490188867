import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import { Button,  Container, Row, Col, Spinner } from 'react-bootstrap';
import { Breadcrumb, Drawer, Menu, Spin, Steps  } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { basicInfo } from '../components/reducers/slice';
import { FaLinkedin, FaFacebook, FaTwitter, FaEnvelope, FaGlobe } from 'react-icons/fa';
import './ReviewSamples.css';
import styled from "styled-components";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
  DashboardOutlined,
  RobotOutlined,
  ApartmentOutlined,
  DatabaseOutlined,
  CalendarOutlined,
  FileSearchOutlined,
  DollarOutlined,
  RocketOutlined
} from "@ant-design/icons";
import { SideMenu } from '../components/Common/SideMenu';


// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // margin-bottom: 30px;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }
`;

function ReviewSamples() {
  const { userId, basicInfo: basicInfoState } = useSelector(state => state.app);

  const [samples, setSamples] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const fetchSamples = useCallback(async () => {
    if (!userId || !basicInfoState) return;
    
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agent_setup/sample_prospect_listing/${userId}`
      );
      setSamples(response.data);
    } catch (error) {
      console.error('Error fetching samples:', error);
    } finally {
      setLoading(false);
    }
  }, [userId, basicInfoState?._id]); // Only depend on userId and basicInfoState._id

  useEffect(() => {
    fetchSamples();
  }, [fetchSamples]); 

  const handleAIDemoClick = (email) => {
    navigate('/email-display', { state: { personEmail: email, documentId: basicInfoState._id, user_id: userId, showHeader: true } });
  };

  const handleAILandingPageClick = (email) => {
    navigate(`/ai-landing?email=${encodeURIComponent(email)}&documentId=${encodeURIComponent(basicInfoState._id)}`, { state: { showHeader: true } });
  };

  const handleContinue = async () => {
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/update_review_samples`, { user_id: userId });
      if (basicInfoState && basicInfoState.current_plan) {
        navigate('/confirm-deploy-agents');
      } else {
        navigate('/pricing');
      }
    } catch (error) {
      console.error('Error updating review samples:', error);
    } finally {
      setLoading(false);
    }
  };

  const SocialIcon = ({ url, Icon, color }) => {
    if (!url) return null;
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" className="social-icon-link">
        <div className="social-icon" style={{ backgroundColor: color }}>
          <Icon size="14px" />
        </div>
      </a>
    );
  };

  return (
      <PageBackground>
        <Container fluid className="">
          <Card className='d-none d-lg-block'>
            <Steps
                items={[
                  {
                    title: 'Dashboard',
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    title: 'Onboarding',
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    title: 'Customize AI Personality',
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    title: 'Workflow & Role',
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    title: 'Data Integration & Training',
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    title: 'Connect Calendly',
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    title: 'Review Samples',
                    status: 'process',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    title: 'Deploy Agents',
                    status: 'wait',
                    icon: <RocketOutlined />,
                  }
                ]}
            />
          </Card>
          <Card className='p-3 d-sm-block d-none d-lg-none'>
            <Steps
                items={[
                  {
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    status: 'process',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <DollarOutlined />,
                  }
                ]}
            />
          </Card>

          <div className="d-flex  flex-column flex-md-row mt-4">
            <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
              <SideMenu currentStep={7} activePage="/review-samples" />
            </Card>
            <div className='mb-3'>
              <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
                Navigation Menu
              </Button>
            </div>
            <Drawer title="Navigation Menu" onClose={onClose} open={open}>
                <SideMenu currentStep={7} activePage="/review-samples" />
            </Drawer>

            <div className="w-100 overflow-auto">
              <Card>
                <h2 className="main-card-heading">Review Samples, deploy agents!</h2>
                {loading ? (
                    <div className="text-center">
                      {/* <TailSpin height="50" width="50" color="blue" /> */}
                      <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading events...</span>
                                                    </Spinner>
                      {/* <Spin tip="Loading"/> */}
                    </div>
                ) : (
                    <>
                      <div className="table-responsive">
                      <table className="sample-table">
                        <thead className="sample-table-header">
                        <tr>
                          <th className="sample-column" >NAME</th>
                          <th className="sample-column">TITLE</th>
                          <th className="sample-column" >COMPANY</th>
                          <th className="sample-column" >LOCATION</th>
                          <th className="sample-column">AI DEMO</th>
                        </tr>
                        </thead>
                        {samples.map(sample => (
                          
                            <tbody key={sample.document_id} className="sample-table-row">
                            <tr>
                              <td className="sample-column">
                              <div className="d-flex" style={{minWidth: '200px'}}>
                              <div className="avatar-container" style={{ position: 'relative' }}>
                            {sample.person?.photo_url && (
                              <img 
                                src={sample.person?.photo_url} 
                                className='avatar'
                                style={{ display: 'block' }}
                                onError={(e) => {
                                  e.target.style.display = 'none';
                                  const initials = sample.person?.name?.split(' ')
                                    .map(word => word[0])
                                    .join('')
                                    .toUpperCase()
                                    .slice(0, 2);
                                    
                                  const fallbackDiv = document.createElement('div');
                                  fallbackDiv.className = 'avatar';
                                  fallbackDiv.style.cssText = `
                                    background-color: #e0e0e0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #000000;
                                    font-weight: 500;
                                    font-size: 14px;
                                    padding: 20px;
                                  `;
                                  fallbackDiv.textContent = initials;
                                  e.target.parentNode.appendChild(fallbackDiv);
                                }}
                              />
                            )}
                            {!sample.person?.photo_url && (
                              <div 
                                className='avatar'
                                style={{
                                  backgroundColor: '#e0e0e0',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  color: '#000000',
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  padding: '20px',
                                }}
                              >
                                {sample.person?.name?.split(' ').map(word => word[0]).join('').toUpperCase().slice(0, 2)}
                              </div>
                            )}
                          </div>
                          
        
        
                                <div className=''>
                                    <span className="mx-2">{sample.person && sample.person.name}</span>
                                    <div className="social-icons">
                                      <SocialIcon url={sample.person && sample.person.linkedin_url} Icon={FaLinkedin}  />
                                      <SocialIcon url={sample.person && sample.person.facebook_url} Icon={FaFacebook}  />
                                      <SocialIcon url={sample.person && sample.person.twitter_url} Icon={FaTwitter} />
                                    </div>
                                  </div>

                                </div>
                              </td>
                              <td className="sample-column">{sample.person && sample.person.title}</td>
                              <td className="sample-column">{sample.organization && sample.organization.name}</td>
                              <td className="sample-column">{sample.person && sample.person.combined_contact_location}</td>
                              <td className="sample-column">
                                <div className='d-flex align-items-center button-review-sample'>
                                  <Button  size="sm" className="btn-email" onClick={() => handleAIDemoClick(sample.person.email)}>
                                    AI Email
                                  </Button>
                                  <Button  size="sm" className="btn-landing" onClick={() => handleAILandingPageClick(sample.person.email)}>
                                    AI Landing Page
                                  </Button>
                                </div>

                              </td>
                            </tr>
                            </tbody>
                        ))}
                      </table>

                    </div>
                     <div className="text-center mt-4">
                     <Button variant="primary" className='continue-btn' size="lg" onClick={handleContinue} disabled={loading}>
                       {/* {loading ? <Spinner animation="border" role="status">
                                                 </Spinner> : 'Continue'} */}
                                                 {loading ? (
                             <TailSpin height="20" width="20" color="white" />
                         ) : (
                             'Continue'
                         )}
                     </Button>
                   </div>
                    </>
                )}

              </Card>
            </div>
          </div>


        </Container>
      </PageBackground>
  );
}

export default ReviewSamples;
