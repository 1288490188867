import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

// Define public routes
const publicRoutes = ['/login', '/register', '/ai-landing', '/schedule-meeting', '/opt-out'];

// Async thunk for fetching basic info
export const basicInfo = createAsyncThunk(
  'app/basicInfo',
  async (_, { getState }) => {
    const { userId } = getState().app;
    if (!userId) {
      throw new Error('User ID not available');
    }
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/agent_setup/get_sales_agent_basic_info/${userId}`);
    return response.data;
  }
);

// Async thunk for fetching user details
export const userInfo = createAsyncThunk(
    'app/userInfo',
    async (user_id, { rejectWithValue }) => {
        const myHeaders = new Headers();
        myHeaders.append("X-Database-Id", "test");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + Cookies.get('accessToken'));

        const raw = JSON.stringify({
            "token": Cookies.get('accessToken')
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_GLOBAL_API_URL}/api/v1/token/verify/`, requestOptions);
            
            const result = await response.json();

            return result;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);


const appSlice = createSlice({
  name: 'app',
  initialState: {
    value: 0,
    userId: null,
    basicInfo: null,
    userInfo: null,
    insertedId: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {
    userId: (state) => {
      const accessToken = Cookies.get('accessToken');
      if (accessToken) {
        // If accessToken exists, decode and set the userId
        const decoded = jwtDecode(accessToken);
        state.userId = decoded.user_id;
      } else {
        // If no accessToken, check if we're on a public route
        const currentPath = window.location.pathname;
        if (!publicRoutes.includes(currentPath)) {
          // If not on a public route, redirect to the login page
          console.log('No access token, redirecting to login...');
          window.location.href = 'https://gomeet.ai/?logout';
        } else {
          // If on a public route, allow access without userId
          console.log(`On public route ${currentPath}, allowing access without userId`);
          state.userId = null;
        }
      }
    },
    resetAppState: (state) => {
      // Reset all state properties to their initial values
      return appSlice.getInitialState();
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(basicInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(basicInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.basicInfo = action.payload;
        state.insertedId = action.payload._id;
      })
      .addCase(basicInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(userInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload;
      })
      .addCase(userInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { userId ,resetAppState} = appSlice.actions;
export default appSlice.reducer;