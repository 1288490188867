import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { basicInfo } from '../components/reducers/slice';
import { Drawer, Menu, Steps } from 'antd';
import './WorkflowAndRoleSetup.css'; // We'll create this CSS file next
import { ApartmentOutlined, AppstoreOutlined, CalendarOutlined, DashboardOutlined, DatabaseOutlined, DollarOutlined, FileSearchOutlined, MailOutlined, RobotOutlined, RocketOutlined, SettingOutlined,SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { SideMenu } from '../components/Common/SideMenu';
import Lottie from 'react-lottie';
import workflowAnimation from '../assets/workflow.json';
import { TailSpin } from 'react-loader-spinner';

const departments = [
  { value: 'sales', label: 'Sales' },
  { value: 'marketing', label: 'Marketing (Coming Soon)', disabled: true },
  { value: 'hr', label: 'HR (Coming Soon)', disabled: true },
  { value: 'supply_chain', label: 'Supply Chain (Coming Soon)', disabled: true },
  { value: 'finance', label: 'Finance (Coming Soon)', disabled: true },
  { value: 'reporting_analytics', label: 'Reporting and Analytics (Coming Soon)', disabled: true },
];

const useCases = [
  { value: 'sales_engagement', label: 'Sales Engagement' },
  { value: 'sales_automation', label: 'Sales Automation', disabled: true },
  { value: 'revenue_acceleration', label: 'Revenue Acceleration', disabled: true },
  { value: 'pipeline_generation', label: 'Pipeline Generation', disabled: true },
  { value: 'sales_intelligence', label: 'Sales Intelligence', disabled: true },
  { value: 'sales_training', label: 'Sales Training', disabled: true },
  { value: 'sales_coaching', label: 'Sales Coaching', disabled: true },
  { value: 'sales_tracking', label: 'Sales Tracking', disabled: true },
];

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // margin-bottom: 30px;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }
`;

function WorkflowAndRoleSetup() {
  const [selectedDepartment, setSelectedDepartment] = useState('sales');
  const [selectedUseCase, setSelectedUseCase] = useState('sales_engagement');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, basicInfo: basicInfoState } = useSelector(state => state.app);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (basicInfoState && basicInfoState.department) {
      setSelectedDepartment(basicInfoState.department);
    }
    if (basicInfoState && basicInfoState.use_case) {
      setSelectedUseCase(basicInfoState.use_case);
    }
  }, [basicInfoState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDepartment) {
      alert('Please select a department');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/update-workflow-role-setup`, {
        userId: userId,
        department: selectedDepartment,
        use_case: selectedUseCase,
      });

      if (response.data.success) {
        dispatch(basicInfo({ ...basicInfo, department: selectedDepartment, use_case: selectedUseCase }));
        navigate('/audience-filters');
      } else {
        console.error('Failed to update department and use case');
        alert('There was an error updating your settings. Please try again.');
      }
    } catch (error) {
      console.error('Error updating department and use case:', error);
      alert('There was an error updating your settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const menuItems = [
    {
      key: 'sub1',
      label: 'Dashboard',
      icon: <MailOutlined />,
      path: '/',
    },
    {
      key: 'sub2',
      label: 'Onboarding',
      icon: <AppstoreOutlined />,
      path: '/onboarding',
    },
    {
      key: 'sub3',
      label: 'Customize AI Personality',
      icon: <SettingOutlined />,
      path: '/customize-ai-personality',
    },
    {
      key: 'sub4',
      label: 'Workflow & Role',
      icon: <SettingOutlined />,
      path: '/workflow-and-role-setup',
      // disabled: true
    },
    {
      key: 'sub5',
      label: 'Data Integration & Training',
      icon: <SettingOutlined />,
      path: '/data-integration-and-training',
      disabled: true
    },
    {
      key: 'sub6',
      label: 'Connect Calendly',
      icon: <SettingOutlined />,
      path: '/connect-calendly',
      disabled: true
    },
    {
      key: 'sub7',
      label: 'Review Samples',
      icon: <SettingOutlined />,
      path: '/review-samples',
      disabled: true
    }
  ];

  const onClick = (e) => {
    const selectedItem = menuItems.find(item => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navigate(selectedItem.path);
    }
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: workflowAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <PageBackground>
      <Container fluid >
        <Card className='d-none d-lg-block'>
          <Steps
              items={[
                {
                  title: 'Dashboard',
                  status: 'finish',
                  icon: <DashboardOutlined />,
                },
                {
                  title: 'Onboarding',
                  status: 'finish',
                  icon: <UserOutlined />,
                },
                {
                  title: 'Customize AI Personality',
                  status: 'finish',
                  icon: <RobotOutlined />,
                },
                {
                  title: 'Workflow & Role',
                  status: 'process',
                  icon: <ApartmentOutlined />,
                },
                {
                  title: 'Data Integration & Training',
                  status: 'wait',
                  icon: <DatabaseOutlined />,
                },
                {
                  title: 'Connect Calendly',
                  status: 'wait',
                  icon: <CalendarOutlined />,
                },
                {
                  title: 'Review Samples',
                  status: 'wait',
                  icon: <FileSearchOutlined />,
                },
                {
                  title: 'Deploy Agents',
                  status: 'wait',
                  icon: <RocketOutlined />,

                }
              ]}
          />
        </Card>
        <Card className='p-3 d-sm-block d-none d-lg-none'>
          <Steps
              items={[
                {
                  status: 'finish',
                  icon: <DashboardOutlined />,
                },
                {
                  status: 'finish',
                  icon: <UserOutlined />,
                },
                {
                  status: 'finish',
                  icon: <RobotOutlined />,
                },
                {
                  status: 'process',
                  icon: <ApartmentOutlined />,
                },
                {
                  status: 'wait',
                  icon: <DatabaseOutlined />,
                },
                {
                  status: 'wait',
                  icon: <CalendarOutlined />,
                },
                {
                  status: 'wait',
                  icon: <FileSearchOutlined />,
                },
                {
                  status: 'wait',
                  icon: <DollarOutlined />,

                }
              ]}
          />
        </Card>

        <div className="d-flex flex-column flex-md-row mt-4">
          <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
            <SideMenu currentStep={4} activePage="/workflow-and-role-setup" />
          </Card>
          <div className='mb-3'>
              <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
                Navigation Menu
              </Button>
            </div>
            <Drawer title="Navigation Menu" onClose={onClose} open={open}>
              <SideMenu currentStep={4} activePage="/workflow-and-role-setup" />
            </Drawer>
          <div className="w-100">

            <Card>
              <h2 className="main-card-heading">Workflow and Role Setup</h2>
              <div  className='row'>
                <div className="col-lg-5 col-md-12 col-sm-12">

                  <Form onSubmit={handleSubmit} className="workflow-form">
                    <Form.Group className="mb-4">
                      <Form.Label>Select Department</Form.Label>
                      <Form.Select
                          value={selectedDepartment}
                          onChange={(e) => setSelectedDepartment(e.target.value)}
                          className="workflow-select"
                      >
                        {departments.map((dept) => (
                            <option key={dept.value} value={dept.value} disabled={dept.disabled}>
                              {dept.label}
                            </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Select Use Case</Form.Label>
                      <Form.Select
                          value={selectedUseCase}
                          onChange={(e) => setSelectedUseCase(e.target.value)}
                          className="workflow-select"
                      >
                        {useCases.map((useCase) => (
                            <option key={useCase.value} value={useCase.value} disabled={useCase.disabled}>
                              {useCase.label} {useCase.disabled ? '(Coming Soon)' : ''}
                            </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={isLoading} className="continue-btn mt-3">
                      {isLoading ? (
                          <TailSpin height="20" width="20" color="white" />
                      ) : (
                          'Continue'
                      )}
                    </Button>
                  </Form>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 d-flex justify-content-center align-items-top">
                  <div className="lottie-container">
                    <Lottie options={defaultOptions} height="100%" width="100%" />
                  </div>
                </div>
              </div>


            </Card>
          </div>
        </div>
    </Container>
  </PageBackground>
  );
}

export default WorkflowAndRoleSetup;
