import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Avatar, Tag, Input, Pagination, Select, Row, Col, Card, Statistic, Button} from 'antd';
import { LinkedinOutlined, FacebookOutlined, TwitterOutlined, SortAscendingOutlined, SortDescendingOutlined, EyeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { userInfo } from '../components/reducers/slice';

const { Option } = Select;

const Prospects = () => {

  const { userInfo: userInfoState = { 
    is_superuser: false, 
    is_admin: false,
    company_id: null
  }, basicInfo: basicInfoState } = useSelector(state => state.app);
  const [prospects, setProspects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProspects, setTotalProspects] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [companyFilter, setCompanyFilter] = useState(undefined);
  const [companies, setCompanies] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [usersList, setUsersList] = useState([]);
  const { userId } = useSelector(state => state.app);
  const [statistics, setStatistics] = useState([]);
  const navigate = useNavigate();
  const showCompanyFilter = userInfoState.is_superuser;
  const showUserFilter = userInfoState.is_superuser || userInfoState.is_admin;

  console.log('userInfoState', userInfoState);
  console.log('Redux state:', useSelector(state => state.app));

  const fetchUsers = useCallback(async (companyId = null) => {
    // if (userInfoState.is_superuser || userInfoState.is_admin) return;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/get-users`, {
        admin: userInfoState.is_superuser,
        is_company_admin: userInfoState.is_admin,
        company_id: companyId || userInfoState.company_id
      });
      setUsersList(response.data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, [userInfoState]);

  const fetchStatistics = useCallback(async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/get-prospect-statistics`, {
        user_id: userInfoState && userInfoState.is_superuser ? selectedUser : userId,
        admin: userInfoState && userInfoState.is_superuser
      });
      setStatistics(response.data.statistics);
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  }, [selectedUser]);

  // Separate function to fetch all prospects without filters for company list
  const fetchCompanies = useCallback(async () => {
    if (!userInfoState.is_superuser) return;
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/get-companies`, {
        admin: userInfoState.is_superuser
      });
      setCompanies(response.data.companies);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  }, [userInfoState]);

  const fetchProspects = useCallback(async () => {
    
    try {
      setLoading(true);
      
      const payload = {
        user_id: showUserFilter ? selectedUser : userId,
        company_id: showCompanyFilter ? companyFilter : userInfoState.company_id,
        admin: userInfoState.is_superuser,
        is_company_admin: userInfoState.is_admin,
        search: searchQuery,
        sort_by: sortField,
        sort_order: sortOrder,
        page: currentPage,
        per_page: pageSize,
        status: statusFilter
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/agent_setup/get-prospects`,
        payload
      );
     
      setProspects(response.data.results);
      setTotalProspects(response.data.count);
    } catch (error) {
      console.error('Error fetching prospects:', error);
    } finally {
      setLoading(false);
    }
  }, [searchQuery, sortField, sortOrder, currentPage, pageSize, statusFilter, selectedUser, companyFilter, userInfoState]);


  
  // Initial fetch of companies and statistics
  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  // Separate effect for fetching prospects when filters change
  useEffect(() => {
    fetchProspects();
  }, [fetchProspects]);

  useEffect(() => {
    fetchStatistics();
  }, [selectedUser])

  useEffect(() => {
    if (userInfoState && (userInfoState.is_superuser|| userInfoState.is_admin)) {
      fetchCompanies();
      fetchUsers(companyFilter);
    }
  }, [userInfoState, companyFilter])

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setCurrentPage(1);
    }, 300),
    []
  );

  const handleSearch = (e) => {
    debouncedSearch(e.target.value);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    setCurrentPage(1);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
    setCurrentPage(1);
  };

  const handleCompanyFilter = async (value) => {
    setCompanyFilter(value);
    setSelectedUser(undefined); // Reset selected user when company changes
    
    // // If a company is selected, fetch its users
    // if (value) {
    //   await fetchUsers(value);
    // } else {
    //   // If company filter is cleared, fetch all users
    //   await fetchUsers();
    // }
    
    setCurrentPage(1);
  };

  const handleUserFilter = (value) => {
    setSelectedUser(value);
    setCurrentPage(1);
  };


  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'pending':
        return 'default';
      case 'email sent':
        return 'blue';
      case 'interested':
        return 'orange';
      case 'meeting booked':
        return 'green';
      default:
        return 'default';
    }
  };

  const columns = [
    // First column (Avatar)
    {
      title: '',
      dataIndex: 'profile_image_url',
      key: 'profile_image_url',
      render: (text, record) => (
        <Avatar src={text} size={40}>
          {record.name?.charAt(0)}
        </Avatar>
      ),
    },
    // Name column
    {
      title: (
        <div onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
          Name
          {sortField === 'name' && (sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <div>
            {record.linkedin_url && (
              <a href={record.linkedin_url} target="_blank" rel="noopener noreferrer">
                <LinkedinOutlined style={{ marginRight: 8 }} />
              </a>
            )}
            {record.facebook_url && (
              <a href={record.facebook_url} target="_blank" rel="noopener noreferrer">
                <FacebookOutlined style={{ marginRight: 8 }} />
              </a>
            )}
            {record.twitter_url && (
              <a href={record.twitter_url} target="_blank" rel="noopener noreferrer">
                <TwitterOutlined style={{ marginRight: 8 }} />
              </a>
            )}
          </div>
        </div>
      ),
    },
    // Company column
    {
      title: (
        <div onClick={() => handleSort('company')} style={{ cursor: 'pointer' }}>
          Company
          {sortField === 'company' && (sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
    },
    // Status column
    {
      title: (
        <div onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
          Status
          {sortField === 'status' && (sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={getStatusColor(status)} style={{ fontWeight: 'bold' }}>
          {status}
        </Tag>
      ),
    },
    // Date & Time column
    {
      title: (
        <div onClick={() => handleSort('date')} style={{ cursor: 'pointer' }}>
          Date & Time
          {sortField === 'date' && (sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
        </div>
      ),
      dataIndex: 'sent_at',
      key: 'dateTime',
      render: (sent_at, record) => {
        const dateTime = record.delivered_time || sent_at;
        return dateTime ? new Date(dateTime).toLocaleString() : '';
      },
    },
    // Action column (now at the end)
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        if (record.status != 'Pending') {
          return (
            <Button 
              type="text"
              icon={<EyeOutlined />}
              onClick={() => {
                console.log('record.user_id', record.user_id)
                navigate('/email-display', { state: { personEmail: record.email, user_id: record.user_id } });
              }}
            />
          );
        }
        return null;
      },
    },
  ];

  // Add user column only if user is superuser
  if (userInfoState && userInfoState.is_superuser) {
    columns.splice(columns.length - 1, 0, {
      title: (
        <div onClick={() => handleSort('user_name')} style={{ cursor: 'pointer' }}>
          User
          {sortField === 'user_name' && (sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
        </div>
      ),
      dataIndex: 'user_name',
      key: 'user_name'
    });
  }

  return (
    <div className="container-fluid mt-4">
      <div className="bg-white rounded shadow p-4">
        <h2 className="fw-bold mb-4">Prospects</h2>
        <Row gutter={16} className="mb-4">
          {statistics.map((stat) => (
            <Col span={4} key={stat.status}>
              <Card>
                <Statistic
                  title={stat.status}
                  value={stat.count}
                  suffix={`/ ${stat.percentage}%`}
                  valueStyle={{ color: getStatusColor(stat.status) }}
                />
              </Card>
            </Col>
          ))}
        </Row>
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <Input
            placeholder="Search prospects"
            onChange={handleSearch}
            style={{ width: 300 }}
          />
          <div style={{ display: 'flex', gap: '16px' }}>
          {/* Company Filter - Only for superadmin */}
          {showCompanyFilter && (
              <Select
                style={{ width: 200 }}
                placeholder="Filter by company"
                onChange={handleCompanyFilter}
                value={companyFilter}
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {companies.map(company => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
            )}

            {/* User Filter - For both superadmin and company admin */}
            {showUserFilter && (
              <Select
                style={{ width: 200 }}
                placeholder="Filter by user"
                onChange={handleUserFilter}
                value={selectedUser}
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {usersList.map(user => (
                  <Option key={user.id} value={user.id}>
                    {`${user.firstname} ${user.lastname}`}
                  </Option>
                ))}
              </Select>
            )}
          
          <Select
              style={{ width: 200 }}
              placeholder="Filter by status"
              onChange={handleStatusFilter}
              value={statusFilter}
              allowClear
            >
              <Option value="pending">Pending</Option>
              <Option value="email sent">Email Sent</Option>
              <Option value="interested">Interested</Option>
              <Option value="meeting booked">Meeting Booked</Option>
              <Option value="opened">Opened</Option>
              <Option value="replied">Replied</Option>
            </Select>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={prospects}
          loading={loading}
          rowKey="_id"
          pagination={false}
        />
        <div className="mt-4 d-flex justify-content-end">
          <Pagination
            current={currentPage}
            total={totalProspects}
            pageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger
            showQuickJumper
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} prospects`}
          />
        </div>
      </div>
    </div>
  );
};



export default Prospects;