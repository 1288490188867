import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Breadcrumb, Drawer, Steps } from 'antd';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { basicInfo } from '../components/reducers/slice';
import styled from 'styled-components';
import { FaUser, FaPencilAlt, FaUpload } from 'react-icons/fa';
import { ApartmentOutlined, AppstoreOutlined, CalendarOutlined, DashboardOutlined, DatabaseOutlined, DollarOutlined, FileSearchOutlined, RobotOutlined, RocketOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { SideMenu } from '../components/Common/SideMenu';

// Import avatar images
import avatar1 from '../assets/images/avatars/avatar1.jpg';
import avatar2 from '../assets/images/avatars/avatar2.jpg';
import avatar5 from '../assets/images/avatars/avatar5.jpg';
import avatar7 from '../assets/images/avatars/avatar7.jpg';
import { TailSpin } from 'react-loader-spinner';

// Static data for avatars and names
const staticAvatars = [avatar1, avatar2, avatar7, avatar5];
const staticNames = ['Steve Prescott', 'Alice Williams', 'Peter Miranda', 'Amellia Scott'];

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 1rem;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const CardTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
`;

const AvatarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;

  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
`;

const AvatarOption = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
`;

const AvatarImage = styled.img`
  width: 100%;
  max-width: 120px;
  height: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid ${props => props.selected ? '#007bff' : 'transparent'};
  transition: all 0.3s ease;
  box-shadow: ${props => props.selected ? '0 0 0 4px rgba(0, 123, 255, 0.25)' : 'none'};

  &:hover {
    border-color: ${props => props.disabled ? 'transparent' : '#007bff'};
    transform: ${props => props.disabled ? 'none' : 'scale(1.05)'};
  }

  @media (max-width: 576px) {
    max-width: 80px;
  }
`;

const CustomUploadButton = styled.div`
  width: 100%;
  max-width: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px dashed #ced4da;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: #007bff;
    transform: scale(1.05);
  }

  @media (max-width: 576px) {
    max-width: 80px;
  }
`;

const NameOption = styled.label`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f8f9fa;
    border-color: #007bff;
  }
`;

const NameIcon = styled.span`
  margin-right: 0.625rem;
  color: #007bff;
`;

const CustomNameInput = styled(Form.Control)`
  margin-top: 0.75rem;
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding: 0.625rem 0.9375rem;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

function CustomizeAIPersonality() {
  const [avatars, setAvatars] = useState(staticAvatars);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [customAvatar, setCustomAvatar] = useState(null);
  const [names, setNames] = useState(staticNames);
  const [selectedName, setSelectedName] = useState(staticNames[0]);
  const [customName, setCustomName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, basicInfo: basicInfoState } = useSelector(state => state.app);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (basicInfoState && basicInfoState.assistant_avatar) {
      const avatarPath = basicInfoState.assistant_avatar;
      const matchingAvatar = staticAvatars.find(avatar => avatar.includes(avatarPath));
      setSelectedAvatar(matchingAvatar || staticAvatars[0]);
    }

    if (basicInfoState && basicInfoState.assistant_name) {
      if (staticNames.includes(basicInfoState.assistant_name)) {
        setSelectedName(basicInfoState.assistant_name);
      } else {
        setSelectedName('');
        setCustomName(basicInfoState.assistant_name);
      }
    }
  }, [basicInfoState]);

  useEffect(() => {
    // Set initial avatar based on selected name
    if (selectedName === 'Steve Prescott' || selectedName === 'Peter Miranda') {
      setSelectedAvatar(staticAvatars[1]); // avatar2
    } else if (selectedName === 'Alice Williams' || selectedName === 'Amellia Scott') {
      setSelectedAvatar(staticAvatars[0]); // avatar1
    } else if (selectedName === '') {
      setSelectedAvatar(staticAvatars[0]); // Select first avatar for custom name
    } else {
      setSelectedAvatar(null);
    }
  }, [selectedName]);

  const handleCustomAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCustomAvatar(e.target.result);
        setSelectedAvatar(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const isAvatarDisabled = (avatarIndex) => {
    if (selectedName === '') {
      return false; // Enable all avatars for custom name
    } else if (selectedName === 'Steve Prescott' || selectedName === 'Peter Miranda') {
      return avatarIndex !== 1 && avatarIndex !== 3; // Only allow avatar2 and avatar5
    } else if (selectedName === 'Alice Williams' || selectedName === 'Amellia Scott') {
      return avatarIndex !== 0 && avatarIndex !== 2; // Only allow avatar1 and avatar7
    }
    return false;
  };

  const handleNameChange = (name) => {
    setSelectedName(name);
    setCustomName('');
    if (name === 'Steve Prescott' || name === 'Peter Miranda') {
      setSelectedAvatar(staticAvatars[1]); // avatar2
    } else if (name === 'Alice Williams' || name === 'Amellia Scott') {
      setSelectedAvatar(staticAvatars[0]); // avatar1
    } else if (name === '') {
      setSelectedAvatar(staticAvatars[0]); // Select first avatar for custom name
    } else {
      setSelectedAvatar(null);
    }
  };

  const handleNext = async () => {
    const selectedAvatarFileName = selectedAvatar ? selectedAvatar.split('/').pop() : 'custom_avatar.jpg';
    const finalName = selectedName || customName;

    if ((!selectedAvatar && !customAvatar) || !finalName) {
      alert('Please select an avatar and provide a name for your assistant.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/update-assistant-info`, {
        user_id: userId,
        assistant_avatar: selectedAvatarFileName,
        assistant_name: finalName,
      });

      if (response.data.success) {
        dispatch(basicInfo());
        navigate('/workflow-and-role-setup');
      } else {
        console.error('Failed to update assistant info');
        alert('There was an error updating your assistant information. Please try again.');
      }
    } catch (error) {
      console.error('Error updating assistant info:', error);
      alert('There was an error updating your assistant information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageBackground>
      <Container fluid>
        <Card className='d-none d-lg-block'>
          <Steps
            items={[
              {
                title: 'Dashboard',
                status: 'finish',
                icon: <DashboardOutlined />,
              },
              {
                title: 'Onboarding',
                status: 'finish',
                icon: <UserOutlined />,
              },
              {
                title: 'Customize AI Personality',
                status: 'process',
                icon: <RobotOutlined />,
              },
              {
                title: 'Workflow & Role',
                status: 'wait',
                icon: <ApartmentOutlined />,
              },
              {
                title: 'Data Integration & Training',
                status: 'wait',
                icon: <DatabaseOutlined />,
              },
              {
                title: 'Connect Calendly',
                status: 'wait',
                icon: <CalendarOutlined />,
              },
              {
                title: 'Review Samples',
                status: 'wait',
                icon: <FileSearchOutlined />,
              },
              {
                title: 'Deploy Agents',
                status: 'wait',
                icon: <RocketOutlined />,
              }
            ]}
          />
        </Card>
        <Card className='p-3 d-sm-block d-none d-lg-none'>
          <Steps
            items={[
              {
                status: 'finish',
                icon: <DashboardOutlined />,
              },
              {
                status: 'finish',
                icon: <UserOutlined />,
              },
              {
                status: 'process',
                icon: <RobotOutlined />,
              },
              {
                status: 'wait',
                icon: <ApartmentOutlined />,
              },
              {
                status: 'wait',
                icon: <DatabaseOutlined />,
              },
              {
                status: 'wait',
                icon: <CalendarOutlined />,
              },
              {
                status: 'wait',
                icon: <FileSearchOutlined />,
              },
              {
                status: 'wait',
                icon: <DollarOutlined />,
              }
            ]}
          />
        </Card>
        <div className="d-flex flex-column flex-md-row mt-4">
          <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
            <SideMenu currentStep={3} activePage="/customize-ai-personality" />
          </Card>
          <div className='mb-3'>
            <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
              Navigation Menu
            </Button>
            </div>
            <Drawer title="Navigation Menu" onClose={onClose} open={open}>
                <SideMenu currentStep={3} activePage="/customize-ai-personality" />
            </Drawer>
          <Card className="flex-grow-1">
            <h2 className="main-card-heading">Create a New Assistant</h2>
            <Row>
              <Col lg={6} md={12} className="mb-4">
                <CardTitle>Assistant Avatar</CardTitle>
                <p>Select an AI avatar for your Assistant below.</p>
                <AvatarGrid>
                  {avatars.map((avatar, index) => (
                    <AvatarOption key={index} disabled={isAvatarDisabled(index)}>
                      <AvatarImage
                        src={avatar}
                        alt={`Avatar ${index + 1}`}
                        selected={selectedAvatar === avatar}
                        disabled={isAvatarDisabled(index)}
                        onClick={() => {
                          if (!isAvatarDisabled(index)) {
                            setSelectedAvatar(avatar);
                            setCustomAvatar(null);
                          }
                        }}
                      />
                    </AvatarOption>
                  ))}
                  <AvatarOption onClick={() => fileInputRef.current.click()}>
                    <CustomUploadButton className="uploadFromLocal">
                      <FaUpload size={30} color="#6c757d" />
                      <span style={{ marginTop: '5px', fontSize: '12px', color: '#6c757d' }}>Upload</span>
                    </CustomUploadButton>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleCustomAvatarUpload}
                      style={{ display: 'none' }}
                      accept="image/*"
                    />
                  </AvatarOption>
                </AvatarGrid>
                {customAvatar && (
                  <div className="mt-3 text-center">
                    <AvatarImage
                      src={customAvatar}
                      alt="Custom Avatar"
                      selected={true}
                      style={{ width: '120px', height: '120px' }}
                    />
                  </div>
                )}
              </Col>
              <Col lg={6} md={12}>
                <CardTitle>Assistant Name</CardTitle>
                <p>Select an AI Assistant name</p>
                <Form className="name-selection">
                  {names.map((name, index) => (
                    <NameOption key={index}>
                      <Form.Check
                        type="radio"
                        id={`name-${index}`}
                        name="aiName"
                        onChange={() => handleNameChange(name)}
                        checked={selectedName === name}
                        className='me-3'
                      />
                      {name}
                    </NameOption>
                  ))}
                  <NameOption>
                    <Form.Check
                      type="radio"
                      id="custom-name"
                      name="aiName"
                      onChange={() => handleNameChange('')}
                      checked={!names.includes(selectedName)}
                      className='me-3'
                    />
                    Make your own
                  </NameOption>
                  {!names.includes(selectedName) && (
                    <CustomNameInput
                      type="text"
                      placeholder="Enter custom name"
                      value={customName}
                      onChange={(e) => {
                        setCustomName(e.target.value);
                        setSelectedName('');
                      }}
                    />
                  )}
                </Form>
              </Col>
            </Row>
            <div className="text-center mt-4">
              <button className='btn btn-lg continue-btn btn-primary' type="submit" onClick={handleNext} disabled={isLoading}>
                {/* {isLoading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  'Continue'
                )} */}
                {isLoading ? (
                                <TailSpin height="20" width="20" color="white" />
                            ) : (
                                'Continue'
                            )}
              </button>
            </div>
          </Card>
        </div>
      </Container>
    </PageBackground>
  );
}

export default CustomizeAIPersonality;
