import React, { useState, useEffect } from 'react';
import { Modal, Table, Form, Pagination, Spinner, Alert, Button } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import axios from 'axios';
import { Spin } from 'antd';

function ValidateNegativeCompaniesModal({ show, onHide, negativeCompaniesFile, userId, onUploadComplete }) {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (negativeCompaniesFile) {
      fetchCompanies();
    }
  }, [currentPage, searchTerm, negativeCompaniesFile]);

  const fetchCompanies = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      formData.append('negative_companies', negativeCompaniesFile);
      formData.append('page', currentPage);
      formData.append('search', searchTerm);
      formData.append('user_id', userId);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/validate_negative_company`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      setCompanies(response.data.results);
      setTotalPages(response.data.total_pages);
      setHasNext(response.data.has_next);
      setHasPrevious(response.data.has_previous);
      setTotalResults(response.data.total_results);
    } catch (error) {
      console.error('Error fetching companies:', error);
      const errorMessage = error.response && error.response.data.error ? error.response.data.error : 'An error occurred while fetching companies. Please try again.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadCsvFile = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      formData.append('file', negativeCompaniesFile);
      formData.append('user_id', userId);
      formData.append('collection_name', 'negative_companies');

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/upload_csv_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      console.log('CSV file uploaded successfully:', response.data);
       // Notify parent component that upload is complete
      setCompanies([])
      setTotalResults(0)
      onHide(); // Close the modal after successful upload

    } catch (error) {
      console.error('Error uploading CSV file:', error);
      const errorMessage = error.response && error.response.data.error ? error.response.data.error : 'An error occurred while uploading the CSV file. Please try again.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
      onUploadComplete();
    }
  };

  const onClose=()=>{
    setCompanies([])
    setTotalResults(0)
    onHide()
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const getStatusStyle = (status) => {
    return {
      color: status.toLowerCase() === 'already exists' || status.toLowerCase() === 'valid' ? 'green' : 'red',
      fontWeight: 'bold'
    };
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = 3;
    const ellipsis = <Pagination.Ellipsis key="ellipsis" />;

    items.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />,
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={!hasPrevious} />
    );

    if (totalPages <= maxVisiblePages) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }
    } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

      if (endPage - startPage < maxVisiblePages - 1) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      if (startPage > 1) {
        items.push(ellipsis);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(ellipsis);
      }
    }
    
    items.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={!hasNext} />,
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return items;
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>Exclusion List Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ position: 'relative', minHeight: '300px' }}>
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}
        <Form.Group className="mb-3">
          {/* <Form.Control
            type="text"
            placeholder="Search by Company Domain"
            value={searchTerm}
            onChange={handleSearchChange}
          /> */}
        </Form.Group>
        <div style={{ opacity: isLoading ? 0.5 : 1 }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Company Domain</th>
                <th>Company Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company, index) => (
                <tr key={index}>
                  <td>{company.domain_name}</td>
                  <td>{company.company_name}</td>
                  <td style={getStatusStyle(company.status)}>{company.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop: '1rem'}}>
            <Pagination size="sm">{renderPaginationItems()}</Pagination>
            <div>Total Results: {totalResults}</div>
          </div>
        </div>
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
             {/* <Spin tip="Loading">Loading...</Spin> */}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button 
          disabled={error || isLoading} 
          variant="outline-primary" 
          className="me-2" 
          style={{padding: '0.375rem 0.75rem',display:'flex',justifyContent:'center',alignItems:'center'}}
          onClick={uploadCsvFile}
        >
          <FaUpload size={16} style={{marginRight: '0.5rem'}} />
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ValidateNegativeCompaniesModal;