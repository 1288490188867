import React, { useState, useEffect,useCallback } from 'react';
import { Form, Input, Select, Button, Upload, message, Spin, ColorPicker, Switch, Divider, Collapse } from 'antd';
import { CaretRightOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { LuPanelLeft, LuPanelRight } from "react-icons/lu";
import logo from "../assets/images/chat-icon.png"
const { Option } = Select;

const StylingTab = ({ form, handleFormChange, formDetails, normFile, document_id,activeTab }) => {
  const [loading, setLoading] = useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [color, setColor] = useState('#1677ff');
  
  const [openKeys, setOpenKeys] = useState(['1', '2', '3']); // Initialize with all panels open
  const [images, setImages] = useState({
    company_logo: {
      url: formDetails?.company_logo?.[0]?.url || logo,
      previewUrl: formDetails?.company_logo?.[0]?.url || logo,
      file: null
    },
    bot_avatar: {
      url: formDetails?.bot_avatar?.[0]?.url || logo,
      previewUrl: formDetails?.bot_avatar?.[0]?.url || logo,
      file: null
    },
    chat_icon: {
      url: formDetails?.chat_icon?.[0]?.url|| logo,
      previewUrl: formDetails?.chat_icon?.[0]?.url || logo,
      file: null
    }
  });

  const onCollapseChange = (keys) => {
    setOpenKeys(keys);
  };
  useEffect(() => {
    setChangedValues({});

    return () => {
      // Cleanup function to reset changedValues when component unmounts
      setChangedValues({});
    };
  }, [document_id, activeTab]);


  const handleValuesChange = (changedValue, allValues) => {
    setChangedValues(prevChangedValues => ({ ...prevChangedValues, ...changedValue }));
    handleFormChange(allValues);
  };
  console.log("changedValues in StylingTab--->",changedValues);

  const beforeUpload = (file) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
    const isValidType = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
    if (!isValidType) {
      message.destroy()
      message.error('You can only upload Images!');
      return false;
    }
    if (file.size > MAX_FILE_SIZE) {
      message.destroy()
      message.error('File size must be less than 5 MB!');
      return false;
    }
    return true;
  };

    const handleImageChange = (info, imageType) => {
      // if (info.file.status !== 'done') return;
      console.log("FILE---",info);
      const file = info.file.originFileObj;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
         //update images state with both URLs
          setImages(prev => ({
            ...prev,
            [imageType]: {
              url:prev[imageType].url,//keep the original URL
              url: e.target.result, //Use the new file for 
              file: file
            }
          }));
  
          const fileList = [{
            uid: '-1',
            name: file.name,
            status: 'done',
            originFileObj: file,
            url: e.target.result
          }];
           
          form.setFieldsValue({
            [imageType]: fileList
          });
  
          // Update changedValues
        setChangedValues(prev => ({
          ...prev,
          [imageType]: fileList
        }));
  
         // ADD THIS: Trigger form change with complete values
         const currentFormValues = form.getFieldsValue();
         handleFormChange({
           ...currentFormValues,
           [imageType]: fileList
         });
        };
        reader.onerror = (error) => {
          message.destroy()
          message.error(`File read failed: ${error}`);
        };
        reader.readAsDataURL(file);
      }
    };


  const onSave = async () => {
    if (Object.keys(changedValues).length === 0) {
      message.destroy()
      message.info("No changes to save");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(changedValues).forEach((key) => {
        if (
          key === "company_logo" ||
          key === "bot_avatar" ||
          key === "chat_icon"
        ) {
          if (changedValues[key] && changedValues[key][0] && changedValues[key][0].originFileObj) {
            formData.append(key, changedValues[key][0].originFileObj);
          }
        } else {
          formData.append(key, changedValues[key]);
        }
      });

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/aichat/chatbots/${document_id}/styling/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.destroy()
      message.success("Chat bot styling updated successfully");
      // handleFormChange(response.data);
      setChangedValues({});
    } catch (error) {
      console.error("Error updating chat bot styling:", error);
      message.destroy()
      message.error("Failed to update chat bot styling");
    } finally {
      setLoading(false);
    }
  };

  

  const handleColorChange = (colorValue, hexString) => {
    setColor(hexString);
    setChangedValues(prevChangedValues => ({ ...prevChangedValues, primary_color: hexString }));
    handleFormChange({ ...form.getFieldsValue(), primary_color: hexString });
  };

  return (
    <Spin spinning={loading} tip="Saving...">
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        layout="vertical"
        initialValues={formDetails}
      >
        <Collapse
          activeKey={openKeys}
          onChange={onCollapseChange}
          size="large"
          expandIconPosition="right"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="custom-collapse me-2 mb-3"
          items={[{
            key: '1',
            label: 'Set your brand styling',
            children: <>
              <div className='row'>
                <div className='col-md-6'>
                  <div>
                  <Form.Item
                  name="company_logo"
                  label="Company logo"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <img
                            src={images.company_logo.url || formDetails?.company_logo?.[0]?.url}
                            alt="Company logo"
                            className='clickable-upload-img'
                          />
                          <Upload
                            name="company_logo"
                            onChange={(info) => handleImageChange(info, 'company_logo')}
                            beforeUpload={beforeUpload}
                            showUploadList={false}
                            className='clickable-upload'
                            accept="image/*"
                          >
                            <UploadOutlined /> Upload logo
                          </Upload>
                        </div>
                      </Form.Item>
                  </div>

                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span>Upload your company logo to be displayed in the chat header. Accepted Files: JPG or PNG or JPEG. MAX SIZE: 5MB</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                    <Form.Item
                      name="bot_avatar"
                      label="Bot avatar"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img
                            src={images.bot_avatar.url || formDetails?.bot_avatar?.[0]?.url}
                            alt="Bot avatar"
                            className='clickable-upload-img'
                          />
                          <Upload
                            name="bot_avatar"
                            customRequest={({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0)}
                            onChange={(info) => handleImageChange(info, 'bot_avatar')}
                            beforeUpload={beforeUpload}
                            showUploadList={false}
                            className='clickable-upload'
                            accept="image/*"
                          >
                            <UploadOutlined /> Upload avatar
                          </Upload>
                        </div>
                </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span>Upload an avatar image for your AI chat bot. Accepted Files: JPG or PNG or JPEG. MAX SIZE: 5MB</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                  name="chat_icon"
                  label="Chat icon"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}

                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img
                            src={images.chat_icon.url || formDetails?.chat_icon?.[0]?.url}
                            alt="Chat icon"
                            className='clickable-upload-img'
                          />
                          <Upload
                            name="chat_icon"
                            customRequest={({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0)}
                            onChange={(info) => handleImageChange(info, 'chat_icon')}
                            beforeUpload={beforeUpload}
                            showUploadList={false}
                            className='clickable-upload'
                            accept="image/*"
                          >
                            <UploadOutlined /> Upload icon
                          </Upload>
                        </div>
                </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span>Upload an avatar image for your AI chat bot. Accepted Files: JPG or PNG or JPEG. MAX SIZE: 5MB</small>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Item
                    name="primary_color"
                    label="Color"
                  >
                    <ColorPicker value={color} showText  onChange={handleColorChange} />
                  </Form.Item>
                </div>
                <div className='col-md-6'>
                  <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span>Define the primary color scheme for the chat to align with your brand, creating a visually cohesive experience on the customer's site.</small>
                </div>
              </div>
              </>
            }]}
      />
      <Collapse
          activeKey={openKeys}
          onChange={onCollapseChange}
          expandIconPosition="right"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="custom-collapse me-2"
          size="large"
          items={[{
            key: '2',
            label: 'Appearance',
            children: <>
      <div className='row'>
        <div className='col-md-6'>
          <Form.Item
              name="widget_position"
              label="Widget position"
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '10px' }}>Left</span>
                <Switch
                  // checkedChildren={<LuPanelLeft />}
                  // unCheckedChildren={<LuPanelRight />}
                  defaultChecked={formDetails.widget_position === 'right'}
                  onChange={(checked) => {
                    const newPosition = checked ? 'right' : 'left';
                    form.setFieldsValue({ widget_position: newPosition });
                    handleValuesChange({ widget_position: newPosition }, form.getFieldsValue());
                  }}
                />
                <span style={{ marginLeft: '10px' }}>Right</span>
              </div>
          </Form.Item>
        </div>
        <div className='col-md-6'>
          <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span>Select the position of the chat widget on the webpage.</small>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <Form.Item
            name="widget_default_state"
            label="Widget default state"
          >
            <Select>
              <Option value="open">Open</Option>
              <Option value="closed">Closed</Option>
            </Select>
          </Form.Item>
        </div>
        <div className='col-md-6'>
          <small className='extraContentDesc'><span><HiOutlineExclamationCircle /></span>Choose whether the chat widget should be open or closed by default.</small>
        </div>
      </div>
      </>
      }]}
      />
        <Form.Item className='mt-4 text-end' style={{marginBottom : '100px'}}>
          <Button className='me-2' type="primary" onClick={onSave} loading={loading} disabled={loading}>
            {loading ? 'Saving...' : 'Save Styling'}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default StylingTab;
