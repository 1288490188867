import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import {Image, Row, Col, Container, Accordion, Spinner} from 'react-bootstrap';
import { Breadcrumb, Input, List, Avatar, Button, Menu, Steps, Spin, Drawer } from 'antd';
import { LuMinus } from "react-icons/lu";
import { useSelector, useDispatch } from 'react-redux';
import { basicInfo } from '../components/reducers/slice';
import languagesData from '../languages.json';
import debounce from 'lodash/debounce';
import styled from "styled-components";
import { FaRegEdit } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { MdOutlineEdit } from "react-icons/md";
import "./Onboarding.scss";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FileSearchOutlined,
  MailOutlined,
  RobotOutlined,
  RocketOutlined,
  SettingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined
} from "@ant-design/icons";
import { BsStars } from "react-icons/bs";
import Lottie from 'react-lottie';
import searchListAnimation from '../assets/SearchList.json';
import { SideMenu } from '../components/Common/SideMenu';

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // margin-bottom: 30px;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }
`;

const ResponsiveSteps = styled(Steps)`
  @media (max-width: 768px) {
    .ant-steps-item-icon {
      width: 24px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
    }
    .ant-steps-item-content {
      width: auto;
    }
    .ant-steps-item-title {
      font-size: 12px;
      padding-right: 8px;
    }
  }
  @media (max-width: 576px) {
    .ant-steps-item-icon {
      width: 20px;
      height: 20px;
      font-size: 10px;
      line-height: 20px;
    }
    .ant-steps-item-content {
      display: none;
    }
  }
`;

function Onboarding() {
  const { userId, basicInfo: basicInfoState } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoUrl, setCompanyLogoUrl] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyProposal, setCompanyProposal] = useState('');
  const [language, setLanguage] = useState('English');
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [reviewOne, setReviewOne] = useState('');
  const [reviewTwo, setReviewTwo] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [generatingProposal, setGeneratingProposal] = useState(false);
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [companiesData, setCompaniesData] = useState({});
  const [open, setOpen] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const { TextArea } = Input;
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const [websiteUrl, setWebsiteUrl] = useState('');

  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const observer = useRef();
  const lastCompanyElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    const sortedLanguages = languagesData.sort((a, b) => {
      if (a.language === 'English') return -1;
      if (b.language === 'English') return 1;
      return a.language.localeCompare(b.language);
    });
    setLanguages(sortedLanguages);

    if (basicInfoState && basicInfoState.company_name) {
      setCompanyName(basicInfoState.company_name);
      setCompanyProposal(basicInfoState.company_proposal || '');
      setLanguage(basicInfoState.language || 'English');
      setReviewOne(basicInfoState.review_one || '');
      setReviewTwo(basicInfoState.review_two || '');
      setRedirectUrl(basicInfoState.redirect_url || '');
      setWebsiteUrl(basicInfoState.website_url || '');
      setShowAdditionalFields(true);
      setIsCompanySelected(true);
      setShowGenerateButton(true);

      if (basicInfoState.company_logo) {
        setSelectedImage(basicInfoState.company_logo);
        setCompanyLogoUrl(basicInfoState.company_logo);
      }
    }
  }, [basicInfoState]);
  const LottieAnimation = ({ width = '100%', height = '100%' }) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: searchListAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div style={{ width, height, margin: '20px auto' }}>
        <Lottie options={defaultOptions} />
      </div>
    )}

  useEffect(() => {
    const handleClickOutside = (event) => {
      if(inputRef.current !==null || inputRef.current !==undefined){
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropdownVisible(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const searchCompanies = useCallback(debounce(async (query) => {
    setLoadingCompanies(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/agent_setup/search-companies?query=${query}&page=${page}`);
      const newCompanies = response.data.results;

      let filteredCompanies;
      if (query) {
        filteredCompanies = newCompanies.filter(company =>
          company.name.toLowerCase().includes(query.toLowerCase())
        );

        if (!filteredCompanies.some(company => company.name.toLowerCase() === query.toLowerCase())) {
          filteredCompanies.unshift({ name: query, isNew: true });
        }
      } else {
        filteredCompanies = newCompanies;
      }

      setCompanies(prevCompanies => {
        const updatedCompanies = page === 1 ? filteredCompanies : [...prevCompanies, ...filteredCompanies];
        return Array.from(new Set(updatedCompanies.map(a => a.name)))
          .map(name => updatedCompanies.find(a => a.name === name));
      });

      setHasMore(newCompanies.length > 0);
      setDropdownVisible(filteredCompanies.length > 0);
    } catch (error) {
      console.error('Error searching companies:', error);
    } finally {
      setLoadingCompanies(false);
    }
  }, 300), [page]);

  useEffect(() => {
    if (searchQuery) {
      setPage(1);
      searchCompanies(searchQuery);
    } else {
      setCompanies([]);
      setDropdownVisible(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (page > 1 && searchQuery) {
      searchCompanies(searchQuery);
    }
  }, [page]);

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
    setSearchQuery(value);
    setIsCompanySelected(false);
    setShowGenerateButton(false);
    if (value === '') {
      setShowAdditionalFields(false);
      setDropdownVisible(false);
    } else {
      setDropdownVisible(true);
    }
  };

  const handleCompanySelect = async (company) => {
    setCompanyName(company.name);
    setDropdownVisible(false);
    setShowAdditionalFields(true);
    setIsCompanySelected(true);
    setShowGenerateButton(true);

    if (companiesData[company.name]) {
      const storedData = companiesData[company.name];
      setSelectedImage(storedData.logo_url);
      setCompanyLogoUrl(storedData.logo_url);
      setCompanyLogo(null);
      setCompanyProposal(storedData.proposal);
      setLanguage(storedData.language);
      setReviewOne(storedData.review_one);
      setReviewTwo(storedData.review_two);
      setRedirectUrl(storedData.redirect_url);
      setWebsiteUrl(storedData.website_url || '');
    } else if (company.isNew) {
      setSelectedImage(null);
      setCompanyLogoUrl('');
      setCompanyProposal('');
      setCompanyLogo(null);
      setWebsiteUrl('');
    } else {
      setSelectedImage(company.logo_url);
      setCompanyLogoUrl(company.logo_url);
      setCompanyLogo(null);
      setWebsiteUrl(company.website_url || '');
      setCompaniesData(prevData => ({
        ...prevData,
        [company.name]: {
          logo_url: company.logo_url,
          proposal: '',
          language: language,
          review_one: '',
          review_two: '',
          redirect_url: '',
          website_url: company.website_url || ''
        }
      }));
    }
  };

  const handleGenerateProposal = async () => {
    setGeneratingProposal(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/integration/generate-company-proposal`, {
        company_name: companyName,
      });
      const generatedProposal = response.data.proposal;
      setCompanyProposal(generatedProposal);
      setCompaniesData(prevData => ({
        ...prevData,
        [companyName]: {
          ...prevData[companyName],
          proposal: generatedProposal
        }
      }));
    } catch (error) {
      console.error('Error generating company proposal:', error);
    } finally {
      setGeneratingProposal(false);
    }
  };

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'language':
        setLanguage(value);
        break;
      case 'review_one':
        setReviewOne(value);
        break;
      case 'review_two':
        setReviewTwo(value);
        break;
      case 'redirect_url':
        setRedirectUrl(value);
        break;
      case 'company_proposal':
        setCompanyProposal(value);
        break;
      case 'website_url':
        setWebsiteUrl(value);
        break;
      default:
        break;
    }

    setCompaniesData(prevData => ({
      ...prevData,
      [companyName]: {
        ...prevData[companyName],
        [field]: value
      }
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCompanyLogo(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setCompanyLogoUrl('');
        setCompaniesData(prevData => ({
          ...prevData,
          [companyName]: {
            ...prevData[companyName],
            logo_url: reader.result,
            logo_file: file
          }
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    if (companyLogo) {
      formData.append('company_logo', companyLogo);
    } else if (companyLogoUrl) {
      formData.append('company_logo_url', companyLogoUrl);
    }
    formData.append('company_name', companyName);
    formData.append('user_id', userId);
    formData.append('company_proposal', companyProposal);
    formData.append('language', language);
    formData.append('review_one', reviewOne);
    formData.append('review_two', reviewTwo);
    formData.append('redirect_url', redirectUrl);
    formData.append('website_url', websiteUrl);

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/agent_setup/add_sales_agent_basic_info', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch(basicInfo({ ...basicInfoState, ...response.data }));
      navigate('/customize-ai-personality');
    } catch (error) {
      console.error('There was an error uploading the data!', error);
    } finally {
      setLoading(false);
    }
  };

  const triggerFileInput = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  // Function to toggle review visibility
  const toggleReviews = (e) => {
    e.preventDefault();
    setShowReviews(!showReviews);
  };

  return (
      <PageBackground>
        <Container fluid >
          <Card className='d-none d-lg-block'>
            <ResponsiveSteps
                items={[
                  {
                    title: 'Dashboard',
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    title: 'Onboarding',
                    status: 'process',
                    icon: <UserOutlined />,
                  },
                  {
                    title: 'Customize AI Personality',
                    status: 'wait',
                    icon: <RobotOutlined />,
                  },
                  {
                    title: 'Workflow & Role',
                    status: 'wait',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    title: 'Data Integration & Training',
                    status: 'wait',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    title: 'Connect Calendly',
                    status: 'wait',
                    icon: <CalendarOutlined />,
                  },
                  {
                    title: 'Review Samples',
                    status: 'wait',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    title: 'Deploy Agents',
                    status: 'wait',
                    icon: <RocketOutlined />,
                  }
                ]}
            />
          </Card>
          <Card className='p-3 d-sm-block d-none d-lg-none'>
            <Steps
                items={[
                  {
                    status: 'finish',
                    icon: <DashboardOutlined sizes='small' />,
                  },
                  {
                    status: 'process',
                    icon: <UserOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <RobotOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <CalendarOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <DollarOutlined />,
                  }
                ]}
            />
          </Card>

          <div className="d-flex flex-column flex-md-row mt-4">
            <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
              <SideMenu currentStep={2} activePage="/onboarding" />
            </Card>
            <div className='mb-3'>
              <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
                Navigation Menu
              </Button>
            </div>
            <Drawer title="Navigation Menu" onClose={onClose} open={open}>
                <SideMenu currentStep={2} activePage="/onboarding" />
            </Drawer>
            <div className="w-100">
              <Card className="onboarding">
                <h2 className='main-card-heading'>Set-up AI Agent!</h2>
                <form onSubmit={handleSubmit} className=''>
                  <div className='mb-3'>
                    <div className=''>
                    <div className='d-flex flex-column flex-md-row'>

                        {showAdditionalFields &&<div className='mb-3 position-relative'>
                          {selectedImage && (
                              <div className="mt-1 mb-2">
                                <Image src={selectedImage} alt="Preview" thumbnail style={{ maxWidth: '100px' }} />
                              </div>
                          )}
                          <button onClick={triggerFileInput} className="edit_button_logo"><MdOutlineEdit /></button>
                          <input
                              ref={fileInputRef}
                              type="file"
                              onChange={handleFileChange}
                              style={{ display: 'none' }}
                          />
                        </div>}

                        <div className='w-100 ms-0 ms-md-3 mt-3 mt-md-0'>
                          <label className='mb-1'>Your company name <span className='text-danger'>*</span></label><br/>
                          <Input
                              ref={inputRef}
                              type="text"
                              className='p-3'
                              placeholder='Please enter company name'
                              value={companyName}
                              onChange={handleCompanyNameChange}
                              onFocus={() => !isCompanySelected && setDropdownVisible(true)}
                          />
                        </div>
                    </div>
                    </div>

                    {dropdownVisible && (
                        <div ref={dropdownRef}>
                          <List
                              loading={loadingCompanies}
                              dataSource={companies}
                              renderItem={(company, index) => (
                                  <List.Item
                                      key={company.name}
                                      ref={index === companies.length - 1 ? lastCompanyElementRef : null}
                                      onClick={() => handleCompanySelect(company)}
                                      style={{ cursor: 'pointer' }}
                                  >
                                    <List.Item.Meta
                                        avatar={company.isNew ? <Avatar icon={<span>+</span>} /> : <Avatar src={company.logo_url} />}
                                        title={company.isNew ? `Add "${company.name}"` : company.name}
                                        description={company.isNew ? 'New company' : `${company.short_description || 'No description available'} - Employees: ${company.employee_count || 'N/A'}`}
                                    />
                                  </List.Item>
                              )}
                              style={{ maxHeight: '200px', overflowY: 'auto' }}
                          />
                        </div>
                    )}
                  </div>
                  {showAdditionalFields && (
                      <>
                        <div className='mb-3'>
                          <label className='mb-1'>Website URL <span className='text-danger'>*</span></label><br/>
                          <Input
                              type="text"
                              className='p-3'
                              value={websiteUrl}
                              placeholder='Please enter website URL'
                              onChange={(e) => handleInputChange('website_url', e.target.value)}
                              required
                          />
                        </div>

                        <div className='mb-3'>
                          <div className='d-flex justify-content-between flex-column flex-md-row'>
                          <label className='mb-1'>Company Proposal <span className='text-danger'>*</span></label><br/>
                          {showGenerateButton && (
                              <Button
                                  onClick={handleGenerateProposal}
                                  disabled={generatingProposal}
                                  className="mb-2 text-primary ai-btn "
                              >
                                <BsStars  className='d-inline-block'/> Let AI generate your proposal
                              </Button>
                          )}
                          </div>
                          {generatingProposal ? (
                              <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                                <Spinner animation="border" role="status">
                                </Spinner>
                                <span className="mt-3">AI is generating your company proposal...</span>
                              </div>
                          ) : (
                              <TextArea
                                  className='form-control'
                                  value={companyProposal}
                                  rows={5}
                                  required
                                  onChange={(e) => handleInputChange('company_proposal', e.target.value)}
                              ></TextArea>
                          )}
                        </div>
                        <button
                          type="button"
                          onClick={toggleReviews}
                          className='btn-review-toggle'
                        >
                          {showReviews ? <span className='d-flex align-items-center'>Add Testimonials <LuMinus  className='ms-2'/></span> :  <span  className='d-flex  align-items-center '>Add Testimonials <HiPlus className='ms-2'/></span> }
                        </button>
                        {showReviews && <Row>
                          <Col md={12} className='mb-3'>
                            <label className='mb-1'>Review one including name/title/company of reviewer (optional)</label><br/>
                            <textarea
                                className='form-control'
                                value={reviewOne}
                                onChange={(e) => handleInputChange('review_one', e.target.value)}
                                rows="3"
                            ></textarea>
                          </Col>
                          <Col md={12} className='mb-3'>
                            <label className='mb-1'>Review two including name/title/company of reviewer (optional)</label><br/>
                            <textarea
                                className='form-control'
                                value={reviewTwo}
                                onChange={(e) => handleInputChange('review_two', e.target.value)}
                                rows="3"
                            ></textarea>
                          </Col>
                        </Row>}

                        <div className='text-center mt-3'>
                          <button className='btn btn-lg continue-btn btn-primary' type="submit" disabled={loading || generatingProposal}>
                            {loading ? (
                                <TailSpin height="20" width="20" color="white" />
                            ) : (
                                'Continue'
                            )}
                          </button>
                        </div>
                      </>
                  )}
                </form>
              </Card>
              {!showAdditionalFields &&<div className='d-flex justify-content-center'>
              <div className="lottie-container">
                <LottieAnimation />
              </div></div>}
            </div>
          </div>
        </Container>
      </PageBackground>
  );
}

export default Onboarding;
