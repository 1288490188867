import React, { useCallback } from 'react';
import { Button, Select, Space, Typography, Form } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;
const { Option } = Select;

const fieldArr = ['First Name', 'Last Name', 'Company', 'Email', 'City', 'State', 'Country', 'Email Secondary', 'Phone', 'Phone Secondary', 'Linked In Profile Url', 'Sales Navigator Url', 'Linked In Recruiter Url', 'Company Size', 'Industry', 'Title', 'Domain'];

const GetContactInformation = ({ form, contactInformation, handleFormChange }) => {
   
  const getAvailableOptions = useCallback((fields, currentIndex) => {
    const usedFields = new Set(fields.slice(0, currentIndex).map(field => form.getFieldValue(['contact_information', field.name])));
    return fieldArr.filter(field => !usedFields.has(field));
  }, [form]);

  const onValuesChange = (changedValues, allValues) => {
    handleFormChange({ contact_information: allValues.contact_information });
  };

  return (
    <div>
      <Paragraph>
        This option allows you to add all the fields that the bot should ask in the conversation format. 
        These questions should be answered for the bot to create the contact in Reply 3.
      </Paragraph>
      <Form
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{ contact_information: contactInformation || [] }}
      >
        <Form.List name="contact_information">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please select a field or delete this row.",
                      },
                    ]}
                  >
                    <Select style={{ width: 200 }}>
                      {getAvailableOptions(fields, index).map(option => (
                        <Option key={option} value={option}>{option}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <DeleteOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    const nextField = getAvailableOptions(fields, fields.length)[0];
                    if (nextField) {
                      add(nextField);
                    }
                  }}
                  icon={<PlusOutlined />}
                  disabled={getAvailableOptions(fields, fields.length).length === 0}
                >
                  Add contact field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default GetContactInformation;